/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
import {
    faArrowLeft,
    faEnvelope,
    faLocationDot,
    faPencil,
    faPhone
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DataTable from '../../component/DataTable';
import Loader from '../../component/Loader';
import PrimaryButton from '../../component/PrimaryButton';
import SearchField from '../../component/SearchField';
import SecondaryButton from '../../component/SecondaryButton';
import AppHeader from '../../component/header/AppHeader';
import HeaderItem from '../../component/header/HeaderItem';
import CreateManager from '../../component/modal/CreateManager';
import ModalComponent from '../../component/modal/ModalComponent';
import { MESSAGES, SECRETARY_MANAGERS_TABLE_COLUMNS } from '../../constants';
import {
    getAllUsersDataAssociateWithSecretary,
    getSecretaryById,
    setSecretaryWithSelectedManagers
} from '../../helper/services/apis/user-services';
import { buildQueryChunk, checkPagePermission, getCurrentYear } from '../../helper/utility';

function Secretary() {
    const params = useParams();
    const navigate = useNavigate();
    const [managerData, setManagerData] = useState([]);
    const [managerIters, setManagerIters] = useState([]);
    const currentYear = getCurrentYear();
    const [filters, setFilters] = useState({
        year: currentYear
    });
    const [years, setYears] = useState([]);
    const [secretaryUsers, setSecretaryUsers] = useState([]);
    const [managerSearchString, setManagerSearchString] = useState('');

    /**
     * Component State
     */
    const [state, setState] = useState({
        isLoading: false,
        isUpdateUserInfoModalOpen: false,
        isUpdateUserGoalsModalOpen: false,
        isAssignManagerModalOpen: false
    });
    const { register, handleSubmit } = useForm();

    const initYears = async () => {
        const y = [];
        for (let index = 2022; index <= currentYear; index++) {
            y.push({ id: index, name: index });
        }
        setYears(
            y
                .concat([
                    { id: 'month-6', name: 'Ultimi 6 mesi' },
                    { id: 'month-3', name: 'Ultimi 3 mesi' },
                    { id: 'month-2', name: 'Ultimi 2 mesi' },
                    { id: 'month-1', name: 'Lo scorso mese' },
                    { id: 'day-14', name: 'Ultimi 14 giorni' },
                    { id: 'day-7', name: 'Ultimi 7 giorni' }
                ])
                .reverse()
        );
    };

    /**
     * Business Logic
     */

    const updateState = (currentState, key, value) => {
        const data = { ...currentState };
        data[key] = value;
        setState({ ...data });
    };

    const setListType = (currentState, type) => {
        const data = { ...currentState };
        data.listType = type;
        setState({ ...data });
    };

    const generateQueryString = (currentFilters, qs) => {
        let queryString = qs;

        Object.keys(currentFilters).forEach((key) => {
            const value = currentFilters[key];
            if (value) {
                if (key === 'year') {
                    if (typeof value === 'string') {
                        if (value.indexOf('day') > -1) {
                            const days = value.split('-')[1];
                            queryString = buildQueryChunk(queryString, 'day', days);
                        } else if (value.indexOf('month') > -1) {
                            const months = value.split('-')[1];
                            queryString = buildQueryChunk(queryString, 'month', months);
                        } else {
                            queryString = buildQueryChunk(queryString, key, value);
                        }
                    } else {
                        queryString = buildQueryChunk(queryString, key, value);
                    }
                } else {
                    queryString = buildQueryChunk(queryString, key, value);
                }
                // queryString = buildQueryChunk(queryString, key, value);
            }
        });

        return queryString;
    };

    const retrieveManagerData = async (currentState, currentFilters, id) => {
        const data = { ...currentState };
        data.isLoading = true;
        setState({ ...data });

        const qs = generateQueryString(currentFilters, '');

        const result = await getSecretaryById(id, qs).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
        });

        setManagerData({ ...result.data.data });
        setManagerIters([...result.data.iter]);

        data.isLoading = false;
        setState({ ...data });
    };

    const handleFilterChange = (e, currentFilters, filter, currentState) => {
        const newFilters = { ...currentFilters };
        const event = { ...e };
        newFilters[filter] = event.target.value;
        if (filter === 'year') {
            const newState = { ...currentState };
            newState.isCurrentYear = event.target.value === currentYear;
            setState({ ...newState });
        }
        setFilters({ ...newFilters });
    };

    const onCloseCreateUserModal = (currentState, reload = false) => {
        const data = { ...currentState };
        data.isUpdateUserInfoModalOpen = false;
        data.isUpdateUserGoalsModalOpen = false;
        setState({ ...data });
        if (reload) {
            retrieveManagerData(data, params.id);
        }
    };

    const retrieveAllUsersData = async (qs) => {
        let searchString = '';
        if (qs?.length) {
            searchString = `searchString=${qs}`;
        }
        const result = await getAllUsersDataAssociateWithSecretary(params.id, searchString).catch(
            (e) => {
                console.log(e);
                toast.error(MESSAGES.SERVER_ERROR);
            }
        );

        if (result?.ok) {
            const associateManagersIds = result.data.associateManagers;
            const data = result.data.managers.map((manager) => {
                const selected = associateManagersIds.includes(manager.id);
                return {
                    ...manager,
                    selected
                };
            });
            setSecretaryUsers(data);
        }
    };

    const onClickAssignManagersBtn = (currentState) => {
        retrieveAllUsersData();
        const data = { ...currentState };
        data.isAssignManagerModalOpen = true;
        setState({ ...data });
    };

    const getInIterCall = (candidates) => {
        let count = 0;
        candidates?.forEach((candidate) => {
            count += candidate.candidateInIterCall;
        });
        return count;
    };
    const getInNeverMeetCall = (candidates) => {
        let count = 0;
        candidates?.forEach((candidate) => {
            count += candidate.candidateInNeverMeetCall;
        });
        return count;
    };
    const getInNeverMeetMeeting = (candidates) => {
        let count = 0;
        candidates?.forEach((candidate) => {
            count += candidate.candidateInNeverMeetMeeting;
        });
        return count;
    };
    const getInIterMeeting = (candidates) => {
        let count = 0;
        candidates?.forEach((candidate) => {
            count += candidate.candidateInIterMeeting;
        });
        return count;
    };

    const selectedCheckboxOnChangeHandler = (index) => {
        const newManagers = [...secretaryUsers];
        const selectedManager = newManagers[index];
        selectedManager.selected = !selectedManager.selected;
        setSecretaryUsers([...newManagers]);
    };

    const closeAssignManagerModel = (currentState, reload = false) => {
        const newState = { ...currentState };
        newState.isAssignManagerModalOpen = false;
        setManagerSearchString('');
        if (reload) {
            retrieveManagerData({ ...newState }, filters, params.id);
        } else {
            setState({ ...newState });
        }
    };

    const onSubmitHandler = async (secretaryId, data, currentState) => {
        const newState = { ...currentState };
        newState.isLoading = true;
        setState({ ...newState });
        const managersId = [];
        data.forEach((element) => {
            if (element.selected) {
                managersId.push(element.id);
            }
        });
        const payload = { secretaryId, managers: managersId };
        const result = await setSecretaryWithSelectedManagers(payload).catch((e) => {
            console.log(e);
            newState.isLoading = false;
            toast.error(MESSAGES.SERVER_ERROR);
            setState({ ...newState });
        });
        if (result?.ok) {
            toast.success(MESSAGES.SUCCESS);
            newState.isLoading = false;
            closeAssignManagerModel({ ...newState }, true);
            // setState({ ...newState });
        }
    };

    const getRows = (data) => {
        return data?.secretaryManagers?.map((item) => ({
            className: '',
            cells: [
                {
                    template: `${item?.fullName}`,
                    className: 'min-w-[128px] font-bold'
                },
                {
                    template: item?.role?.name,
                    className: 'min-w-[128px]'
                },
                {
                    template: item?.email,
                    className: 'min-w-[128px]'
                },
                {
                    template: item?.candidates?.length,
                    className: 'min-w-[128px] font-bold'
                },
                {
                    template: getInIterCall(item.candidates),
                    className: 'min-w-[128px] font-bold'
                },
                {
                    template: getInNeverMeetCall(item.candidates),
                    className: 'min-w-[128px] font-bold'
                },
                {
                    template: getInIterCall(item.candidates) + getInNeverMeetCall(item.candidates),
                    className: 'min-w-[128px] font-bold'
                },
                {
                    template: getInIterMeeting(item.candidates),
                    className: 'min-w-[128px] font-bold'
                },
                {
                    template: getInNeverMeetMeeting(item.candidate),
                    className: 'min-w-[128px] font-bold'
                },
                {
                    template:
                        getInIterMeeting(item.candidates) + getInNeverMeetMeeting(item.candidate),
                    className: 'min-w-[128px] font-bold'
                }
            ]
        }));
    };

    /**
     * Watchers
     */
    useEffect(() => {
        initYears();
        checkPagePermission(navigate);
        retrieveManagerData(state, filters, params.id);
    }, [filters, params.id]);

    useEffect(() => {
        retrieveAllUsersData(managerSearchString);
    }, [managerSearchString]);

    /**
     * Template
     */
    return (
        <section className="h-full">
            {state.isLoading ? <Loader /> : null}

            {state.isUpdateUserInfoModalOpen ? (
                <CreateManager
                    isOpen
                    isSecretary
                    selectedUserId={params.id}
                    isUpdateInfo={state.isUpdateUserInfoModalOpen}
                    closeModel={(reload = false) => onCloseCreateUserModal(state, reload)}
                />
            ) : null}

            {state.isAssignManagerModalOpen && (
                <ModalComponent
                    isOpen={!!state.isAssignManagerModalOpen}
                    closeModel={() => closeAssignManagerModel(state, false)}
                    title={
                        <div>
                            <SearchField
                                className="my-2 mx-auto w-full text-black"
                                defaultValue={managerSearchString}
                                onPressEnter={(e) => setManagerSearchString(e)}
                            />
                        </div>
                    }
                    size="max-w-[632px]">
                    <form
                        onSubmit={handleSubmit(() =>
                            onSubmitHandler(params.id, secretaryUsers, state)
                        )}>
                        <div className="p-6 h-[650px] overflow-y-auto">
                            {secretaryUsers &&
                                secretaryUsers.map((item, index) => {
                                    return (
                                        <div key={item.id}>
                                            <div className="p-2 flex border-b-2 w-full justify-between">
                                                <span>{`${item?.fullName} (${item?.role?.name})`}</span>
                                                <span>
                                                    <input
                                                        {...register('managers')}
                                                        type="checkbox"
                                                        checked={item.selected}
                                                        value={item.id}
                                                        onChange={() =>
                                                            selectedCheckboxOnChangeHandler(index)
                                                        }
                                                    />
                                                </span>
                                            </div>
                                            {/*                                             
                                            {managerRevenueData[item.template]?.length > 0 ? (
                                                managerRevenueData[item.template].map(
                                                    (candidate) => {
                                                        return (
                                                            <Link
                                                                to={`../candidate/${candidate.id}`}
                                                                className="w-full cursor-pointer border-b-2 p-2 block"
                                                                title={`${candidate.name} ${candidate.surname}`}>
                                                                {`${candidate.name} ${candidate.surname}`}
                                                            </Link>
                                                        );
                                                    }
                                                )
                                            ) : (
                                                <div className="w-full border-b-2 p-2 block">
                                                    Nessun dato
                                                </div>
                                            )} */}
                                        </div>
                                    );
                                })}
                        </div>
                        <div className="flex justify-center p-6 space-x-4 rounded-b bg-white">
                            <SecondaryButton onClick={() => closeAssignManagerModel(state, false)}>
                                ANNULLA
                            </SecondaryButton>
                            <PrimaryButton type="submit">MODIFICA</PrimaryButton>
                        </div>
                    </form>
                </ModalComponent>
            )}

            <AppHeader>
                <HeaderItem>
                    <Link className="cursor-pointer text-2xl font-bold" to="../secretaries">
                        <FontAwesomeIcon icon={faArrowLeft} /> Indietro
                    </Link>
                </HeaderItem>
            </AppHeader>

            <div className="h-[calc(100%-108px)] md:flex md:h-[calc(100%-64px)] bg-white py-4 rounded-md">
                {/* left side info section */}
                <div className="flex flex-row md:flex-shrink-0 px-4 md:w-72 xl:w-96 md:border-r-2 md:flex md:flex-col md:justify-between overflow-auto">
                    {/* Top */}
                    <div className="flex justify-between md:block">
                        <div className="border-r-2 md:border-r-0 md:border-b-2 px-2 md:px-0 md:py-2 space-y-3">
                            <span className="font-bold md:text-2xl">
                                {`${managerData.name} ${managerData.surname}`}
                            </span>
                            <div>
                                <span className="md:text-2xl">Stato : </span>
                                <span className="font-bold md:text-2xl">
                                    {managerData.isActive ? 'Attivo' : 'Inattivo'}
                                </span>
                            </div>
                            <div className="flex space-x-2 !mt-8">
                                <span>
                                    <FontAwesomeIcon icon={faLocationDot} />
                                </span>
                                <span>{managerData.address}</span>
                            </div>
                            <div className="flex space-x-2">
                                <span>
                                    <FontAwesomeIcon icon={faPhone} />
                                </span>
                                <span>{managerData.phoneNumber}</span>
                            </div>
                            <div className="flex space-x-2">
                                <span>
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </span>
                                <span>{managerData.email}</span>
                            </div>
                            <div className="flex justify-end text-primary-500">
                                <FontAwesomeIcon
                                    icon={faPencil}
                                    className="cursor-pointer"
                                    onClick={() =>
                                        updateState(state, 'isUpdateUserInfoModalOpen', true)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* main info section */}
                <div className="flex-1 overflow-x-hidden overflow-y-auto relative px-4 space-y-2">
                    <AppHeader>
                        <HeaderItem>
                            <span
                                className="cursor-pointer font-bold text-2xl"
                                onClick={() => setListType(state, 'activity')}
                                aria-hidden="true">
                                Manager assegnati
                            </span>
                        </HeaderItem>

                        <HeaderItem>
                            <div className="inline-block input-field-group">
                                <select
                                    id="year"
                                    name="year"
                                    value={filters.year}
                                    onChange={(e) => handleFilterChange(e, filters, 'year', state)}>
                                    {years.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </HeaderItem>
                    </AppHeader>

                    <AppHeader>
                        <HeaderItem />
                        <HeaderItem>
                            <PrimaryButton onClick={() => onClickAssignManagersBtn(state)}>
                                MODIFICA
                            </PrimaryButton>
                        </HeaderItem>
                    </AppHeader>

                    <DataTable
                        columns={SECRETARY_MANAGERS_TABLE_COLUMNS}
                        rows={getRows(managerData)}
                    />
                </div>
            </div>
        </section>
    );
}

export default Secretary;
