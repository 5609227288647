/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import Storage from '../helper/services/common/storage-helper-services';
import Loader from './Loader';
import AddNote from './modal/AddNote';

function CandidateNotes({
    candidate = null,
    isAddNewNoteOpen = false,
    updateNewNoteModalState = null
}) {
    const user = Storage.get('profile');
    const [selectedNote, setSelectedNote] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    const onCloseAddNoteModal = (isReload = false) => {
        setSelectedNote(null);
        updateNewNoteModalState(false, isReload);
    };

    const editNoteBtnClickHandler = (attachment) => {
        setSelectedNote({ ...attachment });
        updateNewNoteModalState(true, false);
    };

    // const deleteAttachment = (attachment) => {
    //     setSelectedNote({ ...attachment });
    //     setConfirmDeleteModalOpen(true);
    //     // updateCreateActivityModalState(true, false);
    // };

    const closeConfirmModalHandler = () => {
        setSelectedNote(null);
        // setConfirmDeleteModalOpen(false);
    };

    // const confirmDeleteAttachment = async () => {
    //     setIsLoading(true);
    //     const result = await deleteCandidateAttachment(selectedNote.id).catch((e) => {
    //         console.log(e);
    //         setIsLoading(false);
    //         toast.error(MESSAGES.SERVER_ERROR);
    //     });
    //     if (result?.ok) {
    //         setIsLoading(false);
    //         closeConfirmModalHandler();
    //         reload();
    //     }
    // };

    return (
        <section className="py-2">
            {isLoading ? <Loader /> : null}
            {/* {confirmDeleteModalOpen ? (
                <ConfirmationModal
                    isOpen={confirmDeleteModalOpen}
                    title="Elimina allegato"
                    message="Vuoi eliminare l’allegato selezionato?"
                    onConfirmAction={confirmDeleteAttachment}
                    closeModel={closeConfirmModalHandler}
                    confirmBtnText="ELIMINA"
                />
            ) : null} */}

            {isAddNewNoteOpen ? (
                <AddNote
                    isOpen={isAddNewNoteOpen}
                    candidateId={candidate.id}
                    selectedNote={selectedNote}
                    closeModel={onCloseAddNoteModal}
                />
            ) : null}

            <div>
                {candidate?.notes.map((note) => (
                    <div className="w-full border-b-2 py-3" key={note.id}>
                        <div className="flex w-full justify-between">
                            <strong>{note.createByUser.fullName}</strong>
                            <span>{note.createdAt}</span>
                        </div>
                        <div className="flex w-full mt-1">{note.note}</div>
                        <div className="flex w-full mt-1 justify-end text-primary-500">
                            {user.id === note.created_user_id && (
                                <FontAwesomeIcon
                                    icon={faPencil}
                                    className="cursor-pointer"
                                    onClick={() => editNoteBtnClickHandler(note)}
                                />
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default CandidateNotes;
