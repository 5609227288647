/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { MESSAGES } from '../../constants';
import { updateActivityFields } from '../../helper/services/apis/activity-services';
import PrimaryButton from '../PrimaryButton';
import RatingComponent from '../RatingComponent';
import SecondaryButton from '../SecondaryButton';
import ModalComponent from './ModalComponent';

// eslint-disable-next-line no-unused-vars
function AddActivityNote({ isOpen, closeModel = null, selectedActivity, isEditMode = false }) {
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors }
    } = useForm();

    const onSubmitHandler = async (formData) => {
        const result = await updateActivityFields(selectedActivity.id, { ...formData }).catch(
            (e) => {
                console.log(e);
                toast.error(MESSAGES.SERVER_ERROR);
            }
        );
        if (result?.ok) {
            toast.success(MESSAGES.SUCCESS);
            closeModel(true);
        }
    };

    const setActivityFormData = (data) => {
        const date =
            data.completedDate && data.completedDate != null
                ? data.completedDate
                : new Date().toJSON().slice(0, 10);
        reset({
            completedDate: date,
            note: data.note,
            rating: data.rating
        });
    };

    useEffect(() => {
        setActivityFormData(selectedActivity);
    }, [selectedActivity]);

    return (
        <ModalComponent isOpen={isOpen} closeModel={() => closeModel(false)} title="Termina azione">
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                {/* Modal Body */}
                <div className="p-6 space-y-6">
                    <div className="flex flex-col w-full space-y-4">
                        {selectedActivity.activityStatus === 'meeting' && (
                            <>
                                <div
                                    className={`w-full flex items-center space-x-2 input-field-group ${
                                        errors?.rating ? 'invalid-field' : ''
                                    }`}>
                                    <div className="w-1/4">
                                        <label htmlFor="name">Rating : </label>
                                    </div>
                                    <div className="w-5/6">
                                        <RatingComponent
                                            register={register}
                                            name="rating"
                                            errors={errors}
                                            config={{ required: true }}
                                            setValue={setValue}
                                            defaultValue={selectedActivity.rating}
                                            // disabled={disabled}
                                        />
                                    </div>
                                </div>
                                <div className="w-full flex items-center space-x-2 input-field-group">
                                    <div className="w-1/4">
                                        <label htmlFor="name">Assegnazione Rating : </label>
                                    </div>
                                    <div className="w-5/6">
                                        <div>
                                            <svg
                                                aria-hidden="true"
                                                className="transition w-5 h-6 mr-1 text-yellow-400 inline-block"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <title>{1}</title>
                                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                            </svg>
                                            Provenienza bancaria
                                        </div>
                                        <div>
                                            <svg
                                                aria-hidden="true"
                                                className="transition w-5 h-6 mr-1 text-yellow-400 inline-block"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <title>{2}</title>
                                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                            </svg>
                                            Da più di 5 anni nella stessa filiale
                                        </div>
                                        <div>
                                            <svg
                                                aria-hidden="true"
                                                className="transition w-5 h-6 mr-1 text-yellow-400 inline-block"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <title>{3}</title>
                                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                            </svg>
                                            Ambito gestione clienti
                                        </div>
                                        <div>
                                            <svg
                                                aria-hidden="true"
                                                className="transition w-5 h-6 mr-1 text-yellow-400 inline-block"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <title>{4}</title>
                                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                            </svg>
                                            Iscritto albo OCF
                                        </div>
                                        <div>
                                            <svg
                                                aria-hidden="true"
                                                className="transition w-5 h-6 mr-1 text-yellow-400 inline-block"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <title>{5}</title>
                                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                            </svg>
                                            {`RAL > 100.000`}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        <div
                            className={`w-full flex items-center space-x-2 input-field-group ${
                                errors?.completedDate ? 'invalid-field' : ''
                            }`}>
                            <div className="w-1/4">
                                <label htmlFor="note">Completata il* :</label>
                            </div>
                            <div className="w-5/6">
                                <input
                                    type="date"
                                    id="expireDate"
                                    name="expireDate"
                                    placeholder="Scadenza"
                                    max={new Date().toJSON().slice(0, 10)}
                                    {...register('completedDate', {
                                        required: true
                                    })}
                                />
                                {errors?.completedDate?.type === 'required' && (
                                    <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                )}
                            </div>
                        </div>

                        {!isEditMode ? (
                            <div
                                className={`w-full flex items-center space-x-2 input-field-group ${
                                    errors?.note ? 'invalid-field' : ''
                                }`}>
                                <div className="w-1/4">
                                    <label htmlFor="note">Note* :</label>
                                </div>
                                <div className="w-5/6">
                                    <textarea
                                        rows="4"
                                        id="note"
                                        name="note"
                                        placeholder="Inserisci note o modificale"
                                        {...register('note', {
                                            required: true,
                                            maxLength: 1000
                                        })}
                                        // disabled={disabled}
                                    />
                                    {errors?.note?.type === 'required' && (
                                        <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                    )}
                                    {errors?.note?.type === 'maxLength' && (
                                        <p className="text-red-700">
                                            {MESSAGES.maxLimitMessage(1000)}
                                        </p>
                                    )}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
                {/* Modal Footer */}
                <div className="flex justify-center p-6 space-x-4 rounded-b bg-white">
                    <SecondaryButton onClick={closeModel}>ANNULLA</SecondaryButton>
                    <PrimaryButton type="submit">CONFERMA</PrimaryButton>
                </div>
            </form>
        </ModalComponent>
    );
}

export default AddActivityNote;
