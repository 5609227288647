/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleCheck,
    faCross,
    faPencil,
    faTrashCan,
    faXmark
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import jsPDF from 'jspdf';
import DataTable from './DataTable';
import { ACTIVITY_STATUS, CANDIDATE_ACTIVITY_COLUMNS, MESSAGES } from '../constants';
import CreateActivity from './modal/CreateActivity';
import RatingComponent from './RatingComponent';
import { reformatDate, reformatDateTime, truncate } from '../helper/utility';
import ConfirmationModal from './modal/ConfirmationModal';
import { deleteActivity, updateActivityFields } from '../helper/services/apis/activity-services';
import Loader from './Loader';
import AddActivityNote from './modal/AddActivityNote';
import ReportTemplate from '../pages/ReportPage';

function CandidateActivities({
    candidate = null,
    isAddNoteOpen = false,
    isCreateActivityOpen = false,
    updateCreateActivityModalState = null,
    updateAddNoteModalState = null,
    reload
}) {
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [state, setState] = useState({
        isLoading: false,
        confirmCancelModalOpen: false,
        confirmDeleteModalOpen: false,
        isRequiredCreateNew: false,
        isEditCompletedDate: false
    });

    const onCloseCreateActivityModal = (isReload = false) => {
        setSelectedActivity(false);
        updateCreateActivityModalState(false, isReload);
    };

    const onCloseAddNoteModal = (isReload = false) => {
        setSelectedActivity(false);
        const addActivity = state.isRequiredCreateNew;
        if (addActivity) {
            setState({ ...state, isRequiredCreateNew: false, isEditCompletedDate: false });
        }
        updateAddNoteModalState(false, isReload, addActivity);
    };

    const updateActivityField = async (id, payload, createNote = false, addActivity = false) => {
        const result = await updateActivityFields(id, { ...payload }).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
            return false;
        });
        if (result?.ok) {
            toast.success(MESSAGES.SUCCESS);
            await reload(createNote, addActivity);
            return true;
        }
        return false;
    };

    const updateOutput = async (activity, value, currentState) => {
        const newState = { ...currentState };
        newState.isLoading = true;
        setState(newState);

        const result = await updateActivityField(
            activity.id,
            {
                output: value
            },
            true
        );

        newState.isLoading = false;
        if (result) {
            setSelectedActivity(activity);
            newState.isRequiredCreateNew = true;
        }
        setState(newState);
    };

    const getEsitoCellTemplate = (item) => {
        let template = null;
        if (item.output == null && !candidate?.isArchive) {
            template = (
                <div className="input-field-group">
                    <select onChange={(e) => updateOutput(item, e.target.value, state)} value="">
                        <option value="">Seleziona...</option>
                        <option value="positive">Positivo</option>
                        <option value="negative">Negativo</option>
                        <option value="unsuitable">Non idoneo</option>
                    </select>
                </div>
            );
        } else {
            template =
                // eslint-disable-next-line no-nested-ternary
                item.output === 'positive'
                    ? 'Positivo'
                    : item.output === 'negative'
                    ? 'Negativo'
                    : item.output === 'unsuitable'
                    ? 'Non idoneo'
                    : '';
        }
        return template;
    };

    const cancelActivity = async (selectedActivityId, currentState) => {
        const newState = { ...currentState };
        newState.isLoading = true;
        setState(newState);

        const result = await updateActivityField(selectedActivityId, {
            activityStatus: 'canceled'
        });

        newState.isLoading = false;

        if (result) {
            newState.confirmCancelModalOpen = false;
            setSelectedActivity(null);
            reload();
        }
        setState(newState);
    };

    const deleteSelectedActivity = async (selectedActivityId, currentState) => {
        const newState = { ...currentState };
        newState.isLoading = true;
        newState.confirmDeleteModalOpen = false;
        setState(newState);

        const result = await deleteActivity(selectedActivityId).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
            newState.isLoading = false;
            setState(newState);
        });
        newState.isLoading = false;
        setState(newState);
        if (result) {
            toast.success(MESSAGES.SUCCESS);
            setSelectedActivity(null);
            reload();
        }
    };

    const onClickUpdateActivityNote = (activity) => {
        const newState = { ...state };
        newState.isEditCompletedDate = true;
        setState({ ...newState });
        setSelectedActivity({ ...activity });
        updateAddNoteModalState(true, false);
    };

    const onClickEditActivity = (activity) => {
        setSelectedActivity({ ...activity });
        updateCreateActivityModalState(true, false);
    };

    const onClickCancelActivity = (activity, currentState) => {
        const newState = { ...currentState };
        newState.confirmCancelModalOpen = true;
        setSelectedActivity({ ...activity });
        setState(newState);
    };

    const onClickDeleteActivity = (activity, currentState) => {
        const newState = { ...currentState };
        newState.confirmDeleteModalOpen = true;
        setSelectedActivity({ ...activity });
        setState(newState);
    };

    const getNoteCellTemplate = (item) => {
        let template = null;
        if (item.note) {
            template = (
                <>
                    {truncate(item.note, 100)}
                    <br />
                    <span
                        aria-hidden="true"
                        onClick={() => onClickUpdateActivityNote(item)}
                        className="text-primary-500 font-bold cursor-pointer">
                        Espandi
                    </span>
                </>
            );
        } else {
            template = (
                <span
                    aria-hidden="true"
                    onClick={() => onClickUpdateActivityNote(item)}
                    className="text-primary-500 font-bold cursor-pointer">
                    Aggiungi note
                </span>
            );
        }
        return template;
    };

    const getActionTemplate = (item) => {
        let template = null;
        if (!candidate?.isArchive) {
            if (!item?.isBdActivity) {
                template = (
                    <div className="flex justify-between text-lg">
                        {item?.output ? (
                            <FontAwesomeIcon
                                icon={faCircleCheck}
                                className="text-primary-500 cursor-pointer"
                                onClick={() => onClickUpdateActivityNote(item)}
                            />
                        ) : null}
                        <FontAwesomeIcon
                            icon={faPencil}
                            className="text-primary-500 cursor-pointer"
                            onClick={() => onClickEditActivity(item)}
                        />
                        <FontAwesomeIcon
                            icon={faXmark}
                            className="text-red-500 cursor-pointer"
                            onClick={() => onClickCancelActivity(item, state)}
                        />
                        <FontAwesomeIcon
                            icon={faTrashCan}
                            className="text-red-500 cursor-pointer"
                            onClick={() => onClickDeleteActivity(item, state)}
                        />
                    </div>
                );
            } else if (item?.isBdActivity && item?.output) {
                template = (
                    <div className="flex justify-between text-lg">
                        <FontAwesomeIcon
                            icon={faCircleCheck}
                            className="text-primary-500 cursor-pointer"
                            onClick={() => onClickUpdateActivityNote(item)}
                        />
                    </div>
                );
            }
        }
        return template;
    };

    const getRows = (data = []) => {
        return data.map((item) => ({
            className: item.expiredEmailSent && !item.completedDate ? 'bg-red-300' : null,
            cells: [
                {
                    template: item?.createdByUser?.fullName
                        ? `${item?.createdByUser?.fullName}`
                        : '',
                    className: 'min-w-[128px] font-bold  border-lightBlue-600 border-y-2 border-l-2'
                },
                {
                    template: reformatDateTime(item.expireDate),
                    className: 'min-w-[128px]  border-lightBlue-600 border-y-2'
                },
                {
                    template: item.completedDate ? reformatDate(item.completedDate) : '',
                    className: 'min-w-[128px]  border-lightBlue-600 border-y-2'
                },
                {
                    template: item?.isBdActivity ? 'Compleanno' : item?.iter?.name,
                    className: 'min-w-[128px]  border-lightBlue-600 border-y-2'
                },
                // {
                //     template: item?.action?.name,
                //     className: 'min-w-[128px]  border-lightBlue-600 border-y-2'
                // },
                {
                    template: `${item?.manager?.fullName}`,
                    className: 'min-w-[128px] font-bold  border-lightBlue-600 border-y-2'
                },
                {
                    template: item?.activityWhere,
                    className: 'min-w-[128px]  border-lightBlue-600 border-y-2'
                },
                {
                    template: `${item?.performingManager?.fullName}`,
                    className: 'min-w-[128px] font-bold  border-lightBlue-600 border-y-2'
                },
                // {
                //     template: item?.mode?.name,
                //     className: 'min-w-[128px]  border-lightBlue-600 border-y-2'
                // },
                {
                    template: getEsitoCellTemplate(item),
                    className: 'min-w-[150px]  border-lightBlue-600 border-y-2'
                },
                {
                    template: (
                        <RatingComponent
                            readOnly
                            name="rating"
                            config={{ required: true }}
                            defaultValue={item.rating}
                        />
                    ),
                    className: 'min-w-[128px] border-lightBlue-600 border-y-2'
                },
                // {
                //     template: getNoteCellTemplate(item),
                //     className: 'min-w-[128px] border-lightBlue-600 border-y-2'
                // },
                {
                    template: (
                        <span
                            className="px-4 py-2 rounded-sm border-x"
                            style={{
                                background: ACTIVITY_STATUS[item.activityStatus]?.color,
                                color: ACTIVITY_STATUS[item.activityStatus]?.text
                            }}>
                            {ACTIVITY_STATUS[item.activityStatus].label}
                        </span>
                    ),
                    className: 'min-w-[160px] font-bold border-lightBlue-600 border-y-2'
                },
                {
                    template: getActionTemplate(item),
                    className: 'min-w-[128px] border-lightBlue-600 border-y-2 border-r-2'
                }
            ]
        }));
    };

    useEffect(() => {
        if (selectedActivity) {
            const activity = candidate.activities.find((a) => a.id === selectedActivity.id);
            if (activity) {
                setSelectedActivity({ ...activity });
            }
        }
    }, [candidate]);

    return (
        <section className="py-2">
            {state.isLoading ? <Loader /> : null}

            {isCreateActivityOpen ? (
                <CreateActivity
                    isOpen={isCreateActivityOpen}
                    openInDetailView="adc"
                    selectedActivity={selectedActivity}
                    closeModel={(isReolad) => onCloseCreateActivityModal(isReolad)}
                    candidateId={candidate.id}
                />
            ) : null}

            {isAddNoteOpen && (
                <AddActivityNote
                    isOpen={isAddNoteOpen}
                    selectedActivity={selectedActivity}
                    closeModel={(isReolad) => onCloseAddNoteModal(isReolad)}
                    disabled={!!candidate?.isArchive}
                    isEditMode={state.isEditCompletedDate}
                />
            )}

            {state.confirmCancelModalOpen ? (
                <ConfirmationModal
                    isOpen={state.confirmCancelModalOpen}
                    title="Annulla azione"
                    message="Sei sicuro di voler annullare l’attività selezionata? 
                Una volta annullata non sarà più possibile completarla.
                Continuare?"
                    onConfirmAction={() => cancelActivity(selectedActivity.id, state)}
                    closeModel={() => setState({ ...state, confirmCancelModalOpen: false })}
                    cancelBtnText="INDIETRO"
                    confirmBtnText="ANNULLA"
                />
            ) : null}

            {state.confirmDeleteModalOpen ? (
                <ConfirmationModal
                    isOpen={state.confirmDeleteModalOpen}
                    title="Elimina azione"
                    message="Vuoi eliminare l'azione selezionata?"
                    onConfirmAction={() => deleteSelectedActivity(selectedActivity.id, state)}
                    closeModel={() => setState({ ...state, confirmDeleteModalOpen: false })}
                    confirmBtnText="ELIMINA"
                />
            ) : null}

            <DataTable
                columns={CANDIDATE_ACTIVITY_COLUMNS}
                rows={getRows(candidate.activities)}
                className="h-[100%]"
            />
        </section>
    );
}

export default CandidateActivities;
