import { faArrowLeft, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DataTable from '../../component/DataTable';
import Loader from '../../component/Loader';
import PrimaryButton from '../../component/PrimaryButton';
import SearchField from '../../component/SearchField';
import AppHeader from '../../component/header/AppHeader';
import HeaderItem from '../../component/header/HeaderItem';
import { MESSAGES, SHARED_MANAGER_COLUMNS } from '../../constants';
import { sharedCandidateWithSelectedManagers } from '../../helper/services/apis/candidate-services';
import { getSharingManager } from '../../helper/services/apis/user-services';
import { buildQueryChunk } from '../../helper/utility';

function SharedManager() {
    const navigate = useNavigate();
    const params = useParams();
    const [managers, setManagers] = useState([]);
    const [managersCount, selectedManagersCount] = useState(0);
    const [filters, setFilters] = useState({
        searchString: ''
    });
    const { register, handleSubmit } = useForm();

    /**
     * Component State
     */
    const [state, setState] = useState({
        isLoading: false
    });

    const retrieveManagersData = async (currentState, id, currentFilters) => {
        const newState = { ...currentState };
        newState.isLoading = true;
        setState({ ...newState });
        const qs = buildQueryChunk('', 'searchString', currentFilters.searchString);

        const result = await getSharingManager(id, qs).catch((e) => {
            console.log(e);
            newState.isLoading = false;
            setState({ ...newState });
            navigate(`/dashboard`);
        });

        if (result?.ok) {
            const sharedIds = result.data.sharedUser;
            selectedManagersCount(sharedIds.length);
            const data = result.data.managers.map((manager) => {
                const selected = sharedIds.includes(manager.id);
                return {
                    ...manager,
                    selected
                };
            });
            setManagers(data);
        }
        newState.isLoading = false;
        setState({ ...newState });
    };

    const onSubmitHandler = async (candidateId, data, currentState) => {
        const newState = { ...currentState };
        newState.isLoading = true;
        setState({ ...newState });
        const managersId = [];
        data.forEach((element) => {
            if (element.selected) {
                managersId.push(element.id);
            }
        });
        const payload = { candidateId, managers: managersId };
        const result = await sharedCandidateWithSelectedManagers(payload).catch((e) => {
            console.log(e);
            newState.isLoading = false;
            toast.error(MESSAGES.SERVER_ERROR);
            setState({ ...newState });
        });
        if (result?.ok) {
            toast.success(MESSAGES.SUCCESS);
            newState.isLoading = false;
            setState({ ...newState });
        }
    };

    const selectedCheckboxOnChangeHandler = (index) => {
        const newManagers = [...managers];
        const selectedManager = newManagers[index];
        selectedManager.selected = !selectedManager.selected;
        setManagers([...newManagers]);
    };

    const getRows = (data) => {
        return data.map((item, index) => ({
            className: '',
            cells: [
                { template: item?.fullName, className: 'min-w-[128px] font-bold' },
                { template: item?.email, className: 'min-w-[128px]' },
                { template: item?.phoneNumber, className: 'min-w-[128px]' },
                { template: item?.manager?.fullName, className: 'min-w-[128px]' },
                {
                    template: (
                        <input
                            {...register('managers')}
                            type="checkbox"
                            checked={item.selected}
                            value={item.id}
                            onChange={() => selectedCheckboxOnChangeHandler(index)}
                        />
                    ),
                    className: 'min-w-[128px]'
                }
            ]
        }));
    };

    const handleFilterChange = (type, value, currentFilters) => {
        const newFilters = { ...currentFilters };
        newFilters[type] = value;
        setFilters(newFilters);
    };

    /**
     * Watchers
     */
    useEffect(() => {
        retrieveManagersData(state, params.id, filters);
    }, [params.id, filters]);

    return (
        <section className="h-full">
            {state.isLoading ? <Loader /> : null}

            <AppHeader>
                <HeaderItem>
                    <Link className="cursor-pointer text-2xl font-bold" to="./../">
                        <FontAwesomeIcon icon={faArrowLeft} /> Indietro
                    </Link>
                </HeaderItem>
            </AppHeader>

            <div className="h-[calc(100%-108px)] md:h-[calc(100%-64px)] bg-white p-4 space-y-2 overflow-auto">
                <AppHeader>
                    <HeaderItem>
                        <span className="font-bold text-2xl">
                            Condividi candidato ({managersCount})
                        </span>
                    </HeaderItem>
                    <HeaderItem>
                        <SearchField
                            defaultValue={filters.searchString}
                            onPressEnter={(e) => handleFilterChange('searchString', e, filters)}
                        />
                    </HeaderItem>
                </AppHeader>
                <form onSubmit={handleSubmit(() => onSubmitHandler(params.id, managers, state))}>
                    <AppHeader>
                        <HeaderItem />
                        <HeaderItem>
                            <PrimaryButton type="submit">
                                <FontAwesomeIcon icon={faFloppyDisk} />
                            </PrimaryButton>
                        </HeaderItem>
                    </AppHeader>
                    <DataTable
                        columns={SHARED_MANAGER_COLUMNS}
                        rows={getRows(managers)}
                        className="h-[100%]"
                    />
                </form>
            </div>
        </section>
    );
}

export default SharedManager;
