/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { MESSAGES } from '../../constants';
import {
    addCandidateNote,
    deleteCandidateNote,
    updateCandidateNote
} from '../../helper/services/apis/candidate-services';
import Loader from '../Loader';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import ModalComponent from './ModalComponent';

function AddNote({ isOpen, closeModel = null, selectedNote = null, candidateId }) {
    const [note, setNote] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const onNoteChange = (event) => {
        event.stopPropagation();
        event.preventDefault();
        const target = { ...event.target };
        setNote(target.value);
    };

    const onSubmitHandler = async () => {
        setIsLoading(true);
        if (selectedNote) {
            const payload = {
                note,
                candidateId
            };

            const result = await updateCandidateNote(selectedNote.id, payload).catch((e) => {
                console.log(e);
                setIsLoading(false);
                toast.error(MESSAGES.SERVER_ERROR);
            });
            if (result?.ok) {
                setIsLoading(false);
                toast.success(MESSAGES.SUCCESS);
                closeModel(true);
            }
        } else {
            const result = await addCandidateNote({
                note,
                candidateId
            }).catch((e) => {
                console.log(e);
                setIsLoading(false);
                toast.error(MESSAGES.SERVER_ERROR);
            });
            if (result?.ok) {
                setIsLoading(false);
                toast.success(MESSAGES.SUCCESS);
                closeModel(true);
            }
        }
    };

    const onDeletHandler = async () => {
        setIsLoading(true);

        const result = await deleteCandidateNote(selectedNote.id).catch((e) => {
            console.log(e);
            setIsLoading(false);
            toast.error(MESSAGES.SERVER_ERROR);
        });
        if (result?.ok) {
            setIsLoading(false);
            toast.success(MESSAGES.SUCCESS);
            closeModel(true);
        }
    };

    useEffect(() => {
        if (selectedNote) {
            setNote(selectedNote.note);
        }
    }, [selectedNote]);

    return (
        <ModalComponent
            isOpen={isOpen}
            closeModel={() => closeModel(false)}
            title={selectedNote ? 'Modifica nota' : 'Aggiungi nota'}>
            {/* Modal Body */}
            {isLoading ? <Loader /> : null}
            <div className="p-4 input-field-group">
                <textarea
                    className="w-full"
                    rows={8}
                    value={note}
                    onChange={onNoteChange}
                    placeholder="Inserisci nota"
                />
            </div>

            {/* Modal Footer */}
            {/* {files.length > 0 ? ( */}
            <div className="flex justify-center p-6 space-x-4 rounded-b bg-white">
                <SecondaryButton onClick={closeModel}>ANNULLA</SecondaryButton>
                <PrimaryButton onClick={onSubmitHandler}>
                    {selectedNote ? 'MODIFICA' : 'AGGIUNGI'}
                </PrimaryButton>
            </div>
            {selectedNote ? (
                <div className="flex justify-center py-4 space-x-4 rounded-b bg-white cursor-pointer">
                    <span onClick={onDeletHandler} aria-hidden="true" className="text-red-500">
                        Elimina nota
                    </span>
                </div>
            ) : null}

            {/* ) : null} */}
        </ModalComponent>
    );
}

export default AddNote;
