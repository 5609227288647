/* eslint-disable no-nested-ternary */
import {
    faCheck,
    faFilter,
    faFilterCircleXmark,
    faTrashCan
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import DataTable from '../../component/DataTable';
import Loader from '../../component/Loader';
import PrimaryButton from '../../component/PrimaryButton';
import SearchField from '../../component/SearchField';
import AppHeader from '../../component/header/AppHeader';
import HeaderItem from '../../component/header/HeaderItem';
import CandidateListingFilters from '../../component/modal/CandidateListingFilters';
import ConfirmationModal from '../../component/modal/ConfirmationModal';
import CreateCandidate from '../../component/modal/CreateCandidate';
import {
    ACTIVITY_STATUS,
    CANDIDATES_TABLE_COLUMNS,
    CANDIDATE_STATUS,
    MESSAGES
} from '../../constants';
import {
    deleteCandidate,
    getCandidatesListCSVData,
    getCandidatesListData
} from '../../helper/services/apis/candidate-services';
import Storage from '../../helper/services/common/storage-helper-services';
import { buildQueryChunk, generateAndDownloadXlsx, getPaginateData } from '../../helper/utility';

const INIT_FILTERS = {
    managers: [],
    mngManagers: [],
    banks: [],
    branches: [],
    days: [],
    activityStatuses: [],
    candidateStatuses: [],
    searchString: '',
    iter: null,
    startDate: '',
    endDate: ''
};

const CANDIDATE_FILTERS = {};
const resetLocalFilters = () => {
    const CANDIDATE_LOCAL_FILTERS = JSON.parse(Storage.get('candidateFilters'));
    Object.keys(INIT_FILTERS).forEach((key) => {
        CANDIDATE_FILTERS[key] =
            CANDIDATE_LOCAL_FILTERS &&
            CANDIDATE_LOCAL_FILTERS[key] &&
            CANDIDATE_LOCAL_FILTERS[key].length
                ? CANDIDATE_LOCAL_FILTERS[key]
                : INIT_FILTERS[key];
    });
};

function Candidates() {
    const [candidatesData, setCandidatesData] = useState([]);
    const [selectedCandidateId, setSelectedCandidateId] = useState(null);
    const [itersCount, setItersCount] = useState([]);
    const [paginateData, setPaginateData] = useState(null);
    const [appliedFilterCount, setAppliedFilterCount] = useState(0);
    const [user, setUser] = useState(null);

    /**
     * Component State
     */
    const [state, setState] = useState({
        isLoading: false,
        isFiltersModalOpen: false,
        listType: 'active', // 'active'|| 'archive'
        isCreateCandidateOpen: false,
        isConfirmDeleteOpen: false
    });

    resetLocalFilters();
    const [filters, setFilters] = useState({ ...CANDIDATE_FILTERS });

    /**
     * Business Logic
     */
    const setListType = (currentState, type) => {
        const data = { ...currentState };
        data.listType = type;
        setState({ ...data });
    };

    const handleFilterChange = (type, value, currentFilters) => {
        const newFilters = { ...currentFilters };
        if (type === 'iter') {
            if (newFilters.iter === value) {
                newFilters[type] = null;
            } else {
                newFilters[type] = value;
            }
        } else {
            newFilters[type] = value;
        }
        setFilters(newFilters);
    };

    const filterBtnClickHandler = (currentState) => {
        const data = { ...currentState };
        data.isFiltersModalOpen = true;
        setState({ ...data });
    };

    const closeFilterModal = (currentState, reset = false) => {
        const data = { ...currentState };
        data.isFiltersModalOpen = false;
        setState({ ...data });
        if (reset) {
            setFilters({ ...INIT_FILTERS });
        }
    };

    const onClickCreateCandidateBtn = (currentState) => {
        const data = { ...currentState };
        data.isCreateCandidateOpen = true;
        setState({ ...data });
    };

    const generateQueryString = (listType, currentFilters, qs) => {
        let queryString = qs;

        if (listType === 'archive') {
            queryString = buildQueryChunk(queryString, 'isArchive', true);
        }

        Object.keys(currentFilters).forEach((key) => {
            const filter = currentFilters[key];
            if (
                (key === 'iter' ||
                    key === 'searchString' ||
                    key === 'startDate' ||
                    key === 'endDate') &&
                filter
            ) {
                queryString = buildQueryChunk(queryString, key, filter);
            } else if (filter && filter?.length) {
                const value = filter.map((item) => item.value).join(',');
                queryString = buildQueryChunk(queryString, key, value);
            }
        });

        return queryString;
    };

    const retrieveCandidatesData = async (currentState, currentFilters, queryString = '') => {
        const data = { ...currentState };
        data.isLoading = true;
        setState({ ...data });

        const qs = generateQueryString(currentState.listType, currentFilters, queryString);
        const result = await getCandidatesListData(qs).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
            data.isLoading = false;
            setState({ ...data });
        });

        if (result.data) {
            setCandidatesData([...result.data.data.data]);
            setItersCount([...result.data.iter]);
            setPaginateData({ ...getPaginateData(result.data) });
        }

        data.isLoading = false;
        setState({ ...data });
    };

    const onCloseCreateCandidateModal = (currentState, currentFilters, reload = false) => {
        const data = { ...currentState };
        data.isCreateCandidateOpen = false;
        setState({ ...data });
        if (reload) {
            retrieveCandidatesData(data, currentFilters);
        }
    };

    const handlePagination = (currentState, currentFilters, qs) => {
        retrieveCandidatesData(currentState, currentFilters, qs);
    };

    const deleteOnClickHandler = (currentState, id) => {
        const newState = { ...currentState };
        newState.isConfirmDeleteOpen = true;
        setSelectedCandidateId(id);
        setState(newState);
    };

    const onCloseDeleteConfirmModal = (currentState) => {
        const newState = { ...currentState };
        newState.isConfirmDeleteOpen = false;
        setSelectedCandidateId(null);
        setState(newState);
    };

    const removeConfirmClickHandler = async (currentState, currentFilters, id) => {
        const newState = { ...currentState };
        newState.isLoading = true;
        setState(newState);

        const result = await deleteCandidate(id).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
            newState.isLoading = false;
            setState(newState);
        });
        if (result?.ok) {
            toast.success(MESSAGES.SUCCESS);
            newState.isLoading = false;
            newState.isConfirmDeleteOpen = false;
            setState(newState);
            setSelectedCandidateId(null);
            retrieveCandidatesData(newState, currentFilters);
        }
    };

    const getRows = (data, logedinUser) => {
        return data.map((item) => ({
            className: '',
            cells: [
                {
                    template: `${item?.manager?.fullName}`,
                    className: 'min-w-[128px]'
                },
                {
                    template: `${item?.mngManager?.fullName}`,
                    className: 'min-w-[128px]'
                },
                {
                    template: (
                        <Link
                            to={`../candidate/${item.id}`}
                            className="text-primary-800"
                            title={`${item.name} ${item.surname}`}>
                            {`${item.name} ${item.surname}`}
                        </Link>
                    ),
                    className: 'min-w-[128px] font-bold'
                },
                {
                    template: item?.generalInfo?.ocfRegistered ? (
                        <FontAwesomeIcon icon={faCheck} />
                    ) : null,
                    className: 'min-w-[128px] text-primary-800'
                },
                {
                    template: item?.generalInfo?.bank?.name,
                    className: 'min-w-[128px]'
                },
                {
                    template: item?.generalInfo?.commonBranch?.name,
                    className: 'min-w-[128px]'
                },
                {
                    template: (
                        <a
                            href={`mailto:${item.email}`}
                            className="text-primary-800 cursor-pointer">
                            {item.email}
                        </a>
                    ),
                    className: 'min-w-[128px]'
                },
                {
                    template: item.mobileNumber,
                    className: 'min-w-[128px]'
                },
                {
                    template: item?.iter?.name,
                    className: 'min-w-[128px] font-bold'
                },
                {
                    template: item?.currentActivity?.activityStatus
                        ? ACTIVITY_STATUS?.[item?.currentActivity?.activityStatus].label
                        : '',
                    className: 'min-w-[128px] font-bold'
                },
                {
                    template: (
                        <span
                            className={`flex justify-center items-center border-4 w-11 h-11 leading-none rounded-full ${
                                item?.currentIterCounter < 11
                                    ? 'border-green-600 text-green-600'
                                    : item?.currentIterCounter < 21
                                    ? 'border-yellow-600 text-yellow-600'
                                    : 'border-red-600 text-red-600'
                            }`}>
                            {item?.currentIterCounter ? item?.currentIterCounter : 0}
                        </span>
                    ),
                    className: 'min-w-[128px] font-bold'
                },
                {
                    template: item?.totalIterCounter ? item?.totalIterCounter : 0,
                    className: 'min-w-[128px]'
                },
                {
                    template: (
                        <span
                            className="px-4 py-2 rounded-sm"
                            style={{
                                background: CANDIDATE_STATUS[item.status.name]?.color,
                                color: CANDIDATE_STATUS[item.status.name]?.text
                            }}>
                            {item.status.name}
                        </span>
                    ),
                    className: 'min-w-[209px] font-bold'
                },
                {
                    template:
                        logedinUser?.role === 'admin' ? (
                            <FontAwesomeIcon
                                icon={faTrashCan}
                                className="cursor-pointer"
                                onClick={() => deleteOnClickHandler(state, item.id)}
                            />
                        ) : (
                            ''
                        ),
                    className: 'min-w-[128px] text-lg text-center text-red-600'
                }
            ]
        }));
    };

    const setFilterCountAndSaveInLocal = (currentFilters) => {
        let count = 0;
        if (currentFilters) {
            Object.keys(currentFilters).forEach((key) => {
                const item = currentFilters[key];
                if (item && item.length > 0) {
                    count += 1;
                }
            });
        }
        setAppliedFilterCount(count);
        Storage.set('candidateFilters', JSON.stringify(currentFilters));
        resetLocalFilters();
    };
    const downloadCsvData = async (currentState, currentFilters) => {
        const data = { ...currentState };
        data.isLoading = true;
        setState({ ...data });

        const qs = generateQueryString(currentState.listType, currentFilters);
        const result = await getCandidatesListCSVData(qs).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
            data.isLoading = false;
            setState({ ...data });
            return false;
        });

        if (result.data) {
            data.isLoading = false;
            setState({ ...data });
            return result.data;
        }
        return [];
    };

    const downloadCsv = async (currentState, currentFilters) => {
        const data = await downloadCsvData(currentState, currentFilters);
        const columns = [
            { header: 'Manager', field: 'manager' },
            { header: 'MNG Crescita', field: 'mngManager' },
            { header: 'Candidato', field: 'candidate' },
            { header: 'Albo OCF', field: 'ocfRegistered' },
            { header: 'Banca', field: 'bank' },
            { header: 'Comune filiale', field: 'branch' },
            { header: 'Mail', field: 'mail' },
            { header: 'Cellulare', field: 'phoneNumber' },
            { header: 'Fase', field: 'iter' },
            { header: 'Azioni da fare', field: 'activityStatus' },
            { header: 'Giorni in step', field: 'currentIterCounter' },
            { header: 'Giorni in iter', field: 'totalIterCounter' },
            { header: 'Stato', field: 'status' }
        ];
        const finalData = data.map((item) => {
            return {
                manager: item?.manager?.fullName,
                mngManager: item?.mngManager?.fullName,
                candidate: `${item.name} ${item.surname}`,
                ocfRegistered: item?.generalInfo?.ocfRegistered,
                bank: item?.generalInfo?.bank?.name,
                branch: item?.generalInfo?.commonBranch?.name,
                mail: item.email,
                phoneNumber: item.phoneNumber,
                iter: item?.iter?.name,
                activityStatus: item?.currentActivity?.activityStatus,
                currentIterCounter: item?.currentIterCounter,
                totalIterCounter: item?.totalIterCounter,
                status: item.status.name
            };
        });

        const selectedColumns = columns.map((item) => ({
            label: item.header,
            value: item.field
        }));
        generateAndDownloadXlsx(selectedColumns, finalData, 'Candidati');
    };

    /**
     * Watchers
     */
    useEffect(() => {
        // resetLocalFilters();
        setFilterCountAndSaveInLocal(filters);
        retrieveCandidatesData(state, filters);
    }, [state.listType, filters]);

    useEffect(() => {
        const data = Storage.get('profile');
        console.log(data);
        setUser(JSON.parse(data));
    }, []);

    /**
     * Template
     */
    return (
        <>
            {state.isLoading ? <Loader /> : null}

            <CandidateListingFilters
                isOpen={state.isFiltersModalOpen}
                handleFilterChange={handleFilterChange}
                filters={filters}
                closeModel={(reset) => closeFilterModal(state, reset)}
            />

            {state.isConfirmDeleteOpen ? (
                <ConfirmationModal
                    isOpen={state.isConfirmDeleteOpen}
                    title="Elimina candidato"
                    message="Vuoi eliminare il candidato scelto?"
                    onConfirmAction={() =>
                        removeConfirmClickHandler(state, filters, selectedCandidateId)
                    }
                    closeModel={() => onCloseDeleteConfirmModal(state)}
                    confirmBtnText="ELIMINA"
                />
            ) : null}

            {state.isCreateCandidateOpen ? (
                <CreateCandidate
                    isOpen={state.isCreateCandidateOpen}
                    closeModel={(reload) => onCloseCreateCandidateModal(state, filters, reload)}
                />
            ) : null}
            <section className="space-y-2">
                <AppHeader>
                    <HeaderItem>
                        <span
                            className={`cursor-pointer font-bold text-2xl ${
                                state.listType === 'active' ? 'text-grey-900' : 'text-grey-600'
                            }`}
                            onClick={() => setListType(state, 'active')}
                            aria-hidden="true">
                            {state.listType === 'active'
                                ? paginateData?.total
                                    ? paginateData?.total
                                    : null
                                : null}{' '}
                            Candidati
                        </span>
                        <span className="text-2xl">|</span>
                        <span
                            className={`cursor-pointer font-bold text-2xl ${
                                state.listType === 'archive' ? 'text-grey-900' : 'text-grey-600'
                            }`}
                            onClick={() => setListType(state, 'archive')}
                            aria-hidden="true">
                            {state.listType === 'archive'
                                ? paginateData?.total
                                    ? paginateData?.total
                                    : null
                                : null}{' '}
                            Archivio
                        </span>
                    </HeaderItem>
                    <HeaderItem className="">
                        <PrimaryButton onClick={() => filterBtnClickHandler(state)}>
                            <FontAwesomeIcon
                                icon={appliedFilterCount ? faFilterCircleXmark : faFilter}
                            />
                        </PrimaryButton>
                        <PrimaryButton onClick={() => downloadCsv(state, filters)}>
                            XLS
                        </PrimaryButton>
                    </HeaderItem>
                </AppHeader>

                <AppHeader>
                    <HeaderItem />
                    <HeaderItem className="md:ml-auto">
                        {/* <Select
                            defaultValue={[...filters.mngManagers]}
                            isMulti
                            onChange={(e) => handleFilterChange('mngManagers', e, filters)}
                            options={mngManagers}
                            className="basic-multi-select w-48"
                            classNamePrefix="select"
                            placeholder="MNG Crescita"
                            styles={styles}
                        />
                        <Select
                            defaultValue={[...filters.managers]}
                            isMulti
                            onChange={(e) => handleFilterChange('managers', e, filters)}
                            options={managers}
                            className="basic-multi-select w-48"
                            classNamePrefix="select"
                            placeholder="Manager"
                            styles={styles}
                        />
                        <Select
                            defaultValue={[...filters.banks]}
                            isMulti
                            onChange={(e) => handleFilterChange('banks', e, filters)}
                            options={bank}
                            className="basic-multi-select w-48"
                            classNamePrefix="select"
                            placeholder="Banca"
                            styles={styles}
                        />
                        <Select
                            defaultValue={[...filters.branches]}
                            isMulti
                            onChange={(e) => handleFilterChange('branches', e, filters)}
                            options={branches}
                            className="basic-multi-select w-48"
                            classNamePrefix="select"
                            placeholder="Comune"
                            styles={styles}
                        /> */}
                    </HeaderItem>
                </AppHeader>

                <AppHeader>
                    <HeaderItem>
                        {itersCount.map((iter) => (
                            <span
                                key={iter.id}
                                aria-hidden="true"
                                className={`px-4 py-1 bg-white rounded-lg text-[13px] cursor-pointer ${
                                    filters?.iter &&
                                    filters?.iter === iter.id &&
                                    'bg-primary-500 text-white'
                                }`}
                                onClick={() => handleFilterChange('iter', iter.id, filters)}>
                                <b>{iter.candidatesCount}</b> {iter.name}
                            </span>
                        ))}
                    </HeaderItem>
                    <HeaderItem />
                </AppHeader>
                <AppHeader>
                    <HeaderItem className="w-80">
                        <SearchField
                            defaultValue={filters?.searchString}
                            onPressEnter={(e) => handleFilterChange('searchString', e, filters)}
                        />
                    </HeaderItem>
                    <HeaderItem>
                        <PrimaryButton onClick={onClickCreateCandidateBtn}>AGGIUNGI</PrimaryButton>
                    </HeaderItem>
                </AppHeader>

                <DataTable
                    columns={CANDIDATES_TABLE_COLUMNS}
                    rows={getRows(candidatesData, user)}
                    paginateData={paginateData}
                    handlePagination={(qs) => handlePagination(state, filters, qs)}
                />
            </section>
        </>
    );
}

export default Candidates;
