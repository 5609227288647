/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { INIT_ACTIVITY, MESSAGES } from '../../constants';
import { createNewActivity, updateActivity } from '../../helper/services/apis/activity-services';
import Loader from '../Loader';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import CandidateDropdown from '../dropdown/CandidateDropdown';
import IterDropdown from '../dropdown/IterDropdown';
import ManagersDropdown from '../dropdown/ManagersDropdown';
import ModalComponent from './ModalComponent';

function CreateActivity({
    isOpen,
    closeModel,
    selectedActivity = null,
    candidateId = null,
    openInDetailView = false
}) {
    const {
        register,
        handleSubmit,
        reset,
        setValue,

        formState: { errors }
    } = useForm();
    const [isLoading, setIsLoading] = useState(false);

    const onSubmitHandler = async (formData) => {
        setIsLoading(true);
        if (selectedActivity) {
            const payload = { ...formData };
            if (payload.performingManagerId.length <= 0) {
                payload.performingManagerId = payload.managerId;
            }
            const result = await updateActivity(selectedActivity.id, {
                ...payload
            }).catch((e) => {
                console.log(e);
                toast.error(MESSAGES.SERVER_ERROR);
                setIsLoading(false);
            });
            if (result?.ok) {
                toast.success(MESSAGES.SUCCESS);
                setIsLoading(false);
                closeModel(true);
            }
        } else {
            const payload = { ...formData };
            if (payload.performingManagerId.length <= 0) {
                payload.performingManagerId = payload.managerId;
            }
            const result = await createNewActivity({
                candidateId,
                ...payload
            }).catch((e) => {
                console.log(e);
                toast.error(MESSAGES.SERVER_ERROR);
                setIsLoading(false);
            });
            if (result?.ok) {
                toast.success(MESSAGES.SUCCESS);
                setIsLoading(false);
                closeModel(true);
            }
        }
    };

    const setUserFormData = (data) => {
        if (data) {
            reset({ ...data });
        } else {
            reset({ ...INIT_ACTIVITY, candidateId });
        }
    };

    useEffect(() => {
        if (selectedActivity) {
            setUserFormData(selectedActivity);
        } else {
            setUserFormData(null);
        }
    }, [selectedActivity]);

    return (
        <ModalComponent
            isOpen={isOpen}
            closeModel={() => closeModel(false)}
            title={!selectedActivity ? 'Aggiungi azioni' : 'Modifica azioni'}
            size="max-w-[632px]">
            {isLoading ? <Loader /> : null}
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                {/* Modal Body */}
                <div className="p-6 space-y-6">
                    <div className="flex flex-col w-full space-y-4">
                        <div
                            className={`w-full flex items-center space-x-2 input-field-group ${
                                errors?.expireDate ? 'invalid-field' : ''
                            }`}>
                            <div className="w-1/3">
                                <label htmlFor="expireDate">Quando* : </label>
                            </div>
                            <div className="w-2/3">
                                <input
                                    type="datetime-local"
                                    id="expireDate"
                                    name="expireDate"
                                    placeholder="Scadenza"
                                    // min={new Date().toJSON().slice(0, 10)}
                                    {...register('expireDate', {
                                        required: true
                                    })}
                                />
                                {errors?.expireDate?.type === 'required' && (
                                    <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                )}
                            </div>
                        </div>
                        {/* {!candidateId && !selectedActivity ? ( */}
                        {!openInDetailView ? (
                            <CandidateDropdown
                                register={register}
                                name="candidateId"
                                label="Candidato"
                                errors={errors}
                                setValue={setValue}
                                config={{ required: true }}
                            />
                        ) : null}

                        <div
                            className={`w-full flex items-center space-x-2 input-field-group ${
                                errors?.activityStatus ? 'invalid-field' : ''
                            }`}>
                            <div className="w-1/3">
                                <label htmlFor="activityId">Stato attività* :</label>
                            </div>
                            <div className="w-2/3">
                                <select
                                    id="activityStatus"
                                    {...register('activityStatus', {
                                        required: true
                                    })}
                                    disabled={selectedActivity}>
                                    <option value="">Seleziona...</option>
                                    <option value="call">Chiamata</option>
                                    <option value="recall">Richiamare</option>
                                    <option value="meeting">App. fissato</option>
                                </select>
                                {errors?.activityStatus?.type === 'required' && (
                                    <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                )}
                            </div>
                        </div>

                        <ManagersDropdown
                            register={register}
                            name="managerId"
                            errors={errors}
                            config={{ required: true }}
                            label="Chi la svolge"
                            subManager
                            mng
                            secretary
                            candidateId={candidateId}
                        />

                        <div
                            className={`w-full flex items-center space-x-2 input-field-group ${
                                errors?.activityWhere ? 'invalid-field' : ''
                            }`}>
                            <div className="w-1/3">
                                <label htmlFor="activityId">Dove :</label>
                            </div>
                            <div className="w-2/3">
                                <select
                                    id="activityWhere"
                                    {...register('activityWhere', {
                                        required: false
                                    })}
                                    disabled={selectedActivity}>
                                    <option value="">Seleziona...</option>
                                    <option value="Fbo">Fbo</option>
                                    <option value="Teams">Teams</option>
                                </select>
                                {errors?.activityWhere?.type === 'required' && (
                                    <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                )}
                            </div>
                        </div>

                        {/* ) : null} */}

                        {/* <GeneralTablesDropdown
                            register={register}
                            type="actions"
                            name="activityId"
                            label="Azioni"
                            errors={errors}
                            setValue={setValue}
                            config={{ required: true }}
                        /> */}

                        <IterDropdown
                            register={register}
                            name="iterId"
                            errors={errors}
                            disabled={selectedActivity}
                            config={{ required: true }}
                            label="Fase iter"
                        />

                        {/* <GeneralTablesDropdown
                            register={register}
                            type="places"
                            name="placeId"
                            label="Luogo"
                            errors={errors}
                            config={{ required: true }}
                            setValue={setValue}
                        />

                        <GeneralTablesDropdown
                            register={register}
                            type="modes"
                            name="modeId"
                            label="Modalità"
                            errors={errors}
                            config={{ required: true }}
                            setValue={setValue}
                        /> */}

                        <ManagersDropdown
                            register={register}
                            name="performingManagerId"
                            errors={errors}
                            config={{ required: false }}
                            label="Con chi"
                            subManager
                            mng
                            secretary
                            candidateId={candidateId}
                        />
                    </div>
                </div>

                {/* Modal Footer */}
                <div className="flex justify-center p-6 space-x-4 rounded-b bg-white">
                    <SecondaryButton onClick={() => closeModel(false)}>ANNULLA</SecondaryButton>
                    <PrimaryButton type="submit">
                        {selectedActivity ? 'MODIFICA' : 'AGGIUNGI'}
                    </PrimaryButton>
                </div>
            </form>
        </ModalComponent>
    );
}

export default CreateActivity;
