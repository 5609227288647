/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useLocation, Outlet, useNavigate } from 'react-router-dom';

import { logoutProcess } from '../../helper/utility';
import AppLogo from '../../component/AppLogo';
import SideMenu from '../../component/Layout/SideMenu';
import Header from '../../component/Layout/Header';
import Storage from '../../helper/services/common/storage-helper-services';

function Dashboard() {
    const location = useLocation();
    const navigate = useNavigate();

    const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);

    const checkIsActive = (path) => {
        return location.pathname.includes(path);
    };

    const logoutBtnOnPressHandler = () => {
        logoutProcess();
    };

    const handleToggleMobileSidebar = () => {
        setIsMobileSidebarOpen(!isMobileSidebarOpen);
    };

    useEffect(() => {
        let data = Storage.get('profile');
        data = JSON.parse(data);
        if (data.firstTimeLogin) {
            navigate(`/update-password/${data.id}`);
        }
    }, []);

    return (
        <div>
            <div className="flex h-screen antialiased bg-lightBlue-700">
                <SideMenu className="hidden md:block" />

                <div className="flex-1 flex flex-col overflow-hidden h-full relative">
                    {/* Mobile Header */}
                    <header className="md:hidden flex justify-between items-center p-4 h-[72px] bg-white">
                        <AppLogo width="54px" />
                        <button
                            onClick={() => handleToggleMobileSidebar()}
                            className="p-1 transition-colors duration-200 rounded-md text-primary-lighter bg-primary-50 hover:text-white hover:bg-primary-800 focus:outline-none focus:ring">
                            <span className="sr-only">Open main manu</span>
                            <span aria-hidden="true">
                                <svg
                                    className="w-8 h-8"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>
                            </span>
                        </button>
                    </header>

                    {isMobileSidebarOpen ? (
                        <div className="md:hidden h-screen w-screen  absolute top-[72px] left-0">
                            <div className="flex flex-row-reverse h-full w-full bg-mainBlack opacity-80 absolute top-0 left-0" />
                            <div className="flex flex-row-reverse h-full">
                                <SideMenu className="z-10" />
                            </div>
                        </div>
                    ) : null}

                    <Header />

                    <main className="p-4 flex-1 h-[calc(100%_-_72px)] overflow-y-auto">
                        <Outlet />
                    </main>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
