/* eslint-disable react/prop-types */
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import UpdateGeneralInfo from './modal/UpdateGeneralInfo';
import { reformatDate } from '../helper/utility';

function CandidateInformation({ candidate = null, reload }) {
    /**
     * Component State
     */
    const [state, setState] = useState({
        isLoading: false,
        isUpdateGeneralModalOpen: false,
        isProfessional: false
    });

    const onModalCloseHandler = (currentState, isReload = false) => {
        const newState = { ...currentState };
        newState.isUpdateGeneralModalOpen = false;
        newState.isProfessional = false;
        setState({ ...newState });
        if (isReload) {
            reload();
        }
    };

    const onEditBtnClickHandler = (currentState, isPro) => {
        const newState = { ...currentState };
        newState.isUpdateGeneralModalOpen = true;
        newState.isProfessional = isPro;
        setState({ ...newState });
    };

    return (
        <aside className="py-2 {candidate?.generalInfo?.} space-y-3">
            {state.isUpdateGeneralModalOpen ? (
                <UpdateGeneralInfo
                    isOpen={state.isUpdateGeneralModalOpen}
                    closeModel={(isReload) => onModalCloseHandler(state, isReload)}
                    isProfessional={state.isProfessional}
                    candidate={candidate}
                />
            ) : null}

            <section className="px-8 py-4 bg-[#E9F0F7] rounded-md">
                <div className="flex w-full py-4 justify-between border-b-2 border-b-[#979797]">
                    <span className="text-xl font-bold">Informazioni Personali</span>
                    {!candidate?.isArchive && (
                        <FontAwesomeIcon
                            onClick={() => onEditBtnClickHandler(state, false)}
                            className="text-primary-500 cursor-pointer mr-2"
                            icon={faPencil}
                        />
                    )}
                </div>

                <div className="py-4 space-y-3">
                    <dl className="flex space-x-2">
                        <dt className="font-bold">Data di nascita : </dt>
                        <dd>
                            {candidate?.generalInfo?.dob
                                ? reformatDate(candidate?.generalInfo?.dob)
                                : ''}
                        </dd>
                    </dl>
                    <dl className="flex space-x-2">
                        <dt className="font-bold">Coniugato : </dt>
                        <dd>{candidate?.generalInfo?.conjugated}</dd>
                    </dl>
                    <dl className="flex space-x-2">
                        <dt className="font-bold">Attività coniuge : </dt>
                        <dd>{candidate?.generalInfo?.spouseActivity}</dd>
                    </dl>
                    <dl className="flex space-x-2">
                        <dt className="font-bold">N. figli : </dt>
                        <dd>{candidate?.generalInfo?.numberOfChild}</dd>
                    </dl>
                    <dl className="flex space-x-2">
                        <dt className="font-bold">Titolo di studio : </dt>
                        <dd>{candidate?.generalInfo?.qualification}</dd>
                    </dl>
                    <dl className="flex space-x-2">
                        <dt className="font-bold">Note personali :</dt>
                        <dd>{candidate?.generalInfo?.personalNote}</dd>
                    </dl>
                </div>
            </section>

            <section className="px-8 py-4 bg-[#E9F0F7] rounded-md">
                <div className="flex w-full py-4 justify-between border-b-2 border-b-[#979797]">
                    <span className="text-xl font-bold">Informazioni Professionali</span>
                    {!candidate?.isArchive && (
                        <FontAwesomeIcon
                            onClick={() => onEditBtnClickHandler(state, true)}
                            className="text-primary-500 cursor-pointer mr-2"
                            icon={faPencil}
                        />
                    )}
                </div>

                <div className="py-4 space-y-3">
                    <dl className="flex space-x-2">
                        <dt className="font-bold">Banca : </dt>
                        <dd>{candidate?.generalInfo?.bank?.name}</dd>
                    </dl>
                    <dl className="flex space-x-2">
                        <dt className="font-bold">Comune filiale : </dt>
                        <dd>{candidate?.generalInfo?.commonBranch?.name}</dd>
                    </dl>
                    <dl className="flex space-x-2">
                        <dt className="font-bold">Indirizzo filiale : </dt>
                        <dd>{candidate?.generalInfo?.branchAddress}</dd>
                    </dl>
                    <dl className="flex space-x-2">
                        <dt className="font-bold">Da quanti anni in banca : </dt>
                        <dd>{candidate?.generalInfo?.yearsWorkedInBank}</dd>
                    </dl>
                    <dl className="flex space-x-2">
                        <dt className="font-bold">Da quanti anni nell’ultima filiale : </dt>
                        <dd>{candidate?.generalInfo?.yearsWorkedInLastBranch}</dd>
                    </dl>
                    <dl className="flex space-x-2">
                        <dt className="font-bold">Ruolo professionale : </dt>
                        <dd>{candidate?.generalInfo?.professionalRole?.name}</dd>
                    </dl>
                    <dl className="flex space-x-2">
                        <dt className="font-bold">Inquadramento : </dt>
                        <dd>{candidate?.generalInfo?.classification}</dd>
                    </dl>
                    <dl className="flex space-x-2">
                        <dt className="font-bold">Hobby : </dt>
                        <dd>{candidate?.generalInfo?.hobby}</dd>
                    </dl>
                    <dl className="flex space-x-2">
                        <dt className="font-bold">Ultima RAL : </dt>
                        <dd>{candidate?.generalInfo?.lastCu}</dd>
                    </dl>
                    <dl className="flex space-x-2">
                        <dt className="font-bold">Iscrizione Albo OCF : </dt>
                        <dd>{candidate?.generalInfo?.ocfRegister}</dd>
                    </dl>
                    <dl className="flex space-x-2">
                        <dt className="font-bold">Patto di stabilità : </dt>
                        <dd>{candidate?.generalInfo?.stabilityPact}</dd>
                    </dl>
                    <dl className="flex space-x-2">
                        <dt className="font-bold">Patto di non concorrenza : </dt>
                        <dd>{candidate?.generalInfo?.nonCompetitionAgreement}</dd>
                    </dl>
                    <dl className="flex space-x-2">
                        <dt className="font-bold">Portafoglio clienti : </dt>
                        <dd>{candidate?.generalInfo?.customerPortfolio}</dd>
                    </dl>
                    <dl className="flex space-x-2">
                        <dt className="font-bold">Note professionali : </dt>
                        <dd>{candidate?.generalInfo?.professionalNote}</dd>
                    </dl>
                </div>
            </section>
        </aside>
    );
}

export default CandidateInformation;
