import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';

import Storage from './helper/services/common/storage-helper-services';
import ForgotPassword from './pages/ForgotPassword';
import Login from './pages/Login';
import Activities from './pages/dashboard/Activities';
import Candidate from './pages/dashboard/Candidate';
import Candidates from './pages/dashboard/Candidates';
import Dashboard from './pages/dashboard/Dashboard';
import Database from './pages/dashboard/Database';
import Manager from './pages/dashboard/Manager';
import Managers from './pages/dashboard/Managers';
import SharedManager from './pages/dashboard/SharedManager';
import UserProfile from './pages/dashboard/UserProfile';
import { ConfigContextProvider } from './store/ConfigContext';
import UpdatePassword from './pages/UpdatePassword';
import Secretary from './pages/dashboard/Secretary';
import Secretaries from './pages/dashboard/Secretaries';

/**
 * This component is responsible for authentication
 */
function RequireAuth() {
    // Check for token. If token not present redirect to login
    const token = Storage.get('auth-token');
    if (!token) {
        window.location.href = '/login';
        return null;
    }

    // If token Return Dashboard
    return (
        <Dashboard>
            <Outlet />
        </Dashboard>
    );
}

console.log('Environment : ', process.env.NODE_ENV);

function App() {
    return (
        <div className="app-container relative">
            <ConfigContextProvider>
                <BrowserRouter>
                    <Routes>
                        <Route exact path="login" element={<Login />} />
                        <Route exact path="forgot-password" element={<ForgotPassword />} />
                        <Route exact path="update-password/:id" element={<UpdatePassword />} />
                        <Route exact path="dashboard" element={<RequireAuth />}>
                            <Route
                                exact
                                path=""
                                element={<Navigate replace to="/dashboard/candidates" />}
                            />
                            <Route exact path="activities" element={<Activities />} />
                            <Route exact path="candidates" element={<Candidates />} />
                            <Route exact path="candidate/:id" element={<Candidate />} />
                            <Route exact path="candidate/:id/shared" element={<SharedManager />} />
                            <Route exact path="managers" element={<Managers />} />
                            <Route exact path="manager/:id" element={<Manager />} />
                            <Route exact path="secretaries" element={<Secretaries />} />
                            <Route exact path="secretary/:id" element={<Secretary />} />
                            <Route exact path="profile/:id" element={<UserProfile />} />
                            <Route exact path="database" element={<Database />} />
                            <Route exact path="*" element={<Navigate replace to="/login" />} />
                        </Route>
                        <Route exact path="*" element={<Navigate replace to="/login" />} />
                    </Routes>
                </BrowserRouter>
            </ConfigContextProvider>

            {/**
             * Toaster Component
             */}
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                toastClassName="black-background"
                theme="dark"
            />
        </div>
    );
}

export default App;
