/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
/* eslint-disable import/prefer-default-export */
import React, { useEffect, useState } from 'react';
import { TreeTable, TreeState } from 'cp-react-tree-table';

function TreeDataGride({ columns = [], rows = [] }) {
    const [treeValue, setTreeValue] = useState([]);

    const handleOnChange = (newValue) => {
        setTreeValue(newValue);
    };

    useEffect(() => {
        handleOnChange(TreeState.create(rows));
    }, [rows]);

    return (
        <TreeTable value={treeValue} onChange={handleOnChange} height="816">
            {columns.map((item) => (
                <TreeTable.Column
                    renderCell={item.cell}
                    renderHeaderCell={item.header}
                    basis={item?.basis ? item?.basis : 'auto'}
                    grow={item?.basis ? item?.grow : '0'}
                />
            ))}
        </TreeTable>
    );
}

export default TreeDataGride;
