/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
import { faCirclePlus, faPencil, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DataTable from '../../component/DataTable';
import Loader from '../../component/Loader';
import AppHeader from '../../component/header/AppHeader';
import HeaderItem from '../../component/header/HeaderItem';
import ConfirmationModal from '../../component/modal/ConfirmationModal';
import CreateDatabaseValue from '../../component/modal/CreateDatabaseValue';
import { MESSAGES } from '../../constants';
import { deleteTableValue, getTableData } from '../../helper/services/apis/database-services';
import { checkPagePermission } from '../../helper/utility';

function Database() {
    const DATABASE_TABLE_COLUMNS = [
        {
            className: '',
            table: 'bank',
            template: (
                <span className="flex w-full justify-between">
                    <span>Banca</span>
                    <FontAwesomeIcon
                        onClick={() => addNewItem(state, 'bank', 'Banca')}
                        className="text-lg text-primary-500 cursor-pointer"
                        icon={faCirclePlus}
                    />
                </span>
            ),
            isSortable: false,
            sortStatus: null
        },
        {
            className: '',
            table: 'branch',
            template: (
                <span className="flex w-full justify-between">
                    <span>Comune filiale</span>
                    <FontAwesomeIcon
                        onClick={() => addNewItem(state, 'branch', 'Comune filiale')}
                        className="text-lg text-primary-500 cursor-pointer"
                        icon={faCirclePlus}
                    />
                </span>
            ),
            isSortable: false,
            sortStatus: null
        },
        {
            className: '',
            table: 'role',
            template: (
                <span className="flex w-full justify-between">
                    <span>Ruolo professionale</span>
                    <FontAwesomeIcon
                        onClick={() => addNewItem(state, 'prof-role', 'Ruolo professionale')}
                        className="text-lg text-primary-500 cursor-pointer"
                        icon={faCirclePlus}
                    />
                </span>
            ),
            isSortable: false,
            sortStatus: null
        },
        {
            className: '',
            table: 'origin',
            template: (
                <span className="flex w-full justify-between">
                    <span>Canale</span>
                    <FontAwesomeIcon
                        onClick={() => addNewItem(state, 'origin', 'Provenienza')}
                        className="text-lg text-primary-500 cursor-pointer"
                        icon={faCirclePlus}
                    />
                </span>
            ),
            isSortable: false,
            sortStatus: null
        }
        // {
        //     className: '',
        //     table: 'place',
        //     template: (
        //         <span className="flex w-full justify-between">
        //             <span>Luogo</span>
        //             <FontAwesomeIcon
        //                 onClick={() => addNewItem(state, 'place', 'Luogo')}
        //                 className="text-lg text-primary-500 cursor-pointer"
        //                 icon={faCirclePlus}
        //             />
        //         </span>
        //     ),
        //     isSortable: false,
        //     sortStatus: null
        // },
        // {
        //     className: '',
        //     table: 'mode',
        //     template: (
        //         <span className="flex w-full justify-between">
        //             <span>Modalità</span>
        //             <FontAwesomeIcon
        //                 onClick={() => addNewItem(state, 'mode', 'Modalità')}
        //                 className="text-lg text-primary-500 cursor-pointer"
        //                 icon={faCirclePlus}
        //             />
        //         </span>
        //     ),
        //     isSortable: false,
        //     sortStatus: null
        // },
        // {
        //     className: '',
        //     table: 'action',
        //     template: (
        //         <span className="flex w-full justify-between">
        //             <span>Tipo di azione</span>
        //             <FontAwesomeIcon
        //                 onClick={() => addNewItem(state, 'action', 'Tipo di azione')}
        //                 className="text-lg text-primary-500 cursor-pointer"
        //                 icon={faCirclePlus}
        //             />
        //         </span>
        //     ),
        //     isSortable: false,
        //     sortStatus: null
        // }
    ];
    const navigate = useNavigate();
    const [banks, setBanks] = useState([]);
    const [branches, setBranches] = useState([]);
    const [roles, setRoles] = useState([]);
    const [provenances, setProvenances] = useState([]);
    const [places, setPlaces] = useState([]);
    const [modes, setModes] = useState([]);
    const [actions, setActions] = useState([]);
    const [rows, setRows] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);

    /**
     * Component State
     */
    const [state, setState] = useState({
        isLoading: false,
        isAddDatabaseValueModalOpen: false,
        isRemoveDatabaseValueConfirmModalOpen: false
    });

    /**
     * Business Logic
     */
    const retrieveBankData = async () => {
        let result = [];

        result = await getTableData('banks').catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
        });

        return result.data.map((item) => ({ type: 'bank', title: 'Banca', ...item }));
    };

    const retrieveBranchData = async () => {
        let result = [];

        result = await getTableData('branches').catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
        });

        return result.data.map((item) => ({ type: 'branch', title: 'Comune filiale', ...item }));
    };

    const retrieveRoleData = async () => {
        let result = [];

        result = await getTableData('prof-roles').catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
        });

        return result.data.map((item) => ({
            type: 'prof-role',
            title: 'Ruolo professionale',
            ...item
        }));
    };

    const retrieveOriginData = async () => {
        let result = [];

        result = await getTableData('origins').catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
        });

        return result.data.map((item) => ({ type: 'origin', title: 'Provenienza', ...item }));
    };

    const retrievePlaceData = async () => {
        let result = [];

        result = await getTableData('places').catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
        });

        return result.data.map((item) => ({ type: 'place', title: 'Luogo', ...item }));
    };

    const retrieveModeData = async () => {
        let result = [];

        result = await getTableData('modes').catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
        });

        return result.data.map((item) => ({ type: 'mode', title: 'Modalità', ...item }));
    };

    const retrieveActionData = async () => {
        let result = [];

        result = await getTableData('actions').catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
        });

        return result.data.map((item) => ({ type: 'action', title: 'Tipo di azione', ...item }));
    };

    const retrieveData = async (currentState, type = null) => {
        const newState = { ...currentState };
        newState.isLoading = true;
        setState({ ...newState });
        if (type === 'bank') {
            const bank = await retrieveBankData();
            setBanks([...bank]);
        } else if (type === 'branch') {
            const branch = await retrieveBranchData();
            setBranches([...branch]);
        } else if (type === 'prof-role') {
            const role = await retrieveRoleData();
            setRoles([...role]);
        } else if (type === 'origin') {
            const origin = await retrieveOriginData();
            setProvenances([...origin]);
        } else if (type === 'place') {
            // const place = await retrievePlaceData();
            // setPlaces([...place]);
        } else if (type === 'mode') {
            // const mode = await retrieveModeData();
            // setModes([...mode]);
        } else if (type === 'action') {
            // const action = await retrieveActionData();
            // setActions([...action]);
        } else {
            const bank = await retrieveBankData();
            const branch = await retrieveBranchData();
            const role = await retrieveRoleData();
            const origin = await retrieveOriginData();
            // const place = await retrievePlaceData();
            // const mode = await retrieveModeData();
            // const action = await retrieveActionData();
            setBanks([...bank]);
            setBranches([...branch]);
            setRoles([...role]);
            setProvenances([...origin]);
            // setPlaces([...place]);
            // setModes([...mode]);
            // setActions([...action]);
        }
        newState.isLoading = false;
        setState({ ...newState });
    };

    const createRows = (
        dataTable1,
        dataTable2,
        dataTable3,
        dataTable4,
        dataTable5,
        dataTable6,
        dataTable7,
        rowCount
    ) => {
        const data = [];
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < rowCount; index++) {
            const item = {
                bank: dataTable1[index] ? { ...dataTable1[index] } : null,
                branch: dataTable2[index] ? { ...dataTable2[index] } : null,
                role: dataTable3[index] ? { ...dataTable3[index] } : null,
                provenance: dataTable4[index] ? { ...dataTable4[index] } : null,
                place: dataTable5[index] ? { ...dataTable5[index] } : null,
                mode: dataTable6[index] ? { ...dataTable6[index] } : null,
                action: dataTable7[index] ? { ...dataTable7[index] } : null
            };

            data.push({ ...item });
        }

        setRows([...data]);
    };

    const addNewItem = (currentState, dbName, title) => {
        const newState = { ...currentState };
        newState.isAddDatabaseValueModalOpen = true;
        setState({ ...newState });
        setSelectedValue({ dbName, title });
    };

    const editItem = (currentState, dbName, title, name, id) => {
        const newState = { ...currentState };
        newState.isAddDatabaseValueModalOpen = true;
        setState({ ...newState });
        setSelectedValue({ dbName, title, name, id });
    };

    const deleteItem = (currentState, dbName, title, name, id) => {
        const newState = { ...currentState };
        newState.isRemoveDatabaseValueConfirmModalOpen = true;
        setState({ ...newState });
        setSelectedValue({ dbName, title, name, id });
    };

    const getRows = (data) => {
        console.log(data);
        return data.map((item) => ({
            className: 'bg-transparent',
            cells: [
                {
                    template: (
                        <span className="flex w-full justify-between">
                            {item.bank ? (
                                <>
                                    <span className="mr-2 truncate" title={item.bank.name}>
                                        {item.bank.name}
                                    </span>
                                    <span>
                                        <FontAwesomeIcon
                                            onClick={() =>
                                                editItem(
                                                    state,
                                                    item.bank.type,
                                                    item.bank.title,
                                                    item.bank.name,
                                                    item.bank.id
                                                )
                                            }
                                            className="text-primary-500 cursor-pointer mr-2"
                                            icon={faPencil}
                                        />
                                        <FontAwesomeIcon
                                            onClick={() =>
                                                deleteItem(
                                                    state,
                                                    item.bank.type,
                                                    item.bank.title,
                                                    item.bank.name,
                                                    item.bank.id
                                                )
                                            }
                                            className="text-red-500 cursor-pointer"
                                            icon={faTrashCan}
                                        />
                                    </span>
                                </>
                            ) : (
                                ''
                            )}
                        </span>
                    ),
                    className: `min-w-[176px] ${item.bank ? 'bg-white' : ''}`
                },
                {
                    template: (
                        <span className="flex w-full justify-between">
                            {item.branch ? (
                                <>
                                    <span className="mr-2 truncate" title={item.branch.name}>
                                        {item.branch.name}
                                    </span>
                                    <span>
                                        <FontAwesomeIcon
                                            onClick={() =>
                                                editItem(
                                                    state,
                                                    item.branch.type,
                                                    item.branch.title,
                                                    item.branch.name,
                                                    item.branch.id
                                                )
                                            }
                                            className="text-primary-500 cursor-pointer mr-2"
                                            icon={faPencil}
                                        />
                                        <FontAwesomeIcon
                                            onClick={() =>
                                                deleteItem(
                                                    state,
                                                    item.branch.type,
                                                    item.branch.title,
                                                    item.branch.name,
                                                    item.branch.id
                                                )
                                            }
                                            className="text-red-500 cursor-pointer"
                                            icon={faTrashCan}
                                        />
                                    </span>
                                </>
                            ) : (
                                ''
                            )}
                        </span>
                    ),
                    className: `min-w-[176px] ${item.branch ? 'bg-white' : ''}`
                },
                {
                    template: (
                        <span className="flex w-full justify-between">
                            {item.role ? (
                                <>
                                    <span className="mr-2 truncate" title={item.role.name}>
                                        {item.role.name}
                                    </span>
                                    <span>
                                        <FontAwesomeIcon
                                            onClick={() =>
                                                editItem(
                                                    state,
                                                    item.role.type,
                                                    item.role.title,
                                                    item.role.name,
                                                    item.role.id
                                                )
                                            }
                                            className="text-primary-500 cursor-pointer mr-2"
                                            icon={faPencil}
                                        />
                                        <FontAwesomeIcon
                                            onClick={() =>
                                                deleteItem(
                                                    state,
                                                    item.role.type,
                                                    item.role.title,
                                                    item.role.name,
                                                    item.role.id
                                                )
                                            }
                                            className="text-red-500 cursor-pointer"
                                            icon={faTrashCan}
                                        />
                                    </span>
                                </>
                            ) : (
                                ''
                            )}
                        </span>
                    ),
                    className: `min-w-[176px] ${item.role ? 'bg-white' : ''}`
                },
                {
                    template: (
                        <span className="flex w-full justify-between">
                            {item.provenance ? (
                                <>
                                    <span className="mr-2 truncate" title={item.provenance.name}>
                                        {item.provenance.name}
                                    </span>
                                    <span>
                                        <FontAwesomeIcon
                                            onClick={() =>
                                                editItem(
                                                    state,
                                                    item.provenance.type,
                                                    item.provenance.title,
                                                    item.provenance.name,
                                                    item.provenance.id
                                                )
                                            }
                                            className="text-primary-500 cursor-pointer mr-2"
                                            icon={faPencil}
                                        />
                                        <FontAwesomeIcon
                                            onClick={() =>
                                                deleteItem(
                                                    state,
                                                    item.provenance.type,
                                                    item.provenance.title,
                                                    item.provenance.name,
                                                    item.provenance.id
                                                )
                                            }
                                            className="text-red-500 cursor-pointer"
                                            icon={faTrashCan}
                                        />
                                    </span>
                                </>
                            ) : (
                                ''
                            )}
                        </span>
                    ),
                    className: `min-w-[176px] ${item.provenance ? 'bg-white' : ''}`
                }
                // {
                //     template: (
                //         <span className="flex w-full justify-between">
                //             {item.place ? (
                //                 <>
                //                     <span className="mr-2 truncate" title={item.place.name}>
                //                         {item.place.name}
                //                     </span>
                //                     <span>
                //                         <FontAwesomeIcon
                //                             onClick={() =>
                //                                 editItem(
                //                                     state,
                //                                     item.place.type,
                //                                     item.place.title,
                //                                     item.place.name,
                //                                     item.place.id
                //                                 )
                //                             }
                //                             className="text-primary-500 cursor-pointer mr-2"
                //                             icon={faPencil}
                //                         />
                //                         <FontAwesomeIcon
                //                             onClick={() =>
                //                                 deleteItem(
                //                                     state,
                //                                     item.place.type,
                //                                     item.place.title,
                //                                     item.place.name,
                //                                     item.place.id
                //                                 )
                //                             }
                //                             className="text-red-500 cursor-pointer"
                //                             icon={faTrashCan}
                //                         />
                //                     </span>
                //                 </>
                //             ) : (
                //                 ''
                //             )}
                //         </span>
                //     ),
                //     className: `min-w-[176px] ${item.place ? 'bg-white' : ''}`
                // },
                // {
                //     template: (
                //         <span className="flex w-full justify-between">
                //             {item.mode ? (
                //                 <>
                //                     <span className="mr-2 truncate" title={item.mode.name}>
                //                         {item.mode.name}
                //                     </span>
                //                     <span>
                //                         <FontAwesomeIcon
                //                             onClick={() =>
                //                                 editItem(
                //                                     state,
                //                                     item.mode.type,
                //                                     item.mode.title,
                //                                     item.mode.name,
                //                                     item.mode.id
                //                                 )
                //                             }
                //                             className="text-primary-500 cursor-pointer mr-2"
                //                             icon={faPencil}
                //                         />
                //                         <FontAwesomeIcon
                //                             onClick={() =>
                //                                 deleteItem(
                //                                     state,
                //                                     item.mode.type,
                //                                     item.mode.title,
                //                                     item.mode.name,
                //                                     item.mode.id
                //                                 )
                //                             }
                //                             className="text-red-500 cursor-pointer"
                //                             icon={faTrashCan}
                //                         />
                //                     </span>
                //                 </>
                //             ) : (
                //                 ''
                //             )}
                //         </span>
                //     ),
                //     className: `min-w-[176px] ${item.mode ? 'bg-white' : ''}`
                // },
                // {
                //     template: (
                //         <span className="flex w-full justify-between">
                //             {item.action ? (
                //                 <>
                //                     <span className="mr-2 truncate" title={item.action.name}>
                //                         {item.action.name}
                //                     </span>
                //                     <span>
                //                         <FontAwesomeIcon
                //                             onClick={() =>
                //                                 editItem(
                //                                     state,
                //                                     item.action.type,
                //                                     item.action.title,
                //                                     item.action.name,
                //                                     item.action.id
                //                                 )
                //                             }
                //                             className="text-primary-500 cursor-pointer mr-2"
                //                             icon={faPencil}
                //                         />
                //                         <FontAwesomeIcon
                //                             onClick={() =>
                //                                 deleteItem(
                //                                     state,
                //                                     item.action.type,
                //                                     item.action.title,
                //                                     item.action.name,
                //                                     item.action.id
                //                                 )
                //                             }
                //                             className="text-red-500 cursor-pointer"
                //                             icon={faTrashCan}
                //                         />
                //                     </span>
                //                 </>
                //             ) : (
                //                 ''
                //             )}
                //         </span>
                //     ),
                //     className: `min-w-[176px] ${item.action ? 'bg-white' : ''}`
                // }
            ]
        }));
    };

    const onCloseCreateValueModal = (currentState, reload = false, dbName = null) => {
        const newState = { ...currentState };
        newState.isAddDatabaseValueModalOpen = false;
        setState({ ...newState });
        setSelectedValue(null);
        if (reload) {
            retrieveData(newState, dbName);
        }
    };

    const onCloseDeleteValueConfirmModal = (currentState) => {
        const newState = { ...currentState };
        newState.isRemoveDatabaseValueConfirmModalOpen = false;
        setState({ ...newState });
        setSelectedValue(null);
    };

    const deleteValue = async (dbName, id) => {
        const result = await deleteTableValue(dbName, id).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
            return false;
        });
        if (result?.ok) {
            toast.success(MESSAGES.SUCCESS);
            return true;
        }
    };

    const removeValueConfirmClickHandler = async (currentState, currentValue) => {
        const result = await deleteValue(currentValue.dbName, currentValue.id);
        if (result) {
            const newState = { ...currentState };
            setState({ ...newState });
            newState.isRemoveDatabaseValueConfirmModalOpen = false;
            setSelectedValue(null);
            retrieveData(newState, currentValue.dbName);
        }
    };

    /**
     * Watchers
     */
    useEffect(() => {
        const rowCount = Math.max(
            banks.length,
            branches.length,
            roles.length,
            provenances.length,
            places.length,
            modes.length,
            actions.length
        );

        createRows(banks, branches, roles, provenances, places, modes, actions, rowCount);
    }, [banks, branches, roles, provenances, places, modes, actions]);

    useEffect(() => {
        checkPagePermission(navigate);
        retrieveData(state);
    }, []);

    /**
     * Template
     */
    return (
        <section>
            {state.isLoading ? <Loader /> : null}

            {state.isAddDatabaseValueModalOpen ? (
                <CreateDatabaseValue
                    isOpen={state.isAddDatabaseValueModalOpen}
                    closeModel={(reload, dbName) => onCloseCreateValueModal(state, reload, dbName)}
                    selectedValue={selectedValue}
                />
            ) : null}

            {state.isRemoveDatabaseValueConfirmModalOpen ? (
                <ConfirmationModal
                    isOpen={state.isRemoveDatabaseValueConfirmModalOpen}
                    title="Elimina"
                    message={`Sei sicuro di voler eliminare il valore “${selectedValue?.name}” ?
                    Completando l’azione non sarà più visibile all’interno
                    del sistema.`}
                    onConfirmAction={() => removeValueConfirmClickHandler(state, selectedValue)}
                    closeModel={() => onCloseDeleteValueConfirmModal(false)}
                    confirmBtnText="ELIMINA"
                />
            ) : null}

            <AppHeader>
                <HeaderItem>
                    <span className="cursor-pointer font-bold text-2xl text-grey-900">
                        Database
                    </span>
                </HeaderItem>
            </AppHeader>

            <DataTable
                columns={DATABASE_TABLE_COLUMNS}
                rows={getRows(rows)}
                className="border-spacing-x-1"
            />
        </section>
    );
}

export default Database;
