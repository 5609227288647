/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { MESSAGES } from '../../constants';
import {
    createNewTableValue,
    updateNewTableValue
} from '../../helper/services/apis/database-services';
import Loader from '../Loader';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import ModalComponent from './ModalComponent';

function CreateDatabaseValue({ isOpen, closeModel, selectedValue = null }) {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm();
    const [isLoading, setIsLoading] = useState(false);

    const onSubmitHandler = async (formData) => {
        setIsLoading(true);
        if (selectedValue.id) {
            const result = await updateNewTableValue(selectedValue.dbName, selectedValue.id, {
                name: formData.name
            }).catch((e) => {
                console.log(e);
                toast.error(MESSAGES.SERVER_ERROR);
                setIsLoading(false);
            });
            if (result?.ok) {
                toast.success(MESSAGES.SUCCESS);
                setIsLoading(false);
                closeModel(true, selectedValue.dbName);
            }
        } else {
            const result = await createNewTableValue(selectedValue.dbName, {
                name: formData.name
            }).catch((e) => {
                console.log(e);
                toast.error(MESSAGES.SERVER_ERROR);
                setIsLoading(false);
            });
            if (result?.ok) {
                toast.success(MESSAGES.SUCCESS);
                setIsLoading(false);
                closeModel(true, selectedValue.dbName);
            }
        }
    };

    const setValueFormData = (data) => {
        if (data) {
            reset({ ...data });
        } else {
            reset({ name: '' });
        }
    };

    useEffect(() => {
        if (selectedValue) {
            setValueFormData(selectedValue);
        } else {
            setValueFormData(null);
        }
    }, [selectedValue]);

    return (
        <ModalComponent
            isOpen={isOpen}
            closeModel={() => closeModel(false)}
            title={
                selectedValue?.id
                    ? `Modifica ${selectedValue?.title}`
                    : `Aggiungi ${selectedValue?.title}`
            }
            size="max-w-[632px]">
            {isLoading ? <Loader /> : null}
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                {/* Modal Body */}
                <div className="p-6 space-y-6">
                    <div className="flex flex-col w-full space-y-4">
                        <div
                            className={`w-full flex items-center space-x-2 input-field-group ${
                                errors?.name ? 'invalid-field' : ''
                            }`}>
                            <div className="w-1/3">
                                <label htmlFor="email">Valore* : </label>
                            </div>
                            <div className="w-2/3">
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder=""
                                    {...register('name', {
                                        required: true
                                    })}
                                />
                                {errors?.name?.type === 'required' && (
                                    <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal Footer */}
                <div className="flex justify-center p-6 space-x-4 rounded-b bg-white">
                    <SecondaryButton onClick={() => closeModel(false)}>ANNULLA</SecondaryButton>
                    <PrimaryButton type="submit">
                        {selectedValue?.id ? 'MODIFICA' : 'AGGIUNGI'}
                    </PrimaryButton>
                </div>
            </form>
        </ModalComponent>
    );
}

export default CreateDatabaseValue;
