import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

export const API_ENDPOINT = {
    LOGIN: 'auth/login',
    ROLE: 'role',
    USER: 'user',
    MANAGER: 'manager',
    CANDIDATE: 'candidate',
    ACTIVITY: 'activity',
    ACTIVITIES: 'activities',
    SECRETARY: 'secretary',
    SECRETARIES: 'secretaries'
};

export const MESSAGES = {
    INVALID_CREDENTIAL: 'Le credenziali inserite non risultano corrette. Controlla e riprova.',
    SUCCESS: 'Completato con successo',
    SERVER_ERROR: 'Errore del server',
    FIELD_REQUIRED: 'Il campo è obbligatorio',
    maxLimitMessage: (limit = '255') => `Questo campo non può superare i ${limit} caratteri`,
    INVALID_EMAIL: `L' email non è valida`,
    INVALID_PHONE_NUMBER: `Il numero di telefono non è valido`
};

export const CANDIDATE_STATUS = {
    'Mai incontrato': { color: '#DDE6FF', text: '#488DD2' }, // Never meet
    Iter: { color: '#D1F2EB', text: '#5EA87E' }, // In Iter
    'Da richiamare': { color: '#FFEBCD', text: '#FEBE56' }, // Not Interested
    'Non idoneo': { color: '#000000', text: '#FFFFFF' }, // Not Qualify
    Inserito: { color: '#D1F2EB', text: '#5EA87E' } // Placed
};

export const ACTIVITY_STATUS_LIST = [
    { label: 'Chiamata', value: 'call' },
    { label: 'Richiamare', value: 'recall' },
    { label: 'App. fissato', value: 'meeting' },
    { label: 'Annullato', value: 'canceled' }
];

export const ACTIVITY_STATUS = {
    call: { color: '#D4EADD', text: '#40A46C', label: 'Chiamata' },
    recall: { color: '#FFEBCD', text: '#FEBE56', label: 'Richiamare' },
    meeting: { color: '#D4EADD', text: '#40A46C', label: 'App. fissato' },
    canceled: { color: '#FFD2D2', text: '#F06F70', label: 'Annullato' }
};
/**
 * REGEX
 */
export const PHONE_REGEX =
    /^(\((00|\+)39\)|(00|\+)39)?(38[890]|34[7-90]|36[680]|33[3-90]|32[89])\d{7}$/gm;

export const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}/g;
export const NUMBER_REGEX = /[^\d]+/gim;
export const TWO_DECIMAL_NUMBER_REGEX = /[^\d]+/gim;

/**
 * Tables
 */
export const CANDIDATES_TABLE_COLUMNS = [
    { className: '', template: 'Manager', isSortable: false, sortStatus: null },
    { className: '', template: 'MNG Crescita', isSortable: false, sortStatus: null },
    { className: '', template: 'Candidato', isSortable: false, sortStatus: null },
    { className: '', template: 'Albo OCF', isSortable: false, sortStatus: null },
    { className: '', template: 'Banca', isSortable: false, sortStatus: null },
    { className: '', template: 'Comune filiale', isSortable: false, sortStatus: null },
    { className: '', template: 'Mail', isSortable: false, sortStatus: null },
    { className: '', template: 'Cellulare', isSortable: false, sortStatus: null },
    { className: '', template: 'Fase', isSortable: false, sortStatus: null },
    { className: '', template: 'Azioni da fare', isSortable: false, sortStatus: null },
    { className: '', template: 'Giorni in step', isSortable: false, sortStatus: null },
    { className: '', template: 'Giorni in iter', isSortable: false, sortStatus: null },
    { className: '', template: 'Stato', isSortable: false, sortStatus: null },
    { className: '', template: '', isSortable: false, sortStatus: null }
];

export const ACTIVITIES_TABLE_COLUMNS = [
    { className: '', template: 'Candidato', isSortable: false, sortStatus: null },
    { className: '', template: 'Inserita da', isSortable: false, sortStatus: null },
    { className: '', template: 'Quando', isSortable: false, sortStatus: null },
    { className: '', template: 'Completata il', isSortable: false, sortStatus: null },
    { className: '', template: 'Fase', isSortable: false, sortStatus: null },
    { className: '', template: 'Chi svolge l’attività', isSortable: false, sortStatus: null },
    { className: '', template: 'Dove', isSortable: false, sortStatus: null },
    { className: '', template: 'Con chi', isSortable: false, sortStatus: null },
    { className: '', template: 'Esito', isSortable: false, sortStatus: null },
    { className: '', template: 'Rating', isSortable: false, sortStatus: null },
    { className: '', template: 'Stato attività', isSortable: false, sortStatus: null },
    { className: '', template: '', isSortable: false, sortStatus: null }
];

export const CANDIDATE_ACTIVITY_COLUMNS = [
    // { className: '', template: 'Data e ora', isSortable: false, sortStatus: null },
    { className: '', template: 'Inserita da', isSortable: false, sortStatus: null },
    { className: '', template: 'Quando', isSortable: false, sortStatus: null },
    { className: '', template: 'Completata il', isSortable: false, sortStatus: null },
    { className: '', template: 'Fase', isSortable: false, sortStatus: null },
    { className: '', template: 'Chi svolge l’attività', isSortable: false, sortStatus: null },
    { className: '', template: 'Dove', isSortable: false, sortStatus: null },
    { className: '', template: 'Con chi', isSortable: false, sortStatus: null },
    { className: '', template: 'Esito', isSortable: false, sortStatus: null },
    { className: '', template: 'Rating', isSortable: false, sortStatus: null },
    { className: '', template: 'Stato attività', isSortable: false, sortStatus: null },
    { className: '', template: '', isSortable: false, sortStatus: null }
];

export const CANDIDATE_ATTACHMENT_COLUMNS = [
    { className: '', template: 'Nome file', isSortable: false, sortStatus: null },
    { className: '', template: 'Descrizione', isSortable: false, sortStatus: null },
    { className: '', template: 'Data', isSortable: false, sortStatus: null },
    { className: '', template: '', isSortable: false, sortStatus: null }
];

export const USERS_TABLE_COLUMNS = [
    { className: '', template: 'Manager', isSortable: false, sortStatus: null },
    { className: '', template: 'Livello', isSortable: false, sortStatus: null },
    { className: '', template: 'MNG Crescita', isSortable: false, sortStatus: null },
    { className: '', template: 'Data creazione', isSortable: false, sortStatus: null },
    { className: '', template: 'Segretarie', isSortable: false, sortStatus: null },
    { className: '', template: 'Mail', isSortable: false, sortStatus: null },
    { className: '', template: 'Assegnato a', isSortable: false, sortStatus: null },
    { className: '', template: 'Attivo', isSortable: false, sortStatus: null },
    { className: '', template: '', isSortable: false, sortStatus: null }
];

export const SECRETARY_TABLE_COLUMNS = [
    { className: '', template: 'Segretarie', isSortable: false, sortStatus: null },
    { className: '', template: 'Supervisori assegnati', isSortable: false, sortStatus: null },
    { className: '', template: 'Database', isSortable: false, sortStatus: null },
    { className: '', template: 'Mail', isSortable: false, sortStatus: null },
    { className: '', template: 'Chiamate cand. in iter', isSortable: false, sortStatus: null },
    { className: '', template: 'Chiamate cand. nuovi', isSortable: false, sortStatus: null },
    { className: '', template: 'Totale Chiamate candidati', isSortable: false, sortStatus: null },
    { className: '', template: 'Appuntam. cand. in iter', isSortable: false, sortStatus: null },
    { className: '', template: 'Appuntam. cand. nuovi', isSortable: false, sortStatus: null },
    { className: '', template: 'Totale Appuntam. candidati', isSortable: false, sortStatus: null },
    { className: '', template: 'Attivo', isSortable: false, sortStatus: null },
    { className: '', template: '', isSortable: false, sortStatus: null }
];

export const USERS_REVENUE_TABLE_COLUMNS = [
    { className: '', template: 'Manager', isSortable: false, sortStatus: null },
    { className: '', template: 'Database', isSortable: false, sortStatus: null },
    { className: '', template: 'Conoscitivo Progressivo', isSortable: false, sortStatus: null },
    { className: '', template: 'Informativo Progressivo', isSortable: false, sortStatus: null },
    { className: '', template: 'Progetto/Master Progressivo', isSortable: false, sortStatus: null },
    {
        className: '',
        template: 'Fase decisionale Progressivo',
        isSortable: false,
        sortStatus: null
    },
    { className: '', template: 'Esame Progressivo', isSortable: false, sortStatus: null },
    // { className: '', template: 'Progetto Progressivo', isSortable: false, sortStatus: null },
    // { className: '', template: 'Contratto Progressivo', isSortable: false, sortStatus: null },
    { className: '', template: 'Codici Progressivo', isSortable: false, sortStatus: null },
    { className: '', template: 'Totale Candidati in Iter', isSortable: false, sortStatus: null },
    { className: '', template: 'Da Richiamare Progressivo', isSortable: false, sortStatus: null },
    { className: '', template: 'Non Idoneo Progressivo', isSortable: false, sortStatus: null },
    { className: '', template: '', isSortable: false, sortStatus: null }
];

export const USERS_REVENUE_TABLE_ONLY_PROG_COLUMNS = [
    { className: '', template: 'Manager', isSortable: false, sortStatus: null },
    // { className: '', template: 'R1 Settimana corrente', isSortable: false, sortStatus: null },
    // { className: '', template: 'R1 Mese corrente', isSortable: false, sortStatus: null },
    { className: '', template: 'R1 progressivi', isSortable: false, sortStatus: null },
    { className: '', template: 'FBD progressivi', isSortable: false, sortStatus: null },
    // { className: '', template: 'Candidati stato fbd', isSortable: false, sortStatus: null },
    { className: '', template: 'Prodotti progressivi', isSortable: false, sortStatus: null },
    // { className: '', template: 'Candidati stato prodotti', isSortable: false, sortStatus: null },
    { className: '', template: 'Ricavi progressivi', isSortable: false, sortStatus: null },
    // { className: '', template: 'Candidati stato ricavi', isSortable: false, sortStatus: null },
    { className: '', template: 'Proposte progressivi', isSortable: false, sortStatus: null },
    // { className: '', template: 'Candidati stato proposte', isSortable: false, sortStatus: null },
    { className: '', template: 'Prova valutativa progressi', isSortable: false, sortStatus: null },
    // { className: '', template: 'Candidati stato prova v.', isSortable: false, sortStatus: null },
    // { className: '', template: 'Codici progress.', isSortable: false, sortStatus: null },
    // { className: '', template: 'In corso acquisiz. codici', isSortable: false, sortStatus: null },
    {
        className: '',
        template: 'Potenziale codici progressivi',
        isSortable: false,
        sortStatus: null
    },
    // {
    //     className: '',
    //     template: 'Totale candidati in iter',
    //     isSortable: false,
    //     sortStatus: null
    // },
    { className: '', template: '', isSortable: false, sortStatus: null }
];

export const USERS_DATABASE_TABLE_COLUMNS = [
    { className: '', template: 'Manager', isSortable: false, sortStatus: null },
    { className: '', template: 'Totale candidati', isSortable: false, sortStatus: null },
    { className: '', template: 'Mai incontrati', isSortable: false, sortStatus: null },
    { className: '', template: 'Iter', isSortable: false, sortStatus: null },
    { className: '', template: 'Da richiamare', isSortable: false, sortStatus: null },
    { className: '', template: 'Non idoneo', isSortable: false, sortStatus: null },
    { className: '', template: '', isSortable: false, sortStatus: null }
];

export const USERS_DATABASE_TABLE_COLUMNS_TREE = [
    {
        header: () => (
            <div className="py-4 px-6">
                <div className="flex items-center">Manager</div>
            </div>
        ),
        cell: (row) => (
            <div className="p-4 my-2">
                <div
                    className="flex items-center"
                    style={{ paddingLeft: `${row.metadata.depth * 4}px` }}>
                    {row.metadata.index === 0 ? (
                        row?.data?.fullName
                    ) : (
                        <Link
                            to={`../manager/${row?.data?.id}`}
                            className="text-primary-800 cursor-pointer"
                            title={`${row?.data?.fullName}`}>
                            {`${row?.data?.fullName}`}
                        </Link>
                    )}
                </div>
            </div>
        )
        // basis: ''
    },
    {
        header: () => (
            <div className="py-4 px-6">
                <div className="flex items-center">Totale candidati</div>
            </div>
        ),
        cell: (row) => (
            <div className="py-4 px-6 my-2">
                <div className="flex items-center">{row?.data?.total}</div>
            </div>
        ),
        className: ''
    },
    {
        header: () => (
            <div className="py-4 px-6">
                <div className="flex items-center">Mai incontrati</div>
            </div>
        ),
        cell: (row) => (
            <div className="py-4 px-6 my-2">
                <div className="flex items-center">{row?.data?.candidateInNeverMeet}</div>
            </div>
        ),
        className: ''
    },
    {
        header: () => (
            <div className="py-4 px-6">
                <div className="flex items-center">Iter</div>
            </div>
        ),
        cell: (row) => (
            <div className="py-4 px-6 my-2">
                <div className="flex items-center">{row?.data?.candidateInIter}</div>
            </div>
        ),
        className: ''
    },
    {
        header: () => (
            <div className="py-4 px-6">
                <div className="flex items-center">Da richiamare</div>
            </div>
        ),
        cell: (row) => (
            <div className="py-4 px-6 my-2">
                <div className="flex items-center">{row?.data?.candidateInInterested}</div>
            </div>
        ),
        className: ''
    },
    {
        header: () => (
            <div className="py-4 px-6">
                <div className="flex items-center">Non idoneo</div>
            </div>
        ),
        cell: (row) => (
            <div className="py-4 px-6 my-2">
                <div className="flex items-center">{row?.data?.candidateInNotQualify}</div>
            </div>
        ),
        className: ''
    },
    {
        header: () => (
            <div className="py-4 px-6 icon">
                <div className="flex items-center"> </div>
            </div>
        ),
        cell: (row) => (
            <div className="py-4 px-6 my-2 icon">
                <div className="flex items-center cursor-pointer">
                    {row.metadata.hasChildren ? (
                        <FontAwesomeIcon
                            onClick={row.toggleChildren}
                            icon={row.$state.isExpanded ? faChevronDown : faChevronRight}
                        />
                    ) : (
                        ''
                    )}
                </div>
            </div>
        )
    }
];

export const USERS_REVENUE_TABLE_COLUMNS_TREE = [
    {
        header: () => (
            <div className="py-4 px-6">
                <div className="flex items-center">Manager</div>
            </div>
        ),
        cell: (row) => (
            <div className="p-4 my-2">
                <div
                    className="flex items-center"
                    style={{ paddingLeft: `${row.metadata.depth * 4}px` }}>
                    {row.metadata.index === 0 ? (
                        row?.data?.fullName
                    ) : (
                        <Link
                            to={`../manager/${row?.data?.id}`}
                            className="text-primary-800 cursor-pointer"
                            title={`${row?.data?.fullName}`}>
                            {`${row?.data?.fullName}`}
                        </Link>
                    )}
                </div>
            </div>
        )
    },
    {
        header: () => (
            <div className="py-4 px-6">
                <div className="flex items-center">Database</div>
            </div>
        ),
        cell: (row) => (
            <div className="py-4 px-6 my-2">
                <div className="flex items-center">{row?.data?.database}</div>
            </div>
        )
    },
    {
        header: () => (
            <div className="py-4 px-6">
                <div className="flex items-center">Conoscitivo progressivo</div>
            </div>
        ),
        cell: (row) => (
            <div className="py-4 px-6 my-2">
                <div className="flex items-center">{row?.data?.conoscitivo}</div>
            </div>
        )
    },
    {
        header: () => (
            <div className="py-4 px-6">
                <div className="flex items-center">Informativo</div>
            </div>
        ),
        cell: (row) => (
            <div className="py-4 px-6 my-2">
                <div className="flex items-center">{row?.data?.informativo}</div>
            </div>
        )
    },
    {
        header: () => (
            <div className="py-4 px-6">
                <div className="flex items-center">Progetto/ Master</div>
            </div>
        ),
        cell: (row) => (
            <div className="py-4 px-6 my-2">
                <div className="flex items-center">{row?.data?.progetto}</div>
            </div>
        )
    },
    {
        header: () => (
            <div className="py-4 px-6">
                <div className="flex items-center">Fase decisionale</div>
            </div>
        ),
        cell: (row) => (
            <div className="py-4 px-6 my-2">
                <div className="flex items-center">{row?.data?.decisionale}</div>
            </div>
        )
    },
    {
        header: () => (
            <div className="py-4 px-6">
                <div className="flex items-center">Esame</div>
            </div>
        ),
        cell: (row) => (
            <div className="py-4 px-6 my-2">
                <div className="flex items-center">{row?.data?.esame}</div>
            </div>
        )
    },
    {
        header: () => (
            <div className="py-4 px-6">
                <div className="flex items-center">Codici</div>
            </div>
        ),
        cell: (row) => (
            <div className="py-4 px-6 my-2">
                <div className="flex items-center">{row?.data?.codici}</div>
            </div>
        )
    },
    {
        header: () => (
            <div className="py-4 px-6">
                <div className="flex items-center">Totale candidati in iter</div>
            </div>
        ),
        cell: (row) => (
            <div className="py-4 px-6 my-2">
                <div className="flex items-center">{row?.data?.total}</div>
            </div>
        )
    },
    {
        header: () => (
            <div className="py-4 px-6">
                <div className="flex items-center">Da richiamare</div>
            </div>
        ),
        cell: (row) => (
            <div className="py-4 px-6 my-2">
                <div className="flex items-center">{row?.data?.daRichiamare}</div>
            </div>
        )
    },
    {
        header: () => (
            <div className="py-4 px-6">
                <div className="flex items-center">Non idonei</div>
            </div>
        ),
        cell: (row) => (
            <div className="py-4 px-6 my-2">
                <div className="flex items-center">{row?.data?.nonIdoneo}</div>
            </div>
        )
    },
    {
        header: () => (
            <div className="py-4 px-6 icon w-1/2">
                <div className="flex items-center"> </div>
            </div>
        ),
        cell: (row) => (
            <div className="py-4 px-6 my-2 icon w-1/2">
                <div className="flex items-center cursor-pointer">
                    {row.metadata.hasChildren ? (
                        <FontAwesomeIcon
                            onClick={row.toggleChildren}
                            icon={row.$state.isExpanded ? faChevronDown : faChevronRight}
                        />
                    ) : (
                        ''
                    )}
                </div>
            </div>
        )
        // basis: 'content',
        // grow: '0'
    }
];

export const SUB_USER_TABLE_COLUMNS = [
    { className: '', template: 'Manager', isSortable: false, sortStatus: null },
    { className: '', template: 'Livello', isSortable: false, sortStatus: null },
    { className: '', template: 'Data creazione', isSortable: false, sortStatus: null },
    { className: '', template: 'Segretarie', isSortable: false, sortStatus: null },
    { className: '', template: 'Mail', isSortable: false, sortStatus: null },
    { className: '', template: 'Assegnato a', isSortable: false, sortStatus: null }
];

export const SECRETARY_MANAGERS_TABLE_COLUMNS = [
    { className: '', template: 'Manager', isSortable: false, sortStatus: null },
    { className: '', template: 'Livello', isSortable: false, sortStatus: null },
    { className: '', template: 'Mail', isSortable: false, sortStatus: null },
    { className: '', template: 'Totale risorse DB', isSortable: false, sortStatus: null },
    {
        className: '',
        template: 'Chiamate cand. in iter Settimana',
        isSortable: false,
        sortStatus: null
    },
    {
        className: '',
        template: 'Chiamate cand. nuovi Settimana',
        isSortable: false,
        sortStatus: null
    },
    { className: '', template: 'Totale Chiamate candidati', isSortable: false, sortStatus: null },
    { className: '', template: 'Appuntam. cand. in iter', isSortable: false, sortStatus: null },
    { className: '', template: 'Appuntam. cand. nuovi', isSortable: false, sortStatus: null },
    { className: '', template: 'Totale Appuntam. candidati', isSortable: false, sortStatus: null }
];

export const SHARED_MANAGER_COLUMNS = [
    { className: '', template: 'Manager', isSortable: false, sortStatus: null },
    { className: '', template: 'Mail', isSortable: false, sortStatus: null },
    { className: '', template: 'Cellulare', isSortable: false, sortStatus: null },
    { className: '', template: 'Assegnato a', isSortable: false, sortStatus: null },
    { className: '', template: 'Condividi', isSortable: false, sortStatus: null }
];

/**
 * Forms
 */
export const INIT_USER = {
    name: '',
    surname: '',
    roleId: '',
    email: '',
    isManagerRole: 0,
    phoneNumber: '',
    address: '',
    managerId: '',
    secretaryIds: []
};

export const INIT_CANDIDATE = {
    name: '',
    surname: '',
    phoneNumber: '',
    address: '',
    email: '',
    managerId: '',
    mngManagerId: '',
    statusId: ''
};

export const INIT_ACTIVITY = {
    expireDate: '',
    candidateId: '',
    activityStatus: '',
    performingManagerId: '',
    activityWhere: '',
    iterId: '',
    managerId: ''
};

export const MONTH = [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre'
];
