/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-key */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { faMagnifyingGlass, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DataTable from '../../component/DataTable';
import Loader from '../../component/Loader';
import PrimaryButton from '../../component/PrimaryButton';
import SearchField from '../../component/SearchField';
import AppHeader from '../../component/header/AppHeader';
import HeaderItem from '../../component/header/HeaderItem';
import ConfirmationModal from '../../component/modal/ConfirmationModal';
import CreateManager from '../../component/modal/CreateManager';
import { MESSAGES, SECRETARY_TABLE_COLUMNS } from '../../constants';
import { getRolesDropdown } from '../../helper/services/apis/common-services';
import {
    deleteUser,
    getManagerCognativeCcandidates,
    getSecretariesListData,
    getUsersDatabase,
    getUsersRevenueData,
    updateUserField
} from '../../helper/services/apis/user-services';
import {
    buildQueryChunk,
    checkPagePermission,
    generateAndDownloadXlsx,
    getCurrentYear
} from '../../helper/utility';

function Secretaries() {
    const navigate = useNavigate();
    const currentYear = getCurrentYear();
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [managersData, setManagersData] = useState([]);
    const [roles, setRoles] = useState([]);
    const [years, setYears] = useState([]);
    const [managerRevenueData, setManagerRevenueData] = useState([]);
    const [managerRevenueSearchString, setManagerRevenueSearchString] = useState('');

    const [filters, setFilters] = useState({
        year: currentYear
    });

    const [frontFilters, setFrontFilters] = useState({
        searchString: '',
        // startDate: '',
        // endDate: '',
        role: ''
    });

    /**
     * Component State
     */
    const [state, setState] = useState({
        isLoading: false,
        isUserCandidateOpen: false,
        isConfirmDeleteOpen: false,
        currentTab: 0, // 0,1,2
        isCurrentYear: true,
        selectedManagerId: null
    });

    const initYears = async () => {
        const y = [];
        for (let index = 2022; index <= currentYear; index++) {
            y.push({ id: index, name: index });
        }
        setYears(
            y
                .concat([
                    { id: 'month-6', name: 'Ultimi 6 mesi' },
                    { id: 'month-3', name: 'Ultimi 3 mesi' },
                    { id: 'month-2', name: 'Ultimi 2 mesi' },
                    { id: 'month-1', name: 'Lo scorso mese' },
                    { id: 'day-14', name: 'Ultimi 14 giorni' },
                    { id: 'day-7', name: 'Ultimi 7 giorni' }
                ])
                .reverse()
        );
    };

    const retrieveRoles = async () => {
        const result = await getRolesDropdown().catch((e) => console.log(e.message));

        if (result.data) {
            setRoles([...result.data]);
        }
    };

    const setCurrentTab = (currentState, index) => {
        const data = { ...currentState };
        data.currentTab = index;
        setState({ ...data });
    };

    const getCandiateCount = (managers) => {
        let count = 0;
        managers.forEach((item) => {
            // debugger;
            count += item.candidates.length;
        });
        return count;
    };

    const generateManagerObjects = (data) => {
        const userData = [];

        data?.forEach((item) => {
            const user = {};
            user.id = item.id;
            user.fullName = item.fullName;
            user.secretaryManagersCount = item.secretaryManagersCount;
            user.database = getCandiateCount(item.secretaryManagers);
            user.email = item.email;
            user.isActive = item.isActive;
            user.candidateInIterCall = item.candidateInIterCall;
            user.candidateInIterMeeting = item.candidateInIterMeeting;
            user.candidateInNeverMeetCall = item.candidateInNeverMeetCall;
            user.candidateInNeverMeetMeeting = item.candidateInNeverMeetMeeting;
            user.callTotal = user.candidateInIterCall + user.candidateInNeverMeetCall;
            user.meetingTotal = user.candidateInIterMeeting + user.candidateInNeverMeetMeeting;
            userData.push(user);
        });
        return userData;
    };

    const generateManagerRevenueObjects = (data) => {
        const userData = [];
        const total = {
            r1Week: 0,
            r1Month: 0,
            r1Year: 0,
            fbday: 0,
            inFbday: 0,
            prod: 0,
            inProd: 0,
            revenue: 0,
            inRevenue: 0,
            studio: 0,
            inStudio: 0,
            agreement: 0,
            inAgreement: 0,
            inserito: 0,
            totalInIter: 0
        };

        data.forEach((item) => {
            const user = {};

            user.id = item.id;
            user.name = item.name;
            user.surname = item.surname;
            user.r1Week = item.r1InWeek;
            user.r1Month = item.r1InMonth;
            user.r1Year = item.r1InYear;
            user.fbday = item.fbdayInYear;
            user.inFbday = item.inFbdayForYear;
            user.prod = item.prodInYear;
            user.inProd = item.inProdForYear;
            user.revenue = item.revenueInYear;
            user.inRevenue = item.inRevenueForYear;
            user.studio = item.studioInYear;
            user.inStudio = item.inStudioForYear;
            user.agreement = item.agreementInYear;
            user.inAgreement = item.inAgreementForYear;
            user.inserito = item.inseritoStatusInYear;
            user.totalInIter =
                user.inFbday + user.inProd + user.inRevenue + user.inStudio + user.inAgreement;

            total.r1Week += user.r1Week;
            total.r1Month += user.r1Month;
            total.r1Year += user.r1Year;
            total.fbday += user.fbday;
            total.inFbday += user.inFbday;
            total.prod += user.prod;
            total.inProd += user.inProd;
            total.revenue += user.revenue;
            total.inRevenue += user.inRevenue;
            total.studio += user.studio;
            total.inStudio += user.inStudio;
            total.agreement += user.agreement;
            total.inAgreement += user.inAgreement;
            total.inserito += user.inserito;
            total.totalInIter += user.totalInIter;

            userData.push(user);
        });

        userData.reverse();
        userData.unshift(total);
        return userData;
    };

    const generateManagerMngObjects = (data) => {
        return data.map((item) => {
            return {
                ...item,
                mngFbdayCandidates: item.mngFbdayCandidates.map((candidate) => ({
                    id: candidate.id,
                    isStudying: candidate.isStudying,
                    name: candidate.fullName,
                    manager: candidate?.manager.fullName
                })),
                mngProdCandidates: item.mngProdCandidates.map((candidate) => ({
                    id: candidate.id,
                    isStudying: candidate.isStudying,
                    name: candidate.fullName,
                    manager: candidate?.manager.fullName
                })),
                mngRevenueCandidates: item.mngRevenueCandidates.map((candidate) => ({
                    id: candidate.id,
                    isStudying: candidate.isStudying,
                    name: candidate.fullName,
                    manager: candidate?.manager.fullName
                })),
                mngR2OrR3Candidates: item.mngR2OrR3Candidates.map((candidate) => ({
                    id: candidate.id,
                    isStudying: candidate.isStudying,
                    name: candidate.fullName,
                    manager: candidate?.manager.fullName
                })),
                mngAgreementCandidates: item.mngAgreementCandidates.map((candidate) => ({
                    id: candidate.id,
                    isStudying: candidate.isStudying,
                    name: candidate.fullName,
                    manager: candidate?.manager.fullName
                }))
            };
        });
    };

    const generateQueryString = (currentFilters, qs) => {
        let queryString = qs;

        Object.keys(currentFilters).forEach((key) => {
            const value = currentFilters[key];
            if (value) {
                if (key === 'year') {
                    if (typeof value == 'string') {
                        if (value.indexOf('day') > -1) {
                            const days = value.split('-')[1];
                            queryString = buildQueryChunk(queryString, 'day', days);
                        } else if (value.indexOf('month') > -1) {
                            const months = value.split('-')[1];
                            queryString = buildQueryChunk(queryString, 'month', months);
                        } else {
                            queryString = buildQueryChunk(queryString, key, value);
                        }
                    } else {
                        queryString = buildQueryChunk(queryString, key, value);
                    }
                } else {
                    queryString = buildQueryChunk(queryString, key, value);
                }
                // queryString = buildQueryChunk(queryString, key, value);
            }
        });

        return queryString;
    };

    // STEP: 1 API call for listing
    const retrieveUsersData = async (currentState, currentFilters, queryString) => {
        const data = { ...currentState };
        data.isLoading = true;
        setState({ ...data });

        const qs = generateQueryString(currentFilters, queryString);

        const result = await getSecretariesListData(qs).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
            data.isLoading = false;
            setState({ ...data });
        });

        if (result.data) {
            const userData = generateManagerObjects(result.data);
            setManagersData([...userData]);
            data.isLoading = false;
            setState({ ...data });
        }
    };

    const retrieveUsersRevenueData = async (currentState, currentFilters, queryString) => {
        const data = { ...currentState };
        data.isLoading = true;
        setState({ ...data });

        const qs = generateQueryString(currentFilters, queryString);

        const result = await getUsersRevenueData(qs).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
            data.isLoading = false;
            setState({ ...data });
        });

        if (result.data) {
            const userData = generateManagerRevenueObjects(result.data);
            setManagersData([...userData]);
            data.isLoading = false;
            setState({ ...data });
        }
    };

    const retrieveUsersMngData = async (currentState, currentFilters, queryString) => {
        const data = { ...currentState };
        data.isLoading = true;
        setState({ ...data });

        const qs = generateQueryString(currentFilters, queryString);

        const result = await getUsersDatabase(qs).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
            data.isLoading = false;
            setState({ ...data });
        });

        if (result.data) {
            const userData = generateManagerMngObjects(result.data);
            console.log(userData);
            setManagersData([...userData]);
            data.isLoading = false;
            setState({ ...data });
        }
    };

    const getManagerRevenueDataAndSet = async (id, currentState) => {
        if (id) {
            const newState = { ...currentState };
            newState.isLoading = true;
            setState({ ...newState });

            const qs = generateQueryString(
                { searchString: managerRevenueSearchString, year: filters.year },
                ''
            );

            const result = await getManagerCognativeCcandidates(id, qs).catch((e) => {
                console.log(e);
                toast.error(MESSAGES.SERVER_ERROR);
                newState.isLoading = false;
                setState({ ...newState });
            });

            if (result.data) {
                setManagerRevenueData(result.data);
                newState.isLoading = false;
                setState({ ...newState });
            }
        } else {
            setManagerRevenueData(null);
            setManagerRevenueSearchString('');
        }
    };

    const openManagerModal = (currentState, id) => {
        const newState = { ...currentState };
        newState.selectedManagerId = id;
        setState({ ...newState });
    };

    const closeManagerModel = (currentState) => {
        const newState = { ...currentState };
        newState.selectedManagerId = null;
        setState({ ...newState });
    };

    const onClickCreateUserBtn = (currentState) => {
        const data = { ...currentState };
        data.isUserCandidateOpen = true;
        setState({ ...data });
    };

    const onCloseCreateUserModal = (currentState, reload = false) => {
        const data = { ...currentState };
        data.isUserCandidateOpen = false;
        setState({ ...data });
        if (reload) {
            retrieveUsersData(data, filters, null);
        }
    };

    const handelActiveSwitchToggleHandler = async (event, user) => {
        if (user) {
            const result = await updateUserField(
                user.id,
                { isActive: user.isActive ? 0 : 1 },
                'status'
            ).catch((e) => {
                console.log(e);
                toast.error(MESSAGES.SERVER_ERROR);
            });
            if (result?.ok) {
                toast.success(MESSAGES.SUCCESS);
                retrieveUsersData(state, filters, null);
            }
        }
    };

    const filterData = (data, localFilters) => {
        return data.filter((item) => {
            if (localFilters.searchString.length) {
                return (
                    item?.email?.includes(localFilters.searchString) ||
                    item?.fullName?.includes(localFilters.searchString) ||
                    item?.manager?.fullName?.includes(localFilters.searchString)
                );
            }
            return true;
        });
    };

    const deleteOnClickHandler = (currentState, id) => {
        const newState = { ...currentState };
        newState.isConfirmDeleteOpen = true;
        setSelectedUserId(id);
        setState(newState);
    };

    const onCloseDeleteConfirmModal = (currentState) => {
        const newState = { ...currentState };
        newState.isConfirmDeleteOpen = false;
        setSelectedUserId(null);
        setState(newState);
    };

    const removeConfirmClickHandler = async (currentState, currentFilters, id) => {
        const newState = { ...currentState };
        newState.isLoading = true;
        setState(newState);

        const result = await deleteUser(id).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
            newState.isLoading = false;
            setState(newState);
        });
        if (result?.ok) {
            toast.success(MESSAGES.SUCCESS);
            newState.isLoading = false;
            newState.isConfirmDeleteOpen = false;
            setState(newState);
            setSelectedUserId(null);
            retrieveUsersData(newState, currentFilters);
        }
    };

    const getRows = (data, localFilters) => {
        const filteredData = filterData(data, localFilters);
        return filteredData.map((item) => {
            return {
                className: '',
                cells: [
                    {
                        template: (
                            <Link
                                to={`../secretary/${item.id}`}
                                className="text-primary-800 cursor-pointer"
                                title={item.fullName}>
                                {item.fullName}
                            </Link>
                        ),
                        className: 'min-w-[128px] font-bold'
                    },
                    {
                        template: (
                            <Link
                                to={`../secretary/${item.id}`}
                                className="text-primary-800 cursor-pointer"
                                title={item?.secretaryManagersCount}>
                                {item?.secretaryManagersCount}
                            </Link>
                        ),
                        className: 'min-w-[128px] font-bold'
                    },
                    {
                        template: item.database,
                        className: 'min-w-[128px] font-bold'
                    },
                    {
                        template: item?.email,
                        className: 'min-w-[128px] font-bold'
                    },
                    {
                        template: item?.candidateInIterCall,
                        className: 'min-w-[128px] font-bold text-center'
                    },
                    {
                        template: item?.candidateInNeverMeetCall,
                        className: 'min-w-[128px] font-bold text-center'
                    },
                    {
                        template: item?.callTotal,
                        className: 'min-w-[128px] font-bold text-center'
                    },
                    {
                        template: item?.candidateInIterMeeting,
                        className: 'min-w-[128px] font-bold text-center'
                    },
                    {
                        template: item?.candidateInNeverMeetMeeting,
                        className: 'min-w-[128px] font-bold text-center'
                    },
                    {
                        template: item?.meetingTotal,
                        className: 'min-w-[128px] font-bold text-center'
                    },
                    {
                        template: (
                            <label className="relative inline-flex items-center mr-5 cursor-pointer">
                                <input
                                    type="checkbox"
                                    value=""
                                    className="sr-only peer"
                                    checked={item.isActive}
                                    onClick={(e) => handelActiveSwitchToggleHandler(e, item)}
                                />
                                <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-green-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-500" />
                            </label>
                        ),
                        className: 'min-w-[128px] font-bold'
                    },
                    {
                        template: (
                            <FontAwesomeIcon
                                icon={faTrashCan}
                                onClick={() => deleteOnClickHandler(state, item.id)}
                            />
                        ),
                        className: 'min-w-[128px] text-lg text-center text-red-600 cursor-pointer'
                    }
                ]
            };
        });
    };

    const getPercentage = (number1, number2) => {
        let result = 0;

        result =
            Number.isNaN(number1 / number2) || number1 / number2 === Infinity
                ? 0
                : (number1 * 100) / number2;

        return result.toFixed(2);
    };

    const getRevenueRows = (data, currentState) => {
        if (currentState.isCurrentYear) {
            return data.map((item, index) => {
                if (index === 0) {
                    return {
                        className: 'font-bold',
                        cells: [
                            {
                                template: 'TOTALE REGION',
                                className:
                                    'border-b-lightBlue-600 border-b-2 min-w-[128px] font-bold'
                            },
                            {
                                template: item?.r1Week,
                                className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                            },
                            {
                                template: item?.r1Month,
                                className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                            },
                            {
                                template: item?.r1Year,
                                className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                            },

                            {
                                template: (
                                    <>
                                        <span>{item?.fbday}</span>
                                        <br />
                                        <span className="text-red-500">
                                            {`(${getPercentage(item?.fbday, item?.r1Year)}%)`}
                                        </span>
                                    </>
                                ),
                                className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                            },
                            {
                                template: item.inFbday,
                                className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                            },

                            {
                                template: (
                                    <>
                                        <span>{item?.prod}</span>
                                        <br />
                                        <span className="text-red-500">
                                            {`(${getPercentage(item?.prod, item?.fbday)}%)`}
                                        </span>
                                    </>
                                ),
                                className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                            },
                            {
                                template: item?.inProd,
                                className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                            },

                            {
                                template: (
                                    <>
                                        <span>{item?.revenue}</span>
                                        <br />
                                        <span className="text-red-500">
                                            {`(${getPercentage(item?.revenue, item?.prod)}%)`}
                                        </span>
                                    </>
                                ),
                                className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                            },
                            {
                                template: item?.inRevenue,
                                className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                            },

                            {
                                template: (
                                    <>
                                        <span>{item?.studio}</span>
                                        <br />
                                        <span className="text-red-500">
                                            {`(${getPercentage(item?.studio, item?.revenue)}%)`}
                                        </span>
                                    </>
                                ),
                                className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                            },
                            {
                                template: item?.inStudio,
                                className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                            },

                            {
                                template: (
                                    <>
                                        <span>{item?.agreement}</span>
                                        <br />
                                        <span className="text-red-500">
                                            {`(${getPercentage(item?.agreement, item?.studio)}%)`}
                                        </span>
                                    </>
                                ),
                                className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                            },
                            {
                                template: item.inAgreement,
                                className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                            },

                            {
                                template: (
                                    <>
                                        <span>{item?.inserito}</span>
                                        <br />
                                        <span className="text-red-500">
                                            {`(${getPercentage(item?.inserito, item?.agreement)}%)`}
                                        </span>
                                    </>
                                ),
                                className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                            },
                            {
                                template: item.totalInIter,
                                className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                            },
                            {
                                template: '',
                                className: 'border-b-lightBlue-600 border-b-2 min-w-[50px]'
                            }
                        ]
                    };
                }
                return {
                    className: 'font-bold',
                    cells: [
                        {
                            template: (
                                <Link
                                    to={`../manager/${item.id}`}
                                    className="text-primary-800 cursor-pointer"
                                    title={`${item.name} ${item.surname}`}>
                                    {`${item.name} ${item.surname}`}
                                </Link>
                            ),
                            className: 'min-w-[128px] font-bold'
                        },
                        {
                            template: item?.r1Week,
                            className: 'min-w-[128px]'
                        },
                        {
                            template: item?.r1Month,
                            className: 'min-w-[128px]'
                        },
                        {
                            template: item?.r1Year,
                            className: 'min-w-[128px]'
                        },

                        {
                            template: (
                                <>
                                    <span>{item?.fbday}</span>
                                    <br />
                                    <span className="text-red-500">
                                        {`(${getPercentage(item?.fbday, item?.r1Year)}%)`}
                                    </span>
                                </>
                            ),
                            className: 'min-w-[128px]'
                        },
                        {
                            template: item.inFbday,
                            className: 'min-w-[128px]'
                        },

                        {
                            template: (
                                <>
                                    <span>{item?.prod}</span>
                                    <br />
                                    <span className="text-red-500">
                                        {`(${getPercentage(item?.prod, item?.fbday)}%)`}
                                    </span>
                                </>
                            ),
                            className: 'min-w-[128px]'
                        },
                        {
                            template: item?.inProd,
                            className: 'min-w-[128px]'
                        },

                        {
                            template: (
                                <>
                                    <span>{item?.revenue}</span>
                                    <br />
                                    <span className="text-red-500">
                                        {`(${getPercentage(item?.revenue, item?.prod)}%)`}
                                    </span>
                                </>
                            ),
                            className: 'min-w-[128px]'
                        },
                        {
                            template: item?.inRevenue,
                            className: 'min-w-[128px]'
                        },

                        {
                            template: (
                                <>
                                    <span>{item?.studio}</span>
                                    <br />
                                    <span className="text-red-500">
                                        {`(${getPercentage(item?.studio, item?.revenue)}%)`}
                                    </span>
                                </>
                            ),
                            className: 'min-w-[128px]'
                        },
                        {
                            template: item?.inStudio,
                            className: 'min-w-[128px]'
                        },

                        {
                            template: (
                                <>
                                    <span>{item?.agreement}</span>
                                    <br />
                                    <span className="text-red-500">
                                        {`(${getPercentage(item?.agreement, item?.studio)}%)`}
                                    </span>
                                </>
                            ),
                            className: 'min-w-[128px]'
                        },
                        {
                            template: item.inAgreement,
                            className: 'min-w-[128px]'
                        },

                        {
                            template: (
                                <>
                                    <span>{item?.inserito}</span>
                                    <br />
                                    <span className="text-red-500">
                                        {`(${getPercentage(item?.inserito, item?.agreement)}%)`}
                                    </span>
                                </>
                            ),
                            className: 'min-w-[128px]'
                        },
                        {
                            template: item.totalInIter,
                            className: 'min-w-[128px]'
                        },
                        {
                            template: (
                                <FontAwesomeIcon
                                    icon={faMagnifyingGlass}
                                    onClick={() => openManagerModal(state, item.id)}
                                />
                            ),
                            className:
                                'min-w-[50px] text-lg text-center text-primary-500 cursor-pointer'
                        }
                    ]
                };
            });
        }
        return data.map((item, index) => {
            if (index === 0) {
                return {
                    className: 'font-bold',
                    cells: [
                        {
                            template: 'TOTALE REGION',
                            className: 'border-b-lightBlue-600 border-b-2 min-w-[128px] font-bold'
                        },
                        // {
                        //     template: item?.r1Week,
                        //     className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                        // },
                        // {
                        //     template: item?.r1Month,
                        //     className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                        // },
                        {
                            template: item?.r1Year,
                            className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                        },

                        {
                            template: (
                                <>
                                    <span>{item?.fbday}</span>
                                    {/* <br />
                                    <span className="text-red-500">
                                        {`(${getPercentage(item?.fbday, item?.r1Year)}%)`}
                                    </span> */}
                                </>
                            ),
                            className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                        },
                        // {
                        //     template: item.inFbday,
                        //     className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                        // },

                        {
                            template: (
                                <>
                                    <span>{item?.prod}</span>
                                    {/* <br />
                                    <span className="text-red-500">
                                        {`(${getPercentage(item?.prod, item?.fbday)}%)`}
                                    </span> */}
                                </>
                            ),
                            className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                        },
                        // {
                        //     template: item?.inProd,
                        //     className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                        // },

                        {
                            template: (
                                <>
                                    <span>{item?.revenue}</span>
                                    {/* <br />
                                    <span className="text-red-500">
                                        {`(${getPercentage(item?.revenue, item?.prod)}%)`}
                                    </span> */}
                                </>
                            ),
                            className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                        },
                        // {
                        //     template: item?.inRevenue,
                        //     className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                        // },

                        {
                            template: (
                                <>
                                    <span>{item?.studio}</span>
                                    {/* <br />
                                    <span className="text-red-500">
                                        {`(${getPercentage(item?.studio, item?.revenue)}%)`}
                                    </span> */}
                                </>
                            ),
                            className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                        },
                        // {
                        //     template: item?.inStudio,
                        //     className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                        // },

                        {
                            template: (
                                <>
                                    <span>{item?.agreement}</span>
                                    {/* <br />
                                    <span className="text-red-500">
                                        {`(${getPercentage(item?.agreement, item?.studio)}%)`}
                                    </span> */}
                                </>
                            ),
                            className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                        },
                        // {
                        //     template: item.inAgreement,
                        //     className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                        // },

                        {
                            template: item.inserito,
                            className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                        },
                        // {
                        //     template: item.totalInIter,
                        //     className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                        // }
                        {
                            template: '',
                            className: 'border-b-lightBlue-600 border-b-2 min-w-[50px]'
                        }
                    ]
                };
            }
            return {
                className: 'font-bold',
                cells: [
                    {
                        template: (
                            <Link
                                to={`../manager/${item.id}`}
                                className="text-primary-800 cursor-pointer"
                                title={`${item.name} ${item.surname}`}>
                                {`${item.name} ${item.surname}`}
                            </Link>
                        ),
                        className: 'min-w-[128px] font-bold'
                    },
                    // {
                    //     template: item?.r1Week,
                    //     className: 'min-w-[128px]'
                    // },
                    // {
                    //     template: item?.r1Month,
                    //     className: 'min-w-[128px]'
                    // },
                    {
                        template: item?.r1Year,
                        className: 'min-w-[128px]'
                    },

                    {
                        template: (
                            <>
                                <span>{item?.fbday}</span>
                                {/* <br />
                                <span className="text-red-500">
                                    {`(${getPercentage(item?.fbday, item?.r1Year)}%)`}
                                </span> */}
                            </>
                        ),
                        className: 'min-w-[128px]'
                    },
                    // {
                    //     template: item.inFbday,
                    //     className: 'min-w-[128px]'
                    // },

                    {
                        template: (
                            <>
                                <span>{item?.prod}</span>
                                {/* <br />
                                <span className="text-red-500">
                                    {`(${getPercentage(item?.prod, item?.fbday)}%)`}
                                </span> */}
                            </>
                        ),
                        className: 'min-w-[128px]'
                    },
                    // {
                    //     template: item?.inProd,
                    //     className: 'min-w-[128px]'
                    // },

                    {
                        template: (
                            <>
                                <span>{item?.revenue}</span>
                                {/* <br />
                                <span className="text-red-500">
                                    {`(${getPercentage(item?.revenue, item?.prod)}%)`}
                                </span> */}
                            </>
                        ),
                        className: 'min-w-[128px]'
                    },
                    // {
                    //     template: item?.inRevenue,
                    //     className: 'min-w-[128px]'
                    // },

                    {
                        template: (
                            <>
                                <span>{item?.studio}</span>
                                {/* <br />
                                <span className="text-red-500">
                                    {`(${getPercentage(item?.studio, item?.revenue)}%)`}
                                </span> */}
                            </>
                        ),
                        className: 'min-w-[128px]'
                    },
                    // {
                    //     template: item?.inStudio,
                    //     className: 'min-w-[128px]'
                    // },

                    {
                        template: (
                            <>
                                <span>{item?.agreement}</span>
                                {/* <br />
                                <span className="text-red-500">
                                    {`(${getPercentage(item?.agreement, item?.studio)}%)`}
                                </span> */}
                            </>
                        ),
                        className: 'min-w-[128px]'
                    },
                    // {
                    //     template: item.inAgreement,
                    //     className: 'min-w-[128px]'
                    // },

                    {
                        template: item.inserito,
                        className: 'min-w-[128px]'
                    },
                    // {
                    //     template: item.totalInIter,
                    //     className: 'min-w-[128px]'
                    // }
                    {
                        template: (
                            <FontAwesomeIcon
                                icon={faMagnifyingGlass}
                                onClick={() => openManagerModal(state, item.id)}
                            />
                        ),
                        className:
                            'min-w-[50px] text-lg text-center text-primary-500 cursor-pointer'
                    }
                ]
            };
        });
    };

    const getMngCell = (items) => {
        const template = [<div className="font-bold mb-1">{items?.length} Candidati</div>];

        items?.forEach((item) => {
            template.push(
                <div className="mb-0.5">
                    <br />
                    <span className={item.isStudying ? 'text-primary-500' : ''}>
                        {item.name}
                    </span>{' '}
                    <span className="text-red-500">({item.manager})</span>
                </div>
            );
        });

        return template;
    };

    const getMngRows = (data) => {
        return data.map((item) => {
            return {
                className: '',
                cells: [
                    {
                        template: (
                            <Link
                                to={`../manager/${item.id}`}
                                className="text-primary-800 cursor-pointer"
                                title={`${item.name} ${item.surname}`}>
                                {`${item.name} ${item.surname}`}
                            </Link>
                        ),
                        className: 'min-w-[128px] font-bold'
                    },
                    { template: getMngCell(item?.mngFbdayCandidates), className: 'min-w-[128px]' },
                    { template: getMngCell(item?.mngProdCandidates), className: 'min-w-[128px]' },
                    {
                        template: getMngCell(item?.mngRevenueCandidates),
                        className: 'min-w-[128px]'
                    },
                    { template: getMngCell(item?.mngR2OrR3Candidates), className: 'min-w-[128px]' },
                    {
                        template: getMngCell(item?.mngAgreementCandidates),
                        className: 'min-w-[128px]'
                    }
                ]
            };
        });
    };

    const handleFilterChange = (e, currentFilters, filter, currentState) => {
        const newFilters = { ...currentFilters };
        const event = { ...e };
        newFilters[filter] = event.target.value;
        if (filter === 'year') {
            const newState = { ...currentState };
            newState.isCurrentYear = event.target.value == currentYear;
            setState({ ...newState });
        }
        setFilters({ ...newFilters });
    };

    const handleLocalFilterChange = (e, currentFilters, filter) => {
        const newFilters = { ...currentFilters };
        if (filter === 'searchString') {
            newFilters[filter] = e;
        } else {
            const event = { ...e };
            newFilters[filter] = event.target.value;
        }
        setFrontFilters({ ...newFilters });
    };

    const getManagerCsvData = (data, localFilters) => {
        const columns = [
            { header: 'Segretarie', field: 'name' },
            { header: 'Supervisori assegnati', field: 'secretaryManagersCount' },
            { header: 'Database', field: 'template' },
            { header: 'Mail', field: 'email' },
            { header: 'Chiamate cand. in iter', field: 'candidateInIterCall' },
            { header: 'Chiamate cand. nuovi', field: 'candidateInNeverMeetCall' },
            { header: 'Totale Chiamate candidati', field: 'callTotal' },
            { header: 'Appuntam. cand. in iter', field: 'candidateInIterMeeting' },
            { header: 'Appuntam. cand. nuovi', field: 'candidateInNeverMeetMeeting' },
            { header: 'Totale Appuntam. candidati', field: 'meetingTotal' },
            { header: 'Attivo', field: 'mngCrescita' }
        ];
        const filteredData = filterData(data, localFilters);
        const finalData = filteredData.map((item) => {
            return {
                name: item.fullName,
                secretaryManagersCount: item?.secretaryManagersCount,
                template: item.database,
                email: item?.email,
                candidateInIterCall: item?.candidateInIterCall,
                candidateInNeverMeetCall: item?.candidateInNeverMeetCall,
                callTotal: item?.callTotal,
                candidateInIterMeeting: item?.candidateInIterMeeting,
                candidateInNeverMeetMeeting: item?.candidateInNeverMeetMeeting,
                meetingTotal: item?.meetingTotal,
                mngCrescita: item.isManagerRole ? 'Si' : 'No'
            };
        });

        return { columns, finalData };
    };

    const getRevenueCsvData = (data) => {
        if (state.isCurrentYear) {
            const columns = [
                { header: 'Manager', field: 'name' },
                { header: 'R1 Settimana corrente', field: 'r1Week' },
                { header: 'R1 Mese corrente', field: 'r1Month' },
                { header: 'R1 progressivi', field: 'r1Year' },
                { header: 'FBD progressivi', field: 'fbday' },
                { header: 'Candidati stato fbd', field: 'inFbday' },
                { header: 'Prodotti progressivi', field: 'prod' },
                { header: 'Candidati stato prodotti', field: 'inProd' },
                { header: 'Ricavi progressivi', field: 'revenue' },
                { header: 'Candidati stato ricavi', field: 'inRevenue' },
                { header: 'Proposte progressivi', field: 'studio' },
                { header: 'Candidati stato proposte', field: 'inStudio' },
                { header: 'Prova valutativa progressi', field: 'agreement' },
                { header: 'Candidati stato prova v.', field: 'inAgreement' },
                { header: 'Potenziale codici progressivi', field: 'inserito' },
                { header: 'Totale candidati in iter', field: 'total' }
            ];
            const finalData = data.map((item, index) => {
                if (index === 0) {
                    return {
                        name: 'TOTALE REGION',
                        r1Week: item?.r1Week,
                        r1Month: item?.r1Month,
                        r1Year: item?.r1Year,
                        fbday: `${item?.fbday} (${getPercentage(item?.fbday, item?.r1Year)}%)`,
                        inFbday: item.inFbday,
                        prod: `${item?.prod} (${getPercentage(item?.prod, item?.fbday)}%)`,
                        inProd: item?.inProd,
                        revenue: `${item?.revenue} (${getPercentage(item?.revenue, item?.prod)}%)`,
                        inRevenue: item?.inRevenue,
                        studio: `${item?.studio} (${getPercentage(item?.studio, item?.revenue)}%)`,
                        inStudio: item?.inStudio,
                        agreement: `${item?.agreement} (${getPercentage(
                            item?.agreement,
                            item?.studio
                        )}%)`,
                        inAgreement: item.inAgreement,
                        inserito: `${item?.inserito} (${getPercentage(
                            item?.inserito,
                            item?.agreement
                        )}%)`,
                        total: item.totalInIter
                    };
                }
                return {
                    name: `${item.name} ${item.surname}`,
                    r1Week: item?.r1Week,
                    r1Month: item?.r1Month,
                    r1Year: item?.r1Year,
                    fbday: `${item?.fbday} (${getPercentage(item?.fbday, item?.r1Year)}%)`,
                    inFbday: item.inFbday,
                    prod: `${item?.prod} (${getPercentage(item?.prod, item?.fbday)}%)`,
                    inProd: item?.inProd,
                    revenue: `${item?.revenue} (${getPercentage(item?.revenue, item?.prod)}%)`,
                    inRevenue: item?.inRevenue,
                    studio: `${item?.studio} (${getPercentage(item?.studio, item?.revenue)}%)`,
                    inStudio: item?.inStudio,
                    agreement: `${item?.agreement} (${getPercentage(
                        item?.agreement,
                        item?.studio
                    )}%)`,
                    inAgreement: item.inAgreement,
                    inserito: `${item?.inserito} (${getPercentage(
                        item?.inserito,
                        item?.agreement
                    )}%)`,
                    total: item.totalInIter
                };
            });
            return { columns, finalData };
        }

        const columns = [
            { header: 'Manager', field: 'name' },
            // { header: 'R1 Settimana corrente', field: 'r1Week' },
            // { header: 'R1 Mese corrente', field: 'r1Month' },
            { header: 'R1 progressivi', field: 'r1Year' },
            { header: 'FBD progressivi', field: 'fbday' },
            // { header: 'Candidati stato fbd', field: 'inFbday' },
            { header: 'Prodotti progressivi', field: 'prod' },
            // { header: 'Candidati stato prodotti', field: 'inProd' },
            { header: 'Ricavi progressivi', field: 'revenue' },
            // { header: 'Candidati stato ricavi', field: 'inRevenue' },
            { header: 'Proposte progressivi', field: 'studio' },
            // { header: 'Candidati stato proposte', field: 'inStudio' },
            { header: 'Prova valutativa progressi', field: 'agreement' },
            // { header: 'Candidati stato prova v.', field: 'inAgreement' },
            { header: 'Potenziale codici progressivi', field: 'inserito' }
            // { header: 'Totale candidati in iter', field: 'total' }
        ];
        const finalData = data.map((item, index) => {
            if (index === 0) {
                return {
                    name: 'TOTALE REGION',
                    // r1Week: item?.r1Week,
                    // r1Month: item?.r1Month,
                    r1Year: item?.r1Year,
                    fbday: `${item?.fbday}`,
                    // inFbday: item.inFbday,
                    prod: `${item?.prod}`,
                    // inProd: item?.inProd,
                    revenue: `${item?.revenue}`,
                    // inRevenue: item?.inRevenue,
                    studio: `${item?.studio}`,
                    // inStudio: item?.inStudio,
                    agreement: `${item?.agreement}`,
                    // inAgreement: item.inAgreement,
                    inserito: `${item?.inserito}`
                    // total: item.totalInIter
                };
            }
            return {
                name: `${item.name} ${item.surname}`,
                // r1Week: item?.r1Week,
                // r1Month: item?.r1Month,
                r1Year: item?.r1Year,
                fbday: `${item?.fbday}`,
                // inFbday: item.inFbday,
                prod: `${item?.prod}`,
                // inProd: item?.inProd,
                revenue: `${item?.revenue}`,
                // inRevenue: item?.inRevenue,
                studio: `${item?.studio}`,
                // inStudio: item?.inStudio,
                agreement: `${item?.agreement}`,
                // inAgreement: item.inAgreement,
                inserito: `${item?.inserito}`
                // total: item.totalInIter
            };
        });
        return { columns, finalData };
    };

    const getMngCSVCell = (items) => {
        let template = '';

        items?.forEach((item, index) => {
            if (index === 0) {
                template = `${index + 1}) ${item.name} (${item.manager})`;
            } else {
                template = `${template} ${index + 1}) ${item.name} (${item.manager})`;
            }
        });

        return template;
    };

    const getMngManagerCsvData = (data) => {
        const columns = [
            { header: 'MNG Crescita', field: 'name' },
            { header: 'FBD', field: 'fbd' },
            { header: 'Prodotti', field: 'prod' },
            { header: 'Ricavi', field: 'revenue' },
            { header: 'Pre-proposta', field: 'r2' },
            { header: 'Proposta', field: 'agreement' }
        ];
        const finalData = data.map((item) => {
            return {
                name: `${item.name} ${item.surname}`,
                fbd: getMngCSVCell(item?.mngFbdayCandidates),
                prod: getMngCSVCell(item?.mngProdCandidates),
                revenue: getMngCSVCell(item?.mngRevenueCandidates),
                r2: getMngCSVCell(item?.mngR2OrR3Candidates),
                agreement: getMngCSVCell(item?.mngAgreementCandidates)
            };
        });

        return { columns, finalData };
    };

    const downloadCsv = (data, localFilters) => {
        let result = null;

        result = getManagerCsvData(data, localFilters);
        const selectedColumns = result?.columns.map((item) => ({
            label: item.header,
            value: item.field
        }));
        if (result && result.columns && result.finalData) {
            generateAndDownloadXlsx(selectedColumns, result.finalData, 'Segretarie');
        }
    };

    const resetFrontFilters = () => {
        setFrontFilters({
            searchString: '',
            role: ''
        });
    };

    /**
     * Watchers
     */
    useEffect(() => {
        getManagerRevenueDataAndSet(state.selectedManagerId, state);
    }, [state.selectedManagerId, managerRevenueSearchString]);

    useEffect(() => {
        resetFrontFilters();
    }, [state.currentTab]);

    useEffect(() => {
        retrieveUsersData(state, filters, null);
    }, [filters, state.currentTab]);

    useEffect(() => {
        checkPagePermission(navigate);
        retrieveRoles();
        initYears();
    }, []);

    return (
        <section className="space-y-2">
            {state.isLoading ? <Loader /> : null}

            {state.isUserCandidateOpen ? (
                <CreateManager
                    isOpen
                    isCreateNew
                    isSecretary
                    closeModel={(reload = false) => onCloseCreateUserModal(state, reload)}
                />
            ) : null}

            {state.isConfirmDeleteOpen ? (
                <ConfirmationModal
                    isOpen={state.isConfirmDeleteOpen}
                    title="Elimina manager"
                    message="Vuoi eliminare il manager scelto?"
                    onConfirmAction={() =>
                        removeConfirmClickHandler(state, filters, selectedUserId)
                    }
                    closeModel={() => onCloseDeleteConfirmModal(state)}
                    confirmBtnText="ELIMINA"
                />
            ) : null}

            <AppHeader>
                <HeaderItem>
                    <span
                        className={`cursor-pointer font-bold text-2xl text-grey-900 ${
                            state.currentTab === 0 ? 'text-grey-900' : 'text-grey-600'
                        }`}
                        aria-hidden="true">
                        {managersData.length ? managersData.length : 0} Segretarie
                    </span>
                </HeaderItem>
                <HeaderItem>
                    <div className="inline-block input-field-group">
                        <select
                            id="year"
                            name="year"
                            value={filters.year}
                            onChange={(e) => handleFilterChange(e, filters, 'year', state)}>
                            {years.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <SearchField
                        defaultValue={filters?.searchString}
                        onPressEnter={(e) =>
                            handleLocalFilterChange(e, frontFilters, 'searchString')
                        }
                    />
                </HeaderItem>
            </AppHeader>

            {/* <AppHeader>
                <HeaderItem />
                <HeaderItem className="w-80">
                    <div className="inline-block w-full">
                        <SearchField
                            defaultValue={filters?.searchString}
                            onPressEnter={(e) => handleFilterChange(e, filters, 'searchString')}
                        />
                    </div>
                </HeaderItem>
            </AppHeader> */}

            <AppHeader>
                <HeaderItem />
                <HeaderItem>
                    <PrimaryButton onClick={() => downloadCsv(managersData, frontFilters)}>
                        XLS
                    </PrimaryButton>
                    {state.currentTab === 0 && (
                        <PrimaryButton onClick={() => onClickCreateUserBtn(state)}>
                            AGGIUNGI
                        </PrimaryButton>
                    )}
                </HeaderItem>
            </AppHeader>

            <DataTable
                columns={SECRETARY_TABLE_COLUMNS}
                rows={getRows(managersData, frontFilters)}
            />
        </section>
    );
}

export default Secretaries;
