/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
// import { Select } from 'flowbite-react';
// import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import { MESSAGES } from '../../constants';
import { getActivityCandidates } from '../../helper/services/apis/candidate-services';

function CandidateDropdown({
    register,
    name,
    config = {},
    errors,
    className = null,
    defaultValue,
    setValue,
    label,
    multiple = false
}) {
    const [options, setOptions] = useState([]);

    const initValues = async () => {
        const result = await getActivityCandidates().catch((e) => console.log(e.message));

        if (result.data) {
            if (multiple) {
                setOptions(result.data.map((item) => ({ value: item.id, label: item.fullName })));
            } else {
                setOptions([...result.data]);
            }
        }
    };

    const handleChange = (event) => {
        if (event && event.length) {
            setValue(name, event);
        } else {
            setValue(name, '');
        }
    };

    useEffect(() => {
        initValues();
        if (setValue) {
            handleChange(defaultValue);
        }
    }, [defaultValue]);

    return (
        <div
            className={`w-full flex items-center space-x-2 input-field-group ${className} ${
                errors[name] ? 'invalid-field' : ''
            }`}>
            <div className="w-1/3">
                <label htmlFor="manager">{config.required ? `${label}*` : label} : </label>
            </div>
            <div className="w-2/3">
                {multiple ? (
                    options.length ? (
                        <>
                            <Select
                                defaultValue={[...defaultValue]}
                                isMulti
                                name="manager"
                                onChange={handleChange}
                                options={options}
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                            <input type="hidden" {...register(name, { ...config })} />
                        </>
                    ) : null
                ) : options.length ? (
                    <select id="manager" name="manager" {...register(name, { ...config })}>
                        <option value="">Seleziona...</option>
                        {options.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option.fullName}
                            </option>
                        ))}
                    </select>
                ) : null}

                {errors[name]?.type === 'required' && (
                    <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                )}

                {/* <AsyncSelect cacheOptions loadOptions={loadOptions} defaultOptions /> */}
            </div>
        </div>
    );
}

export default CandidateDropdown;
