/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import emailIcon from '../assets/images/email.png';
import locationIcon from '../assets/images/location.png';
import phoneIcon from '../assets/images/phone.png';
import { CANDIDATE_STATUS } from '../constants';
import { reformatDate } from '../helper/utility';

function ReportTemplate({ candidateData }) {
    return (
        <div className="flex rounded-md text-[10px] h-[630px]">
            <div className="flex flex-row flex-shrink-0 px-2 h-full w-36 border-r-[1px] justify-between overflow-auto text-[8px]">
                <div className="flex justify-between md:block">
                    <div className="md:border-r-0 border-b-[1px] px-2 md:px-0 md:py-2 text-center">
                        <div className="inline-block relative">
                            {/* <img
                                src={candidateData?.userProfile || profilePlaceholder}
                                alt="Girl in a jacket"
                                width="80"
                                height="80"
                                className="bg-auto bg-no-repeat bg-center rounded-full h-35"
                            /> */}
                        </div>
                        <span className="font-bold block text-left text-sm">
                            {candidateData?.fullName}
                        </span>
                    </div>
                    <div className="border-b-[1px] px-2 px-0 py-2 space-y-1">
                        <div className="flex space-x-2 items-center">
                            <span>Stato :</span>
                            {candidateData && candidateData.status && (
                                <span
                                    className="px-2 py-0 pb-2 rounded-md"
                                    style={{
                                        background:
                                            CANDIDATE_STATUS[candidateData?.status?.name]?.color,
                                        color: CANDIDATE_STATUS[candidateData?.status?.name]?.text
                                    }}>
                                    {candidateData?.status?.name}
                                    {/* <span className="font-bold"></span> */}
                                </span>
                            )}
                        </div>
                        <div className="flex space-x-2">
                            <span>Fase iter :</span>{' '}
                            <span className="font-bold">{candidateData?.iter?.name}</span>
                        </div>
                        <div className="flex space-x-2">
                            <span>Giorni in step :</span>{' '}
                            <span
                                className={`flex justify-center items-center text-center font-bold ${
                                    candidateData?.currentIterCounter < 11
                                        ? 'text-green-600'
                                        : candidateData?.currentIterCounter < 21
                                        ? 'text-yellow-600'
                                        : 'text-red-600'
                                }`}>
                                {candidateData?.currentIterCounter
                                    ? candidateData?.currentIterCounter
                                    : 0}
                            </span>
                        </div>
                        <div className="flex space-x-2 items-center">
                            <span>Giorni in iter :</span>{' '}
                            <span className="font-bold">
                                {candidateData?.totalIterCounter
                                    ? candidateData?.totalIterCounter
                                    : 0}
                            </span>
                        </div>
                        <div className="flex space-x-2">
                            <span>Rating :</span>{' '}
                            <span className="font-bold">{candidateData?.rating}</span>
                        </div>
                    </div>
                    <div className="border-b-[1px] px-2 px-0 py-2 space-y-2">
                        <div className="flex space-x-2 items-center">
                            <span className="min-w-min">
                                <img
                                    src={locationIcon}
                                    alt="Girl in a jacket"
                                    className="bg-auto bg-no-repeat bg-center h-[8px]"
                                />
                            </span>
                            <span className="ml-3 text-[9px] mt-[-8px]">
                                {candidateData.address}
                            </span>
                        </div>
                        <div className="flex space-x-2 items-center">
                            <span className="min-w-min">
                                <img
                                    src={phoneIcon}
                                    alt="Girl in a jacket"
                                    className="bg-auto bg-no-repeat bg-center h-[8px]"
                                />
                            </span>
                            <span className="ml-3 mt-[-8px]">{candidateData.phoneNumber}</span>
                        </div>
                        <div className="flex space-x-2 items-center">
                            <span className="min-w-min">
                                <img
                                    src={emailIcon}
                                    alt="Girl in a jacket"
                                    className="bg-auto bg-no-repeat bg-center h-[6px]"
                                />
                            </span>
                            <span className="ml-3 mt-[-8px]">{candidateData.email}</span>
                        </div>
                    </div>
                    <div className="border-b-[1px] px-2 px-0 py-2 space-y-2">
                        <div className="flex justify-between items-center">
                            <span>Il candidato sta studiando per l’esame</span>
                            <div className="input-field-group">
                                <input
                                    type="checkbox"
                                    id="privacy"
                                    name="privacy"
                                    placeholder="privacy"
                                    className="cursor-pointer"
                                    checked={candidateData.isStudying}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="border-b-[1px] px-2 px-0 py-2 space-y-2">
                        <div className="flex justify-between items-center">
                            <span>Privacy</span>
                            <div className="input-field-group">
                                <input
                                    type="checkbox"
                                    id="privacy"
                                    name="privacy"
                                    placeholder="privacy"
                                    className="cursor-pointer"
                                    checked={candidateData.privacy}
                                    disabled={!!candidateData?.isArchive}
                                />
                            </div>
                        </div>
                        <div className="flex justify-between items-center">
                            <span>App cornerstone </span>
                            <div className="input-field-group">
                                <input
                                    type="checkbox"
                                    id="appCornerstone"
                                    name="appCornerstone"
                                    className="cursor-pointer"
                                    placeholder="appCornerstone"
                                    checked={candidateData.appCornerstone}
                                    disabled={!!candidateData?.isArchive}
                                />
                            </div>
                        </div>
                        <div>
                            <span className="font-bold">Informazioni aggiunte : </span>
                            <span>{candidateData.additionalInfo}</span>
                        </div>
                    </div>
                </div>
                <div />
            </div>
            <div className="flex-1 min-w-[304px] relative px-2 text-[8px] space-y-2 py-2">
                <section className="px-2 py-2 bg-[#E9F0F7] rounded-md">
                    <div className="flex w-full py-2 justify-between border-b-2 border-b-[#979797]">
                        <span className="text-xs font-bold">Informazioni Personali</span>
                    </div>

                    <div className="py-2 space-y-2">
                        <dl className="flex space-x-2">
                            <dt className="font-bold">Data di nascita : </dt>
                            <dd>
                                {candidateData?.generalInfo?.dob
                                    ? reformatDate(candidateData?.generalInfo?.dob)
                                    : ''}
                            </dd>
                        </dl>
                        <dl className="flex space-x-2">
                            <dt className="font-bold">Coniugato : </dt>
                            <dd>{candidateData?.generalInfo?.conjugated}</dd>
                        </dl>
                        <dl className="flex space-x-2">
                            <dt className="font-bold">Attività coniuge : </dt>
                            <dd>{candidateData?.generalInfo?.spouseActivity}</dd>
                        </dl>
                        <dl className="flex space-x-2">
                            <dt className="font-bold">N. figli : </dt>
                            <dd>{candidateData?.generalInfo?.numberOfChild}</dd>
                        </dl>
                        <dl className="flex space-x-2">
                            <dt className="font-bold">Titolo di studio : </dt>
                            <dd>{candidateData?.generalInfo?.qualification}</dd>
                        </dl>
                        <dl className="flex space-x-2">
                            <dt className="font-bold">Note personali :</dt>
                            <dd>{candidateData?.generalInfo?.personalNote}</dd>
                        </dl>
                    </div>
                </section>

                <section className="px-2 py-2 bg-[#E9F0F7] rounded-md">
                    <div className="flex w-full py-2 justify-between border-b-2 border-b-[#979797]">
                        <span className="text-xs font-bold">Informazioni Professionali</span>
                    </div>

                    <div className="py-2 space-y-2">
                        <dl className="flex space-x-2">
                            <dt className="font-bold">Banca : </dt>
                            <dd>{candidateData?.generalInfo?.bank?.name}</dd>
                        </dl>
                        <dl className="flex space-x-2">
                            <dt className="font-bold">Comune filiale : </dt>
                            <dd>{candidateData?.generalInfo?.commonBranch?.name}</dd>
                        </dl>
                        <dl className="flex space-x-2">
                            <dt className="font-bold">Indirizzo filiale : </dt>
                            <dd>{candidateData?.generalInfo?.branchAddress}</dd>
                        </dl>
                        <dl className="flex space-x-2">
                            <dt className="font-bold">Da quanti anni in banca : </dt>
                            <dd>{candidateData?.generalInfo?.yearsWorkedInBank}</dd>
                        </dl>
                        <dl className="flex space-x-2">
                            <dt className="font-bold">Da quanti anni nell’ultima filiale : </dt>
                            <dd>{candidateData?.generalInfo?.yearsWorkedInLastBranch}</dd>
                        </dl>
                        <dl className="flex space-x-2">
                            <dt className="font-bold">Ruolo professionale : </dt>
                            <dd>{candidateData?.generalInfo?.professionalRole?.name}</dd>
                        </dl>
                        <dl className="flex space-x-2">
                            <dt className="font-bold">Inquadramento : </dt>
                            <dd>{candidateData?.generalInfo?.classification}</dd>
                        </dl>
                        <dl className="flex space-x-2">
                            <dt className="font-bold">Hobby : </dt>
                            <dd>{candidateData?.generalInfo?.hobby}</dd>
                        </dl>
                        <dl className="flex space-x-2">
                            <dt className="font-bold">Ultima RAL : </dt>
                            <dd>{candidateData?.generalInfo?.lastCu}</dd>
                        </dl>
                        <dl className="flex space-x-2">
                            <dt className="font-bold">Iscrizione Albo OCF : </dt>
                            <dd>{candidateData?.generalInfo?.ocfRegister}</dd>
                        </dl>
                        <dl className="flex space-x-2">
                            <dt className="font-bold">Patto di stabilità : </dt>
                            <dd>{candidateData?.generalInfo?.stabilityPact}</dd>
                        </dl>
                        <dl className="flex space-x-2">
                            <dt className="font-bold">Patto di non concorrenza : </dt>
                            <dd>{candidateData?.generalInfo?.nonCompetitionAgreement}</dd>
                        </dl>
                        <dl className="flex space-x-2">
                            <dt className="font-bold">Portafoglio clienti : </dt>
                            <dd>{candidateData?.generalInfo?.customerPortfolio}</dd>
                        </dl>
                        <dl className="flex space-x-2">
                            <dt className="font-bold">Note professionali : </dt>
                            <dd>{candidateData?.generalInfo?.professionalNote}</dd>
                        </dl>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default ReportTemplate;
