/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCalendarDays,
    faUsers,
    faUser,
    faGear,
    faPowerOff,
    faPhone
} from '@fortawesome/free-solid-svg-icons';

import AppLogo from '../AppLogo';
import { logoutProcess } from '../../helper/utility';
import Storage from '../../helper/services/common/storage-helper-services';

const SIDE_MENU_ITEMS = [
    { label: 'Candidati', path: 'candidates', icon: faUsers },
    { label: 'Azioni', path: 'activities', icon: faCalendarDays },
    { label: 'Manager', path: 'managers', icon: faUser },
    { label: 'Segretaria', path: 'secretaries', icon: faPhone },
    { label: '', divider: true },
    { label: 'Database', path: 'database', icon: faGear },
    { label: 'Log out', action: logoutProcess, icon: faPowerOff }
];

function SideMenu({ className }) {
    const location = useLocation();

    const [menuItems, setMenuItems] = useState([]);

    const toggleDropDownHandler = (index) => {
        const tempReplica = [...menuItems];
        const selectedItem = menuItems[index];
        selectedItem.isOpen = !selectedItem.isOpen;
        tempReplica[index] = selectedItem;
        setMenuItems([...tempReplica]);
    };

    const checkIsActive = (path) => {
        return location.pathname.includes(path);
    };

    const checkNestingactive = (item) => {
        let active = false;

        for (let index = 0; index < item.length; index++) {
            const element = item[index];
            if (checkIsActive(element.path)) {
                active = true;
                break;
            }
        }
        return active;
    };

    useEffect(() => {
        const user = JSON.parse(Storage.get('profile'));
        if (user?.role !== 'admin') {
            const data = SIDE_MENU_ITEMS.filter(
                (item) =>
                    item.label !== 'Manager' &&
                    item.label !== 'Database' &&
                    item.label !== 'Segretaria'
            );
            setMenuItems([...data]);
        } else {
            setMenuItems([...SIDE_MENU_ITEMS]);
        }
    }, []);

    return (
        <aside className={`flex-shrink-0 w-64 bg-white border-r ${className}`}>
            <div className="flex flex-col h-full">
                <div className="justify-center items-center p-4 hidden md:flex ">
                    <AppLogo width="54px" />
                </div>
                <nav
                    aria-label="Main"
                    className="flex-1 p-4 space-y-2 overflow-y-hidden hover:overflow-y-auto text-grey-900 font-bold">
                    {menuItems.map((item, index) => {
                        if (item.children) {
                            return (
                                <div x-data="{ isActive: true, open: true}">
                                    <span
                                        className={`flex items-center p-2 transition-colors hover:bg-lightBlue-900 hover:text-primary-900 ${
                                            checkNestingactive(item.children)
                                                ? 'bg-primary-800 text-white'
                                                : null
                                        }`}
                                        role="button"
                                        aria-haspopup="true"
                                        onClick={() => toggleDropDownHandler(index)}>
                                        <span className="ml-2 text-sm">
                                            {' '}
                                            {item.icon ? (
                                                <FontAwesomeIcon
                                                    className="mr-2"
                                                    icon={item.icon}
                                                />
                                            ) : null}{' '}
                                            {item.label}{' '}
                                        </span>
                                        <span className="ml-auto mr-2" aria-hidden="true">
                                            <svg
                                                className={`w-4 h-4 transition-transform transform ${
                                                    item.isOpen ? 'rotate-180' : ''
                                                }`}
                                                //   :class="{ 'rotate-180': open }"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor">
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M19 9l-7 7-7-7"
                                                />
                                            </svg>
                                        </span>
                                    </span>
                                    {/* condition if open */}
                                    {item.isOpen ? (
                                        <div
                                            role="menu"
                                            className="mt-2 space-y-2 px-7"
                                            aria-label={item.label}>
                                            {item.children.map((childItem) => (
                                                <Link
                                                    key={childItem.label}
                                                    to={childItem.path}
                                                    className={`flex items-center p-2 transition-colors rounded-md hover:text-primary-800 ${
                                                        checkIsActive(childItem.path)
                                                            ? 'text-primary-800'
                                                            : null
                                                    }`}>
                                                    <span className="ml-2 text-sm">
                                                        {item.icon ? (
                                                            <FontAwesomeIcon
                                                                className="mr-2"
                                                                icon={item.icon}
                                                            />
                                                        ) : null}{' '}
                                                        {childItem.label}{' '}
                                                    </span>
                                                </Link>
                                            ))}
                                        </div>
                                    ) : null}
                                </div>
                            );
                        }
                        if (item.action) {
                            return (
                                <div className="rounded-md hover:bg-lightBlue-900 hover:text-primary-900">
                                    <span
                                        onClick={() => item.action()}
                                        role="button"
                                        aria-hidden="true"
                                        className="flex items-center px-2 py-4 transition-colors  hover:bg-primary-100 dark:hover:bg-primary">
                                        <span className="ml-2 text-sm">
                                            {item.icon ? (
                                                <FontAwesomeIcon
                                                    className="mr-2"
                                                    icon={item.icon}
                                                />
                                            ) : null}{' '}
                                            {item.label}{' '}
                                        </span>
                                    </span>
                                </div>
                            );
                        }
                        if (item.divider) {
                            return (
                                <div>
                                    <hr />
                                </div>
                            );
                        }
                        return (
                            <div
                                className={`rounded-md hover:bg-lightBlue-900 hover:text-primary-900 ${
                                    checkIsActive(item.path)
                                        ? 'bg-lightBlue-900 text-primary-900'
                                        : null
                                }`}>
                                <Link
                                    to={item.path}
                                    className="flex items-center px-2 py-4 transition-colors  hover:bg-primary-100 dark:hover:bg-primary">
                                    <span className="ml-2 text-sm">
                                        {item.icon ? (
                                            <FontAwesomeIcon className="mr-2" icon={item.icon} />
                                        ) : null}{' '}
                                        {item.label}{' '}
                                    </span>
                                </Link>
                            </div>
                        );
                    })}
                </nav>
            </div>
        </aside>
    );
}

export default SideMenu;
