/* eslint-disable no-nested-ternary */
import {
    faCircleCheck,
    faFilter,
    faFilterCircleXmark,
    faPencil,
    faTrashCan,
    faXmark
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import DataTable from '../../component/DataTable';
import Loader from '../../component/Loader';
import PrimaryButton from '../../component/PrimaryButton';
import RatingComponent from '../../component/RatingComponent';
import SearchField from '../../component/SearchField';
import AppHeader from '../../component/header/AppHeader';
import HeaderItem from '../../component/header/HeaderItem';
import ActivityListingFilters from '../../component/modal/ActivityListingFilters';
import AddActivityNote from '../../component/modal/AddActivityNote';
import ConfirmationModal from '../../component/modal/ConfirmationModal';
import CreateActivity from '../../component/modal/CreateActivity';
import { ACTIVITIES_TABLE_COLUMNS, ACTIVITY_STATUS, MESSAGES } from '../../constants';
import {
    deleteActivity,
    getActivitiesListCsvData,
    getActivitiesListData,
    updateActivityFields
} from '../../helper/services/apis/activity-services';
import Storage from '../../helper/services/common/storage-helper-services';
import {
    buildQueryChunk,
    generateAndDownloadXlsx,
    getPaginateData,
    reformatDate,
    reformatDateTime
} from '../../helper/utility';

const INIT_FILTERS = {
    managers: [],
    modes: [],
    actions: [],
    status: [],
    output: [],
    startDate: '',
    endDate: '',
    iter: null,
    onTime: null
};

const ACTIVITY_LOCAL_FILTERS = JSON.parse(Storage.get('activityFilters'));

const ACTIVITY_FILTERS = {};
Object.keys(INIT_FILTERS).forEach((key) => {
    ACTIVITY_FILTERS[key] =
        ACTIVITY_LOCAL_FILTERS && ACTIVITY_LOCAL_FILTERS[key] && ACTIVITY_LOCAL_FILTERS[key].length
            ? ACTIVITY_LOCAL_FILTERS[key]
            : INIT_FILTERS[key];
});

function Activities() {
    const [activitiesData, setActivitiesData] = useState([]);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [itersCount, setItersCount] = useState([]);
    const [completeCount, setCompleteCount] = useState([]);
    const [paginateData, setPaginateData] = useState(null);
    const [appliedFilterCount, setAppliedFilterCount] = useState(0);
    const [selectedCandidateId, setSelectedCandidateId] = useState(null);

    /**
     * Component State
     */
    const [state, setState] = useState({
        isLoading: false,
        isFiltersModalOpen: false,
        listType: 'active', // 'active'|| 'archive'
        isConfirmDeleteOpen: false,
        isCreateActivityOpen: false,
        isAddNoteOpen: false,
        confirmCancelModalOpen: false,
        confirmDeleteModalOpen: false,
        isRequiredCreateNew: false,
        isEditCompletedDate: false
    });

    const [filters, setFilters] = useState({ ...ACTIVITY_FILTERS });

    /**
     * Business Logic
     */
    const setListType = (currentState, type) => {
        const data = { ...currentState };
        data.listType = type;
        setState({ ...data });
    };

    const handleFilterChange = (type, value, currentFilters) => {
        const newFilters = { ...currentFilters };
        if (type === 'iter') {
            if (newFilters.iter === value) {
                newFilters[type] = null;
            } else {
                newFilters[type] = value;
            }
        } else if (type === 'onTime') {
            if (newFilters.onTime === value) {
                newFilters[type] = null;
            } else {
                newFilters[type] = value;
            }
        } else {
            newFilters[type] = value;
        }
        setFilters(newFilters);
    };

    const filterBtnClickHandler = (currentState) => {
        const data = { ...currentState };
        data.isFiltersModalOpen = true;
        setState({ ...data });
    };

    const closeFilterModal = (currentState, reset = false) => {
        const data = { ...currentState };
        data.isFiltersModalOpen = false;
        setState({ ...data });
        if (reset) {
            setFilters({ ...INIT_FILTERS });
        }
    };

    const onClickCreateActivityBtn = (currentState) => {
        const data = { ...currentState };
        data.isCreateActivityOpen = true;
        setState({ ...data });
    };

    const generateQueryString = (listType, currentFilters, qs) => {
        let queryString = qs;

        if (listType === 'archive') {
            queryString = buildQueryChunk(queryString, 'isArchive', true);
        }
        Object.keys(currentFilters).forEach((key) => {
            const filter = currentFilters[key];
            if (
                (key === 'iter' ||
                    key === 'searchString' ||
                    key === 'onTime' ||
                    key === 'startDate' ||
                    key === 'endDate') &&
                filter
            ) {
                queryString = buildQueryChunk(queryString, key, filter);
            } else if (filter && filter?.length) {
                const value = filter.map((item) => item.value).join(',');
                queryString = buildQueryChunk(queryString, key, value);
            }
        });

        return queryString;
    };

    const retrieveActivitiesData = async (
        currentState,
        currentFilters,
        queryString = '',
        createNote = false,
        addActivity = false
    ) => {
        const data = { ...currentState };
        data.isLoading = true;
        setState({ ...data });
        const qs = generateQueryString(currentState.listType, currentFilters, queryString);
        const result = await getActivitiesListData(qs).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
            data.isLoading = false;
            setState({ ...data });
        });

        if (result.data) {
            setActivitiesData([...result.data.data.data]);
            setItersCount([...result.data.iter]);
            setCompleteCount([...result.data.count]);
            setPaginateData({ ...getPaginateData(result.data) });
        }
        data.isAddNoteOpen = createNote;
        data.isCreateActivityOpen = addActivity;
        data.isLoading = false;
        setTimeout(() => {
            setState({ ...data });
        }, 500);
    };

    const onCloseCreateActivityModal = (currentState, currentFilters, reload = false) => {
        const data = { ...currentState };
        data.isCreateActivityOpen = false;
        setState({ ...data });
        setSelectedActivity(null);
        setSelectedCandidateId(null);
        if (reload) {
            retrieveActivitiesData(data, currentFilters);
        }
    };

    const handlePagination = (currentState, currentFilters, qs) => {
        retrieveActivitiesData(currentState, currentFilters, qs);
    };

    const onCloseAddNoteModal = (currentState, currentFilters, reload = false) => {
        const newState = { ...currentState };
        setSelectedCandidateId(selectedActivity.candidate.id);
        setSelectedActivity(false);
        const addActivity = state.isRequiredCreateNew;
        if (addActivity) {
            newState.isRequiredCreateNew = false;
        }
        newState.isAddNoteOpen = false;
        newState.isEditCompletedDate = false;
        if (reload) {
            retrieveActivitiesData(newState, currentFilters, '', false, addActivity);
        } else {
            setState({ ...newState });
        }
    };

    const updateActivityField = async (id, payload) => {
        const result = await updateActivityFields(id, { ...payload }).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
            return false;
        });
        if (result?.ok) {
            toast.success(MESSAGES.SUCCESS);
            return true;
        }
        return false;
    };

    const updateOutput = async (activity, value, currentState, currentFilters) => {
        const newState = { ...currentState };
        newState.isLoading = true;
        setState(newState);

        const result = await updateActivityField(activity.id, {
            output: value
        });
        newState.isLoading = false;
        if (result) {
            setSelectedActivity(activity);
            newState.isRequiredCreateNew = true;
            await retrieveActivitiesData(newState, currentFilters, '', true);
        }
    };

    const getEsitoCellTemplate = (item) => {
        let template = null;
        if (item.output == null && !item.candidate?.isArchive) {
            template = (
                <div className="input-field-group">
                    <select
                        onChange={(e) => updateOutput(item, e.target.value, state, filters)}
                        value="">
                        <option value="">Seleziona...</option>
                        <option value="positive">Positivo</option>
                        <option value="negative">Negativo</option>
                        <option value="unsuitable">Non idoneo</option>
                    </select>
                </div>
            );
        } else {
            template =
                // eslint-disable-next-line no-nested-ternary
                item.output === 'positive'
                    ? 'Positivo'
                    : item.output === 'negative'
                    ? 'Negativo'
                    : item.output === 'unsuitable'
                    ? 'Non idoneo'
                    : '';
        }
        return template;
    };

    const cancelActivity = async (selectedActivityId, currentState, currentFilters) => {
        const newState = { ...currentState };
        newState.isLoading = true;
        setState(newState);

        const result = await updateActivityField(selectedActivityId, {
            activityStatus: 'canceled'
        });

        newState.isLoading = false;
        if (result) {
            newState.confirmCancelModalOpen = false;
            setSelectedActivity(null);
            await retrieveActivitiesData(newState, currentFilters);
        } else {
            setState(newState);
        }
    };

    const deleteSelectedActivity = async (selectedActivityId, currentState, currentFilters) => {
        const newState = { ...currentState };
        newState.isLoading = true;
        newState.confirmDeleteModalOpen = false;
        setState(newState);

        const result = await deleteActivity(selectedActivityId).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
            newState.isLoading = false;
            setState(newState);
        });
        if (result?.ok) {
            toast.success(MESSAGES.SUCCESS);
            setSelectedActivity(null);
            await retrieveActivitiesData(newState, currentFilters);
        }
    };

    const onClickUpdateActivityNote = (activity, currentState) => {
        const newState = { ...currentState };
        newState.isAddNoteOpen = true;
        newState.isEditCompletedDate = true;
        setSelectedActivity({ ...activity });
        setState({ ...newState });
    };

    const onClickEditActivity = (activity, currentState) => {
        const newState = { ...currentState };
        newState.isCreateActivityOpen = true;
        setSelectedActivity({ ...activity });
        setState({ ...newState });
    };

    const onClickCancelActivity = (activity, currentState) => {
        const newState = { ...currentState };
        newState.confirmCancelModalOpen = true;
        setSelectedActivity({ ...activity });
        setState(newState);
    };

    const onClickDeleteActivity = (activity, currentState) => {
        const newState = { ...currentState };
        newState.confirmDeleteModalOpen = true;
        setSelectedActivity({ ...activity });
        setState(newState);
    };

    const getActionTemplate = (item, currentState) => {
        let template = null;
        if (!item.candidate?.isArchive && !item?.isBdActivity) {
            template = (
                <div className="flex justify-between text-lg">
                    {item?.output ? (
                        <FontAwesomeIcon
                            icon={faCircleCheck}
                            className="text-primary-500 cursor-pointer"
                            onClick={() => onClickUpdateActivityNote(item, currentState)}
                        />
                    ) : null}
                    <FontAwesomeIcon
                        icon={faPencil}
                        className="text-primary-500 cursor-pointer"
                        onClick={() => onClickEditActivity(item, state)}
                    />
                    <FontAwesomeIcon
                        icon={faXmark}
                        className="text-red-500 cursor-pointer"
                        onClick={() => onClickCancelActivity(item, state)}
                    />
                    <FontAwesomeIcon
                        icon={faTrashCan}
                        className="text-red-500 cursor-pointer"
                        onClick={() => onClickDeleteActivity(item, state)}
                    />
                </div>
            );
        } else if (item?.isBdActivity && item?.output) {
            template = (
                <div className="flex justify-between text-lg">
                    <FontAwesomeIcon
                        icon={faCircleCheck}
                        className="text-primary-500 cursor-pointer"
                        onClick={() => onClickUpdateActivityNote(item, currentState)}
                    />
                </div>
            );
        }
        return template;
    };

    const getRows = (data, currentState) => {
        return data.map((item) => ({
            className: item.expiredEmailSent && !item.completedDate ? 'bg-red-300' : null,
            cells: [
                {
                    template: (
                        <Link
                            to={`../candidate/${item.candidate.id}`}
                            className="text-primary-800"
                            title={`${item.candidate?.fullName}`}>
                            {item?.candidate?.fullName}
                        </Link>
                    ),
                    className: 'min-w-[128px] font-bold'
                },
                {
                    template: item?.createdByUser?.fullName
                        ? `${item?.createdByUser?.fullName}`
                        : '',
                    className: 'min-w-[128px] font-bold'
                },
                {
                    template: reformatDateTime(item.expireDate),
                    className: 'min-w-[128px]'
                },
                {
                    template: item.completedDate ? reformatDate(item.completedDate) : '',
                    className: 'min-w-[128px]'
                },
                {
                    template: item?.isBdActivity ? 'Compleanno' : item?.iter?.name,
                    className: 'min-w-[128px]'
                },
                {
                    template: `${item?.manager?.fullName}`,
                    className: 'min-w-[128px] font-bold'
                },
                {
                    template: item?.activityWhere,
                    className: 'min-w-[128px]'
                },
                {
                    template: `${item?.performingManager?.fullName}`,
                    className: 'min-w-[128px]'
                },
                { template: getEsitoCellTemplate(item), className: 'min-w-[150px]' },
                {
                    template: (
                        <RatingComponent
                            readOnly
                            name="rating"
                            config={{ required: true }}
                            defaultValue={item.rating}
                        />
                    ),
                    className: 'min-w-[128px]'
                },
                {
                    template: (
                        <span
                            className="px-4 py-2 rounded-sm"
                            style={{
                                background: ACTIVITY_STATUS[item.activityStatus].color,
                                color: ACTIVITY_STATUS[item.activityStatus].text
                            }}>
                            {ACTIVITY_STATUS[item.activityStatus].label}
                        </span>
                    ),
                    className: 'min-w-[150px] font-bold'
                },
                { template: getActionTemplate(item, currentState), className: 'min-w-[128px]' }
            ]
        }));
    };

    const setFilterCountAndSaveInLocal = (currentFilters) => {
        let count = 0;
        if (currentFilters) {
            Object.keys(currentFilters).forEach((key) => {
                const item = currentFilters[key];
                if (item && item.length > 0) {
                    count += 1;
                }
            });
        }
        setAppliedFilterCount(count);
        Storage.set('activityFilters', JSON.stringify(currentFilters));
    };

    const downloadCsvData = async (currentState, currentFilters) => {
        const data = { ...currentState };
        data.isLoading = true;
        setState({ ...data });

        const qs = generateQueryString(currentState.listType, currentFilters);
        const result = await getActivitiesListCsvData(qs).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
            data.isLoading = false;
            setState({ ...data });
            return false;
        });

        if (result.data) {
            data.isLoading = false;
            setState({ ...data });
            return result.data;
        }
        return [];
    };

    const downloadCsv = async (currentState, currentFilters) => {
        const data = await downloadCsvData(currentState, currentFilters);
        const columns = [
            { header: 'Candidato', field: 'createdAt' },
            { header: 'Inserita da', field: 'createdByUser' },
            { header: 'Quando', field: 'expireDate' },
            { header: 'Completata il', field: 'completedDate' },
            { header: 'Fase', field: 'iter' },
            { header: 'Chi svolge l’attività', field: 'performingManager' },
            { header: 'Dove', field: 'activityWhere' },
            { header: 'Con chi', field: 'manager' },
            { header: 'Esito', field: 'output' },
            { header: 'Rating', field: 'rating' },
            { header: 'Stato attività', field: 'activityStatus' }
        ];
        const finalData = data.map((item) => {
            return {
                createdAt: item?.candidate?.fullName,
                createdByUser: item?.createdByUser?.fullName,
                expireDate: item.expireDate ? reformatDateTime(item.expireDate) : '',
                completedDate: item.completedDate ? reformatDate(item.completedDate) : '',
                iter: item?.iter?.name,
                performingManager: item?.performingManager?.fullName,
                activityWhere: item?.activityWhere,
                manager: item?.manager?.fullName,
                output:
                    item.output === 'positive'
                        ? 'Positivo'
                        : item.output === 'negative'
                        ? 'Negativo'
                        : item.output === 'unsuitable'
                        ? 'Non idoneo'
                        : '',
                rating: item.rating,
                activityStatus: ACTIVITY_STATUS[item.activityStatus].label
            };
        });

        const selectedColumns = columns.map((item) => ({
            label: item.header,
            value: item.field
        }));
        generateAndDownloadXlsx(selectedColumns, finalData, 'Azioni');
    };
    /**
     * Watchers
     */
    useEffect(() => {
        setFilterCountAndSaveInLocal(filters);
        retrieveActivitiesData(state, filters);
    }, [state.listType, filters]);

    useEffect(() => {
        if (selectedActivity) {
            const activity = activitiesData.find((a) => a.id === selectedActivity.id);
            if (activity) {
                setSelectedActivity({ ...activity });
            }
        }
    }, [activitiesData]);

    /**
     * Template
     */
    return (
        <>
            {state.isLoading ? <Loader /> : null}

            <ActivityListingFilters
                isOpen={state.isFiltersModalOpen}
                handleFilterChange={handleFilterChange}
                filters={filters}
                closeModel={(reset) => closeFilterModal(state, reset)}
            />

            {state.isCreateActivityOpen ? (
                <CreateActivity
                    isOpen={state.isCreateActivityOpen}
                    selectedActivity={selectedActivity}
                    closeModel={(reload) => onCloseCreateActivityModal(state, filters, reload)}
                    candidateId={selectedCandidateId}
                />
            ) : null}
            {state.isAddNoteOpen && (
                <AddActivityNote
                    isOpen={state.isAddNoteOpen}
                    selectedActivity={selectedActivity}
                    closeModel={(reload) => onCloseAddNoteModal(state, filters, reload)}
                    isEditMode={state.isEditCompletedDate}
                />
            )}

            {state.confirmCancelModalOpen ? (
                <ConfirmationModal
                    isOpen={state.confirmCancelModalOpen}
                    title="Annulla azione"
                    message="Sei sicuro di voler annullare l’attività selezionata? 
                Una volta annullata non sarà più possibile completarla.
                Continuare?"
                    onConfirmAction={() => cancelActivity(selectedActivity.id, state, filters)}
                    closeModel={() => setState({ ...state, confirmCancelModalOpen: false })}
                    cancelBtnText="INDIETRO"
                    confirmBtnText="ANNULLA"
                />
            ) : null}

            {state.confirmDeleteModalOpen ? (
                <ConfirmationModal
                    isOpen={state.confirmDeleteModalOpen}
                    title="Elimina azione"
                    message="Vuoi eliminare l'azione selezionata?"
                    onConfirmAction={() =>
                        deleteSelectedActivity(selectedActivity.id, state, filters)
                    }
                    closeModel={() => setState({ ...state, confirmDeleteModalOpen: false })}
                    confirmBtnText="ELIMINA"
                />
            ) : null}

            <section className="space-y-2">
                <AppHeader>
                    <HeaderItem>
                        <span
                            className={`cursor-pointer font-bold text-2xl ${
                                state.listType === 'active' ? 'text-grey-900' : 'text-grey-600'
                            }`}
                            onClick={() => setListType(state, 'active')}
                            aria-hidden="true">
                            {paginateData?.total ? paginateData?.total : null} Azioni
                        </span>
                        <span className="text-2xl">|</span>
                        <span
                            className={`cursor-pointer font-bold text-2xl ${
                                state.listType === 'archive' ? 'text-grey-900' : 'text-grey-600'
                            }`}
                            onClick={() => setListType(state, 'archive')}
                            aria-hidden="true">
                            Archivio
                        </span>
                    </HeaderItem>
                    <HeaderItem className="">
                        <PrimaryButton onClick={() => filterBtnClickHandler(state)}>
                            <FontAwesomeIcon
                                icon={appliedFilterCount ? faFilterCircleXmark : faFilter}
                            />
                        </PrimaryButton>
                        <PrimaryButton onClick={() => downloadCsv(state, filters)}>
                            XLS
                        </PrimaryButton>
                    </HeaderItem>
                </AppHeader>

                <AppHeader>
                    <HeaderItem />
                    <HeaderItem className="md:ml-auto" />
                </AppHeader>

                <AppHeader>
                    <HeaderItem>
                        {completeCount.map((item) => (
                            <span
                                key={item.name}
                                aria-hidden="true"
                                className={`px-4 py-1 bg-white rounded-lg text-[13px] cursor-pointer ${
                                    filters?.onTime &&
                                    filters?.onTime === item.id &&
                                    'bg-primary-500 text-white'
                                }`}
                                onClick={() => handleFilterChange('onTime', item.id, filters)}>
                                <b>{item?.count}</b> {item?.name}
                            </span>
                        ))}
                    </HeaderItem>
                    <HeaderItem />
                </AppHeader>
                <AppHeader>
                    <HeaderItem>
                        {itersCount.map((iter) => (
                            <span
                                key={iter.id}
                                aria-hidden="true"
                                className={`px-4 py-1 bg-white rounded-lg text-[13px] cursor-pointer ${
                                    filters?.iter &&
                                    filters?.iter === iter.id &&
                                    'bg-primary-500 text-white'
                                }`}
                                onClick={() => handleFilterChange('iter', iter.id, filters)}>
                                <b>{iter.activitiesCount}</b> {iter.name}
                            </span>
                        ))}
                    </HeaderItem>
                    <HeaderItem />
                </AppHeader>
                <AppHeader>
                    <HeaderItem className="w-80">
                        <SearchField
                            defaultValue={filters?.searchString}
                            onPressEnter={(e) => handleFilterChange('searchString', e, filters)}
                        />
                    </HeaderItem>
                    <HeaderItem>
                        <PrimaryButton onClick={() => onClickCreateActivityBtn(state)}>
                            AGGIUNGI
                        </PrimaryButton>
                    </HeaderItem>
                </AppHeader>

                <DataTable
                    columns={ACTIVITIES_TABLE_COLUMNS}
                    rows={getRows(activitiesData, state)}
                    paginateData={paginateData}
                    handlePagination={(qs) => handlePagination(state, filters, qs)}
                />
            </section>
        </>
    );
}

export default Activities;
