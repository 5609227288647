/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-key */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { faCheck, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DataTable from '../../component/DataTable';
import Loader from '../../component/Loader';
import PrimaryButton from '../../component/PrimaryButton';
import SearchField from '../../component/SearchField';
// import TreeDataGride from '../../component/TreeDataGride';
import TreeDataGride from '../../component/TreeDataGride';
import AppHeader from '../../component/header/AppHeader';
import HeaderItem from '../../component/header/HeaderItem';
import ConfirmationModal from '../../component/modal/ConfirmationModal';
import CreateManager from '../../component/modal/CreateManager';
import ModalComponent from '../../component/modal/ModalComponent';
import {
    MESSAGES,
    USERS_DATABASE_TABLE_COLUMNS_TREE,
    USERS_REVENUE_TABLE_COLUMNS_TREE,
    USERS_TABLE_COLUMNS
} from '../../constants';
import { getRolesDropdown } from '../../helper/services/apis/common-services';
import {
    deleteUser,
    getManagerCognativeCcandidates,
    getUsersDatabase,
    getUsersListData,
    getUsersRevenueData,
    updateUserField
} from '../../helper/services/apis/user-services';
import {
    buildQueryChunk,
    checkPagePermission,
    generateAndDownloadXlsx,
    getCurrentYear
} from '../../helper/utility';

function Managers() {
    const navigate = useNavigate();
    const currentYear = getCurrentYear();
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [managersData, setManagersData] = useState([]);
    const [roles, setRoles] = useState([]);
    const [years, setYears] = useState([]);
    const [managerCognativeCandidate, setManagerCognativeCandidate] = useState([]);
    const [managerRevenueSearchString, setManagerRevenueSearchString] = useState('');
    // const [expandedRows, setExpandedRows] = useState([]);

    const [filters, setFilters] = useState({
        year: currentYear
    });

    const [frontFilters, setFrontFilters] = useState({
        searchString: '',
        role: ''
    });

    const [state, setState] = useState({
        isLoading: false,
        isUserCandidateOpen: false,
        isConfirmDeleteOpen: false,
        currentTab: 0, // 0,1,2
        isCurrentYear: true,
        selectedManagerId: null
    });

    const initYears = async () => {
        const y = [];
        for (let index = 2022; index <= currentYear; index++) {
            y.push({ id: index, name: index });
        }
        setYears(
            y
                .concat([
                    { id: 'month-6', name: 'Ultimi 6 mesi' },
                    { id: 'month-3', name: 'Ultimi 3 mesi' },
                    { id: 'month-2', name: 'Ultimi 2 mesi' },
                    { id: 'month-1', name: 'Lo scorso mese' },
                    { id: 'day-14', name: 'Ultimi 14 giorni' },
                    { id: 'day-7', name: 'Ultimi 7 giorni' }
                ])
                .reverse()
        );
    };

    const retrieveRoles = async () => {
        const result = await getRolesDropdown().catch((e) => console.log(e.message));

        if (result.data) {
            setRoles([...result.data]);
        }
    };

    const setCurrentTab = (currentState, index) => {
        const data = { ...currentState };
        data.currentTab = index;
        setManagersData(null);
        setState({ ...data });
    };

    const generateManagerRevenueObjects = (data) => {
        // const userData = [];
        // const total = {
        //     database: 0,
        //     conoscitivo: 0,
        //     informativo: 0,
        //     progetto: 0,
        //     decisionale: 0,
        //     esame: 0,
        //     codici: 0,
        //     total: 0,
        //     daRichiamare: 0,
        //     nonIdoneo: 0
        // };

        if (data?.supervisorFour) {
            data.supervisorFour = data.supervisorFour.map((item) => ({
                id: item.id,
                fullName: `${item.fullName}`,
                database: item.candidatesCount,
                conoscitivo: item.candidateInConoscitivo,
                informativo: item.candidateInInformative,
                progetto: item.candidateInPlanning,
                decisionale: item.candidateInContract,
                esame: item.candidateInExamination,
                codici: item.candidateInInserito,
                total:
                    item.candidateInInformative +
                    item.candidateInPlanning +
                    item.candidateInContract +
                    item.candidateInExamination,
                daRichiamare: item.candidateInDaRichiamare,
                nonIdoneo: item.candidateInNonIdoneo,
                managerId: item.managerId
            }));
        } else {
            data.supervisorFour = [];
        }
        if (data?.supervisorThree) {
            data.supervisorThree = data.supervisorThree.map((item) => ({
                id: item.id,
                fullName: `${item.fullName}`,
                database: item.candidatesCount,
                conoscitivo: item.candidateInConoscitivo,
                informativo: item.candidateInInformative,
                progetto: item.candidateInPlanning,
                decisionale: item.candidateInContract,
                esame: item.candidateInExamination,
                codici: item.candidateInInserito,
                total:
                    item.candidateInInformative +
                    item.candidateInPlanning +
                    item.candidateInContract +
                    item.candidateInExamination,
                daRichiamare: item.candidateInDaRichiamare,
                nonIdoneo: item.candidateInNonIdoneo,
                managerId: item.managerId
            }));
        } else {
            data.supervisorThree = [];
        }
        if (data?.supervisorTwo) {
            data.supervisorTwo = data.supervisorTwo.map((item) => ({
                id: item.id,
                fullName: `${item.fullName}`,
                database: item.candidatesCount,
                conoscitivo: item.candidateInConoscitivo,
                informativo: item.candidateInInformative,
                progetto: item.candidateInPlanning,
                decisionale: item.candidateInContract,
                esame: item.candidateInExamination,
                codici: item.candidateInInserito,
                total:
                    item.candidateInInformative +
                    item.candidateInPlanning +
                    item.candidateInContract +
                    item.candidateInExamination,
                daRichiamare: item.candidateInDaRichiamare,
                nonIdoneo: item.candidateInNonIdoneo,
                managerId: item.managerId
            }));
        } else {
            data.supervisorTwo = [];
        }
        if (data?.supervisorOne) {
            data.supervisorOne = data?.supervisorOne?.map((item) => ({
                id: item.id,
                fullName: `${item.fullName}`,
                database: item.candidatesCount,
                conoscitivo: item.candidateInConoscitivo,
                informativo: item.candidateInInformative,
                progetto: item.candidateInPlanning,
                decisionale: item.candidateInContract,
                esame: item.candidateInExamination,
                codici: item.candidateInInserito,
                total:
                    item.candidateInInformative +
                    item.candidateInPlanning +
                    item.candidateInContract +
                    item.candidateInExamination,
                daRichiamare: item.candidateInDaRichiamare,
                nonIdoneo: item.candidateInNonIdoneo,
                managerId: item.managerId
            }));
        } else {
            data.supervisorOne = [];
        }
        if (data?.direct) {
            data.direct = data.direct.map((item) => ({
                id: item.id,
                fullName: `${item.fullName}`,
                database: item.candidatesCount,
                conoscitivo: item.candidateInConoscitivo,
                informativo: item.candidateInInformative,
                progetto: item.candidateInPlanning,
                decisionale: item.candidateInContract,
                esame: item.candidateInExamination,
                codici: item.candidateInInserito,
                total:
                    item.candidateInInformative +
                    item.candidateInPlanning +
                    item.candidateInContract +
                    item.candidateInExamination,
                daRichiamare: item.candidateInDaRichiamare,
                nonIdoneo: item.candidateInNonIdoneo,
                managerId: item.managerId
            }));
        } else {
            data.direct = [];
        }
        data.admin = data.admin.map((item) => ({
            id: item.id,
            fullName: `${item.fullName}Admin`,
            database: item.candidatesCount,
            conoscitivo: item.candidateInConoscitivo,
            informativo: item.candidateInInformative,
            progetto: item.candidateInPlanning,
            decisionale: item.candidateInContract,
            esame: item.candidateInExamination,
            codici: item.candidateInInserito,
            total:
                item.candidateInInformative +
                item.candidateInPlanning +
                item.candidateInContract +
                item.candidateInExamination,
            daRichiamare: item.candidateInDaRichiamare,
            nonIdoneo: item.candidateInNonIdoneo,
            managerId: item.managerId
        }));

        data.supervisorThree.forEach((item) => {
            item.subManagers = data?.supervisorFour?.filter(
                (manager) => item.id === manager.managerId
            );
            item.subManagersTotal = {
                fullName: item.fullName,
                id: item.id,
                database: 0,
                conoscitivo: 0,
                informativo: 0,
                progetto: 0,
                decisionale: 0,
                esame: 0,
                codici: 0,
                total: 0,
                daRichiamare: 0,
                nonIdoneo: 0
            };

            if (item.subManagers?.length && item.subManagers?.length > 0) {
                item.subManagers.forEach((element) => {
                    item.subManagersTotal.database += element.database;
                    item.subManagersTotal.conoscitivo += element.conoscitivo;
                    item.subManagersTotal.informativo += element.informativo;
                    item.subManagersTotal.progetto += element.progetto;
                    item.subManagersTotal.decisionale += element.decisionale;
                    item.subManagersTotal.esame += element.esame;
                    item.subManagersTotal.codici += element.codici;
                    item.subManagersTotal.total += element.total;
                    item.subManagersTotal.daRichiamare += element.daRichiamare;
                    item.subManagersTotal.nonIdoneo += element.nonIdoneo;
                });
            }
        });
        data.supervisorTwo.forEach((item) => {
            item.subManagers = data?.supervisorThree?.filter(
                (manager) => item.id === manager.managerId
            );
            item.subManagersTotal = {
                fullName: item.fullName,
                id: item.id,
                database: 0,
                conoscitivo: 0,
                informativo: 0,
                progetto: 0,
                decisionale: 0,
                esame: 0,
                codici: 0,
                total: 0,
                daRichiamare: 0,
                nonIdoneo: 0
            };

            if (item.subManagers?.length && item.subManagers?.length > 0) {
                item.subManagers.forEach((element) => {
                    item.subManagersTotal.database +=
                        element.database + element.subManagersTotal.database;
                    item.subManagersTotal.conoscitivo +=
                        element.conoscitivo + element.subManagersTotal.conoscitivo;
                    item.subManagersTotal.informativo +=
                        element.informativo + element.subManagersTotal.informativo;
                    item.subManagersTotal.progetto +=
                        element.progetto + element.subManagersTotal.progetto;
                    item.subManagersTotal.decisionale +=
                        element.decisionale + element.subManagersTotal.decisionale;
                    item.subManagersTotal.esame += element.esame + element.subManagersTotal.esame;
                    item.subManagersTotal.codici +=
                        element.codici + element.subManagersTotal.codici;
                    item.subManagersTotal.total += element.total + element.subManagersTotal.total;
                    item.subManagersTotal.daRichiamare +=
                        element.daRichiamare + element.subManagersTotal.daRichiamare;
                    item.subManagersTotal.nonIdoneo +=
                        element.nonIdoneo + element.subManagersTotal.nonIdoneo;
                });
            }
        });
        data.supervisorOne.forEach((item) => {
            item.subManagers = data?.supervisorTwo?.filter(
                (manager) => item.id === manager.managerId
            );
            item.subManagersTotal = {
                fullName: item.fullName,
                id: item.id,
                database: 0,
                conoscitivo: 0,
                informativo: 0,
                progetto: 0,
                decisionale: 0,
                esame: 0,
                codici: 0,
                total: 0,
                daRichiamare: 0,
                nonIdoneo: 0
            };

            if (item.subManagers?.length && item.subManagers?.length > 0) {
                item.subManagers.forEach((element) => {
                    item.subManagersTotal.database +=
                        element.database + element.subManagersTotal.database;
                    item.subManagersTotal.conoscitivo +=
                        element.conoscitivo + element.subManagersTotal.conoscitivo;
                    item.subManagersTotal.informativo +=
                        element.informativo + element.subManagersTotal.informativo;
                    item.subManagersTotal.progetto +=
                        element.progetto + element.subManagersTotal.progetto;
                    item.subManagersTotal.decisionale +=
                        element.decisionale + element.subManagersTotal.decisionale;
                    item.subManagersTotal.esame += element.esame + element.subManagersTotal.esame;
                    item.subManagersTotal.codici +=
                        element.codici + element.subManagersTotal.codici;
                    item.subManagersTotal.total += element.total + element.subManagersTotal.total;
                    item.subManagersTotal.daRichiamare +=
                        element.daRichiamare + element.subManagersTotal.daRichiamare;
                    item.subManagersTotal.nonIdoneo +=
                        element.nonIdoneo + element.subManagersTotal.nonIdoneo;
                });
            }
        });
        data.direct.forEach((item) => {
            item.subManagers = data.supervisorOne.filter(
                (manager) => item.id === manager.managerId
            );

            item.subManagersTotal = {
                fullName: item.fullName,
                id: item.id,
                database: 0,
                conoscitivo: 0,
                informativo: 0,
                progetto: 0,
                decisionale: 0,
                esame: 0,
                codici: 0,
                total: 0,
                daRichiamare: 0,
                nonIdoneo: 0
            };

            if (item.subManagers?.length && item.subManagers?.length > 0) {
                item.subManagers.forEach((element) => {
                    item.subManagersTotal.database +=
                        element.database + element.subManagersTotal.database;
                    item.subManagersTotal.conoscitivo +=
                        element.conoscitivo + element.subManagersTotal.conoscitivo;
                    item.subManagersTotal.informativo +=
                        element.informativo + element.subManagersTotal.informativo;
                    item.subManagersTotal.progetto +=
                        element.progetto + element.subManagersTotal.progetto;
                    item.subManagersTotal.decisionale +=
                        element.decisionale + element.subManagersTotal.decisionale;
                    item.subManagersTotal.esame += element.esame + element.subManagersTotal.esame;
                    item.subManagersTotal.codici +=
                        element.codici + element.subManagersTotal.codici;
                    item.subManagersTotal.total += element.total + element.subManagersTotal.total;
                    item.subManagersTotal.daRichiamare +=
                        element.daRichiamare + element.subManagersTotal.daRichiamare;
                    item.subManagersTotal.nonIdoneo +=
                        element.nonIdoneo + element.subManagersTotal.nonIdoneo;
                });
            }
        });
        data.admin.forEach((item) => {
            item.subManagers = data.direct.filter((manager) => item.id === manager.managerId);
            item.subManagersTotal = {
                fullName: item.fullName,
                id: item.id,
                database: 0,
                conoscitivo: 0,
                informativo: 0,
                progetto: 0,
                decisionale: 0,
                esame: 0,
                codici: 0,
                total: 0,
                daRichiamare: 0,
                nonIdoneo: 0
            };

            if (item.subManagers?.length && item.subManagers?.length > 0) {
                item.subManagers.forEach((element) => {
                    item.subManagersTotal.database +=
                        element.database + element.subManagersTotal.database;
                    item.subManagersTotal.conoscitivo +=
                        element.conoscitivo + element.subManagersTotal.conoscitivo;
                    item.subManagersTotal.informativo +=
                        element.informativo + element.subManagersTotal.informativo;
                    item.subManagersTotal.progetto +=
                        element.progetto + element.subManagersTotal.progetto;
                    item.subManagersTotal.decisionale +=
                        element.decisionale + element.subManagersTotal.decisionale;
                    item.subManagersTotal.esame += element.esame + element.subManagersTotal.esame;
                    item.subManagersTotal.codici +=
                        element.codici + element.subManagersTotal.codici;
                    item.subManagersTotal.total += element.total + element.subManagersTotal.total;
                    item.subManagersTotal.daRichiamare +=
                        element.daRichiamare + element.subManagersTotal.daRichiamare;
                    item.subManagersTotal.nonIdoneo +=
                        element.nonIdoneo + element.subManagersTotal.nonIdoneo;
                });
            }
        });

        // data.admin.forEach((admin) => {
        //     admin.subManagersTotal.database += admin.database;
        //     admin.subManagersTotal.conoscitivo += admin.conoscitivo;
        //     admin.subManagersTotal.informativo += admin.informativo;
        //     admin.subManagersTotal.progetto += admin.progetto;
        //     admin.subManagersTotal.decisionale += admin.decisionale;
        //     admin.subManagersTotal.esame += admin.esame;
        //     admin.subManagersTotal.codici += admin.codici;
        //     admin.subManagersTotal.total += admin.total;
        //     admin.subManagersTotal.daRichiamare += admin.daRichiamare;
        //     admin.subManagersTotal.nonIdoneo += admin.nonIdoneo;
        //     admin.isSubManager = admin.subManagers.length > 0;

        //     userData.push({
        //         ...admin.subManagersTotal,
        //         managerId: admin.id,
        //         isTotal: true,
        //         isAdmin: true,
        //         isSubManager: admin.subManagers.length > 0
        //     });
        //     if (admin.subManagers.length > 0) {
        //         userData.push({
        //             id: admin.id,
        //             fullName: admin.fullName,
        //             database: admin.database,
        //             conoscitivo: admin.conoscitivo,
        //             informativo: admin.informativo,
        //             progetto: admin.progetto,
        //             decisionale: admin.decisionale,
        //             esame: admin.esame,
        //             codici: admin.codici,
        //             total: admin.total,
        //             daRichiamare: admin.daRichiamare,
        //             nonIdoneo: admin.nonIdoneo,
        //             managerId: admin.managerId,
        //             userItSelf: true,
        //             level: [1]
        //         });

        //         admin.subManagers.forEach((dir) => {
        //             dir.subManagersTotal.database += dir.database;
        //             dir.subManagersTotal.conoscitivo += dir.conoscitivo;
        //             dir.subManagersTotal.informativo += dir.informativo;
        //             dir.subManagersTotal.progetto += dir.progetto;
        //             dir.subManagersTotal.decisionale += dir.decisionale;
        //             dir.subManagersTotal.esame += dir.esame;
        //             dir.subManagersTotal.codici += dir.codici;
        //             dir.subManagersTotal.total += dir.total;
        //             dir.subManagersTotal.daRichiamare += dir.daRichiamare;
        //             dir.subManagersTotal.nonIdoneo += dir.nonIdoneo;
        //             // dir.isSubManager = dir?.subManagers.length > 0;

        //             userData.push({
        //                 ...dir.subManagersTotal,
        //                 managerId: admin.id,
        //                 isTotal: true,
        //                 isSubManager: dir?.subManagers.length > 0
        //             });

        //             if (dir?.subManagers.length > 0) {
        //                 userData.push({
        //                     id: dir.id,
        //                     fullName: dir.fullName,
        //                     database: dir.database,
        //                     conoscitivo: dir.conoscitivo,
        //                     informativo: dir.informativo,
        //                     progetto: dir.progetto,
        //                     decisionale: dir.decisionale,
        //                     esame: dir.esame,
        //                     codici: dir.codici,
        //                     total: dir.total,
        //                     daRichiamare: dir.daRichiamare,
        //                     nonIdoneo: dir.nonIdoneo,
        //                     managerId: dir.managerId,
        //                     userItSelf: true,
        //                     level: [1, 2]
        //                 });

        //                 dir?.subManagers?.forEach((supOne) => {
        //                     supOne.subManagersTotal.database += supOne.database;
        //                     supOne.subManagersTotal.conoscitivo += supOne.conoscitivo;
        //                     supOne.subManagersTotal.informativo += supOne.informativo;
        //                     supOne.subManagersTotal.progetto += supOne.progetto;
        //                     supOne.subManagersTotal.decisionale += supOne.decisionale;
        //                     supOne.subManagersTotal.esame += supOne.esame;
        //                     supOne.subManagersTotal.codici += supOne.codici;
        //                     supOne.subManagersTotal.total += supOne.total;
        //                     supOne.subManagersTotal.daRichiamare += supOne.daRichiamare;
        //                     supOne.subManagersTotal.nonIdoneo += supOne.nonIdoneo;
        //                     supOne.isSubManager = supOne.subManagers.length > 0;

        //                     userData.push({
        //                         ...supOne.subManagersTotal,
        //                         managerId: dir.id,
        //                         isTotal: true,
        //                         isSubManager: supOne.subManagers.length > 0
        //                     });
        //                     if (supOne.subManagers.length > 0) {
        //                         userData.push({
        //                             id: supOne.id,
        //                             fullName: supOne.fullName,
        //                             database: supOne.database,
        //                             conoscitivo: supOne.conoscitivo,
        //                             informativo: supOne.informativo,
        //                             progetto: supOne.progetto,
        //                             decisionale: supOne.decisionale,
        //                             esame: supOne.esame,
        //                             codici: supOne.codici,
        //                             total: supOne.total,
        //                             daRichiamare: supOne.daRichiamare,
        //                             nonIdoneo: supOne.nonIdoneo,
        //                             managerId: supOne.managerId,
        //                             userItSelf: true,
        //                             level: [1, 2, 3]
        //                         });

        //                         supOne.subManagers.forEach((supTwo) => {
        //                             userData.push({
        //                                 id: supTwo.id,
        //                                 fullName: supTwo.fullName,
        //                                 database: supTwo.database,
        //                                 conoscitivo: supTwo.conoscitivo,
        //                                 informativo: supTwo.informativo,
        //                                 progetto: supTwo.progetto,
        //                                 decisionale: supTwo.decisionale,
        //                                 esame: supTwo.esame,
        //                                 codici: supTwo.codici,
        //                                 total: supTwo.total,
        //                                 daRichiamare: supTwo.daRichiamare,
        //                                 nonIdoneo: supTwo.nonIdoneo,
        //                                 managerId: supTwo.managerId,
        //                                 // userItSelf: true,
        //                                 level: [1, 2, 3, 4]
        //                             });
        //                         });
        //                     }
        //                 });
        //             }
        //         });
        //     }

        //     total.database = admin.subManagersTotal.database;
        //     total.conoscitivo = admin.subManagersTotal.conoscitivo;
        //     total.informativo = admin.subManagersTotal.informativo;
        //     total.progetto = admin.subManagersTotal.progetto;
        //     total.decisionale = admin.subManagersTotal.decisionale;
        //     total.esame = admin.subManagersTotal.esame;
        //     total.codici = admin.subManagersTotal.codici;
        //     total.total = admin.subManagersTotal.total;
        //     total.daRichiamare = admin.subManagersTotal.daRichiamare;
        //     total.nonIdoneo = admin.subManagersTotal.nonIdoneo;
        // });
        // -----Working V1 Start-------
        // data.forEach((item) => {
        //     const user = {};
        //     const adminTotal = {
        //         database: 0,
        //         conoscitivo: 0,
        //         informativo: 0,
        //         progetto: 0,
        //         decisionale: 0,
        //         esame: 0,
        //         codici: 0,
        //         total: 0,
        //         daRichiamare: 0,
        //         nonIdoneo: 0
        //     };
        //     // Admin
        //     user.id = item.id;
        //     user.fullName = `${item.fullName}Admin`;
        //     user.database = item.candidatesCount;
        //     user.conoscitivo = item.candidateInConoscitivo;
        //     user.informativo = item.candidateInInformative;
        //     user.progetto = item.candidateInPlanning;
        //     user.decisionale = item.candidateInContract;
        //     user.esame = item.candidateInExamination;
        //     user.codici = item.candidateInInserito;
        //     user.total = user.informativo + user.progetto + user.decisionale + user.esame;
        //     user.daRichiamare = item.candidateInDaRichiamare;
        //     user.nonIdoneo = item.candidateInNonIdoneo;
        //     // user.isSub = item.subManagers.length > 0;
        //     // user.level = '0px';

        //     userData.push({ ...user });
        //     // userData.unshift(total);
        //     // if (user.isSub) {
        //     //     item.subManagers.forEach((dir) => {
        //     //         const dr = {};
        //     //         const drTotal = {
        //     //             database: 0,
        //     //             conoscitivo: 0,
        //     //             informativo: 0,
        //     //             progetto: 0,
        //     //             decisionale: 0,
        //     //             esame: 0,
        //     //             codici: 0,
        //     //             total: 0,
        //     //             daRichiamare: 0,
        //     //             nonIdoneo: 0
        //     //         };
        //     //         dr.id = dir.id;
        //     //         dr.fullName = `${dir.fullName}Dir`;
        //     //         dr.database = dir.candidatesCount;
        //     //         dr.conoscitivo = dir.candidateInConoscitivo;
        //     //         dr.informativo = dir.candidateInInformative;
        //     //         dr.progetto = dir.candidateInPlanning;
        //     //         dr.decisionale = dir.candidateInContract;
        //     //         dr.esame = dir.candidateInExamination;
        //     //         dr.codici = dir.candidateInInserito;
        //     //         dr.total = dr.informativo + dr.progetto + dr.decisionale + dr.esame;
        //     //         dr.daRichiamare = dir.candidateInDaRichiamare;
        //     //         dr.nonIdoneo = dir.candidateInNonIdoneo;
        //     //         dr.isSub = dir.subManagers.length > 0;
        //     //         dr.level = '4px';

        //     //         userData.push({ ...dr });
        //     //         adminTotal.id = dr.id;
        //     //         adminTotal.fullName = `${dir.fullName}TOTAL`;
        //     //         adminTotal.database += dr.database;
        //     //         adminTotal.conoscitivo += dr.conoscitivo;
        //     //         adminTotal.informativo += dr.informativo;
        //     //         adminTotal.progetto += dr.progetto;
        //     //         adminTotal.decisionale += dr.decisionale;
        //     //         adminTotal.esame += dr.esame;
        //     //         adminTotal.codici += dr.codici;
        //     //         adminTotal.total += dr.total;
        //     //         adminTotal.daRichiamare += dr.daRichiamare;
        //     //         adminTotal.nonIdoneo += dr.nonIdoneo;
        //     //         adminTotal.level = '4px';

        //     //         if (dr.isSub) {
        //     //             dir.subManagers.forEach((spOne) => {
        //     //                 const spO = {};
        //     //                 const spOTotal = {
        //     //                     database: 0,
        //     //                     conoscitivo: 0,
        //     //                     informativo: 0,
        //     //                     progetto: 0,
        //     //                     decisionale: 0,
        //     //                     esame: 0,
        //     //                     codici: 0,
        //     //                     total: 0,
        //     //                     daRichiamare: 0,
        //     //                     nonIdoneo: 0
        //     //                 };
        //     //                 spO.id = dir.id;
        //     //                 spO.fullName = `${spOne.fullName}SP1`;
        //     //                 spO.database = spOne.candidatesCount;
        //     //                 spO.conoscitivo = spOne.candidateInConoscitivo;
        //     //                 spO.informativo = spOne.candidateInInformative;
        //     //                 spO.progetto = spOne.candidateInPlanning;
        //     //                 spO.decisionale = spOne.candidateInContract;
        //     //                 spO.esame = spOne.candidateInExamination;
        //     //                 spO.codici = spOne.candidateInInserito;
        //     //                 spO.total =
        //     //                     spO.informativo + spO.progetto + spO.decisionale + spO.esame;
        //     //                 spO.daRichiamare = spOne.candidateInDaRichiamare;
        //     //                 spO.nonIdoneo = spOne.candidateInNonIdoneo;
        //     //                 spO.isSub = spOne.subManagers.length > 0;
        //     //                 spO.level = '6px';

        //     //                 userData.push({ ...spO });

        //     //                 drTotal.database += spO.database;
        //     //                 drTotal.conoscitivo += spO.conoscitivo;
        //     //                 drTotal.informativo += spO.informativo;
        //     //                 drTotal.progetto += spO.progetto;
        //     //                 drTotal.decisionale += spO.decisionale;
        //     //                 drTotal.esame += spO.esame;
        //     //                 drTotal.codici += spO.codici;
        //     //                 drTotal.total += spO.total;
        //     //                 drTotal.daRichiamare += spO.daRichiamare;
        //     //                 drTotal.nonIdoneo += spO.nonIdoneo;
        //     //                 drTotal.level = '6px';

        //     //                 if (spO.isSub) {
        //     //                     spOne.subManagers.forEach((spTwo) => {
        //     //                         const sp = {};
        //     //                         sp.id = dir.id;
        //     //                         sp.fullName = `${spOne.fullName}SP2`;
        //     //                         sp.database = spOne.candidatesCount;
        //     //                         sp.conoscitivo = spOne.candidateInConoscitivo;
        //     //                         sp.informativo = spOne.candidateInInformative;
        //     //                         sp.progetto = spOne.candidateInPlanning;
        //     //                         sp.decisionale = spOne.candidateInContract;
        //     //                         sp.esame = spOne.candidateInExamination;
        //     //                         sp.codici = spOne.candidateInInserito;
        //     //                         sp.total =
        //     //                             sp.informativo + sp.progetto + sp.decisionale + sp.esame;
        //     //                         sp.daRichiamare = spOne.candidateInDaRichiamare;
        //     //                         sp.nonIdoneo = spOne.candidateInNonIdoneo;
        //     //                         sp.isSub = spOne.subManagers.length > 0;
        //     //                         sp.level = '8px';

        //     //                         userData.push({ ...sp });

        //     //                         spOTotal.database += spTwo.database;
        //     //                         spOTotal.conoscitivo += spTwo.conoscitivo;
        //     //                         spOTotal.informativo += spTwo.informativo;
        //     //                         spOTotal.progetto += spTwo.progetto;
        //     //                         spOTotal.decisionale += spTwo.decisionale;
        //     //                         spOTotal.esame += spTwo.esame;
        //     //                         spOTotal.codici += spTwo.codici;
        //     //                         spOTotal.total += spTwo.total;
        //     //                         spOTotal.daRichiamare += spTwo.daRichiamare;
        //     //                         spOTotal.nonIdoneo += spTwo.nonIdoneo;
        //     //                         spOTotal.level = '8px';
        //     //                     });
        //     //                     // userData.unshift({ ...spOTotal });
        //     //                     // userData.push({ ...spOTotal });
        //     //                 }
        //     //             });
        //     //             // userData.unshift({ ...drTotal });
        //     //             // userData.push({ ...drTotal });
        //     //         }
        //     //     });
        //     //     // userData.unshift({ ...adminTotal });
        //     //     // userData.push({ ...adminTotal });
        //     // }

        //     total.database += user.database;
        //     total.conoscitivo += user.conoscitivo;
        //     total.informativo += user.informativo;
        //     total.progetto += user.progetto;
        //     total.decisionale += user.decisionale;
        //     total.esame += user.esame;
        //     total.codici += user.codici;
        //     total.total += user.total;
        //     total.daRichiamare += user.daRichiamare;
        //     total.nonIdoneo += user.nonIdoneo;
        // });
        // -----Working V1 End-------

        return data;
    };

    const generateManagerRevenueRows = (data) => {
        console.log(data);
        const userGrideData = [];
        const total = {
            fullName: 'TOTALE REGION',
            database: 0,
            conoscitivo: 0,
            informativo: 0,
            progetto: 0,
            decisionale: 0,
            esame: 0,
            codici: 0,
            total: 0,
            daRichiamare: 0,
            nonIdoneo: 0
        };

        if (data && data?.admin) {
            data.admin.forEach((admin) => {
                admin.subManagersTotal.database += admin.database;
                admin.subManagersTotal.conoscitivo += admin.conoscitivo;
                admin.subManagersTotal.informativo += admin.informativo;
                admin.subManagersTotal.progetto += admin.progetto;
                admin.subManagersTotal.decisionale += admin.decisionale;
                admin.subManagersTotal.esame += admin.esame;
                admin.subManagersTotal.codici += admin.codici;
                admin.subManagersTotal.total += admin.total;
                admin.subManagersTotal.daRichiamare += admin.daRichiamare;
                admin.subManagersTotal.nonIdoneo += admin.nonIdoneo;

                admin.children = [];
                userGrideData.push({
                    data: { ...admin.subManagersTotal },
                    children: admin.children
                });
                if (admin.subManagers.length > 0) {
                    admin.children.push({
                        data: {
                            id: admin.id,
                            fullName: admin.fullName,
                            database: admin.database,
                            conoscitivo: admin.conoscitivo,
                            informativo: admin.informativo,
                            progetto: admin.progetto,
                            decisionale: admin.decisionale,
                            esame: admin.esame,
                            codici: admin.codici,
                            total: admin.total,
                            daRichiamare: admin.daRichiamare,
                            nonIdoneo: admin.nonIdoneo,
                            managerId: admin.managerId
                        }
                    });

                    admin.subManagers.forEach((dir) => {
                        dir.subManagersTotal.conoscitivo += dir.conoscitivo;
                        dir.subManagersTotal.database += dir.database;
                        dir.subManagersTotal.informativo += dir.informativo;
                        dir.subManagersTotal.progetto += dir.progetto;
                        dir.subManagersTotal.decisionale += dir.decisionale;
                        dir.subManagersTotal.esame += dir.esame;
                        dir.subManagersTotal.codici += dir.codici;
                        dir.subManagersTotal.total += dir.total;
                        dir.subManagersTotal.daRichiamare += dir.daRichiamare;
                        dir.subManagersTotal.nonIdoneo += dir.nonIdoneo;

                        dir.children = [];
                        admin.children.push({
                            data: { ...dir.subManagersTotal },
                            children: dir.children
                        });

                        if (dir.subManagers.length > 0) {
                            dir.children.push({
                                data: {
                                    id: dir.id,
                                    fullName: dir.fullName,
                                    database: dir.database,
                                    conoscitivo: dir.conoscitivo,
                                    informativo: dir.informativo,
                                    progetto: dir.progetto,
                                    decisionale: dir.decisionale,
                                    esame: dir.esame,
                                    codici: dir.codici,
                                    total: dir.total,
                                    daRichiamare: dir.daRichiamare,
                                    nonIdoneo: dir.nonIdoneo,
                                    managerId: dir.managerId
                                }
                            });
                            dir.subManagers.forEach((spo) => {
                                spo.subManagersTotal.conoscitivo += spo.conoscitivo;
                                spo.subManagersTotal.database += spo.database;
                                spo.subManagersTotal.informativo += spo.informativo;
                                spo.subManagersTotal.progetto += spo.progetto;
                                spo.subManagersTotal.decisionale += spo.decisionale;
                                spo.subManagersTotal.esame += spo.esame;
                                spo.subManagersTotal.codici += spo.codici;
                                spo.subManagersTotal.total += spo.total;
                                spo.subManagersTotal.daRichiamare += spo.daRichiamare;
                                spo.subManagersTotal.nonIdoneo += spo.nonIdoneo;

                                spo.children = [];
                                dir.children.push({
                                    data: { ...spo.subManagersTotal },
                                    children: spo.children
                                });

                                if (spo.subManagers.length > 0) {
                                    spo.children.push({
                                        data: {
                                            id: spo.id,
                                            fullName: spo.fullName,
                                            database: spo.database,
                                            conoscitivo: spo.conoscitivo,
                                            informativo: spo.informativo,
                                            progetto: spo.progetto,
                                            decisionale: spo.decisionale,
                                            esame: spo.esame,
                                            codici: spo.codici,
                                            total: spo.total,
                                            daRichiamare: spo.daRichiamare,
                                            nonIdoneo: spo.nonIdoneo,
                                            managerId: spo.managerId
                                        }
                                    });
                                    // TODO:
                                    spo.subManagers.forEach((sptw) => {
                                        sptw.subManagersTotal.conoscitivo += sptw.conoscitivo;
                                        sptw.subManagersTotal.database += sptw.database;
                                        sptw.subManagersTotal.informativo += sptw.informativo;
                                        sptw.subManagersTotal.progetto += sptw.progetto;
                                        sptw.subManagersTotal.decisionale += sptw.decisionale;
                                        sptw.subManagersTotal.esame += sptw.esame;
                                        sptw.subManagersTotal.codici += sptw.codici;
                                        sptw.subManagersTotal.total += sptw.total;
                                        sptw.subManagersTotal.daRichiamare += sptw.daRichiamare;
                                        sptw.subManagersTotal.nonIdoneo += sptw.nonIdoneo;

                                        sptw.children = [];
                                        spo.children.push({
                                            data: { ...sptw.subManagersTotal },
                                            children: sptw.children
                                        });

                                        if (sptw.subManagers.length > 0) {
                                            sptw.children.push({
                                                data: {
                                                    id: sptw.id,
                                                    fullName: sptw.fullName,
                                                    database: sptw.database,
                                                    conoscitivo: sptw.conoscitivo,
                                                    informativo: sptw.informativo,
                                                    progetto: sptw.progetto,
                                                    decisionale: sptw.decisionale,
                                                    esame: sptw.esame,
                                                    codici: sptw.codici,
                                                    total: sptw.total,
                                                    daRichiamare: sptw.daRichiamare,
                                                    nonIdoneo: sptw.nonIdoneo,
                                                    managerId: sptw.managerId
                                                }
                                            });
                                            // TODO:
                                            sptw.subManagers.forEach((spth) => {
                                                spth.subManagersTotal.conoscitivo +=
                                                    spth.conoscitivo;
                                                spth.subManagersTotal.database += spth.database;
                                                spth.subManagersTotal.informativo +=
                                                    spth.informativo;
                                                spth.subManagersTotal.progetto += spth.progetto;
                                                spth.subManagersTotal.decisionale +=
                                                    spth.decisionale;
                                                spth.subManagersTotal.esame += spth.esame;
                                                spth.subManagersTotal.codici += spth.codici;
                                                spth.subManagersTotal.total += spth.total;
                                                spth.subManagersTotal.daRichiamare +=
                                                    spth.daRichiamare;
                                                spth.subManagersTotal.nonIdoneo += spth.nonIdoneo;

                                                spth.children = [];
                                                sptw.children.push({
                                                    data: { ...spth.subManagersTotal },
                                                    children: spth.children
                                                });

                                                if (spth.subManagers.length > 0) {
                                                    spth.children.push({
                                                        data: {
                                                            id: spth.id,
                                                            fullName: spth.fullName,
                                                            database: spth.database,
                                                            conoscitivo: spth.conoscitivo,
                                                            informativo: spth.informativo,
                                                            progetto: spth.progetto,
                                                            decisionale: spth.decisionale,
                                                            esame: spth.esame,
                                                            codici: spth.codici,
                                                            total: spth.total,
                                                            daRichiamare: spth.daRichiamare,
                                                            nonIdoneo: spth.nonIdoneo,
                                                            managerId: spth.managerId
                                                        }
                                                    });
                                                    // TODO:
                                                    // TODO:
                                                    spth.subManagers.forEach((spf) => {
                                                        spth.children.push({
                                                            data: { ...spf }
                                                        });
                                                    });
                                                }
                                            });
                                            // TODO:
                                        }
                                    });
                                    // TODO:
                                }
                            });
                        }
                    });
                }

                total.database += admin.subManagersTotal.database;
                total.conoscitivo += admin.subManagersTotal.conoscitivo;
                total.informativo += admin.subManagersTotal.informativo;
                total.progetto += admin.subManagersTotal.progetto;
                total.decisionale += admin.subManagersTotal.decisionale;
                total.esame += admin.subManagersTotal.esame;
                total.codici += admin.subManagersTotal.codici;
                total.total += admin.subManagersTotal.total;
                total.daRichiamare += admin.subManagersTotal.daRichiamare;
                total.nonIdoneo += admin.subManagersTotal.nonIdoneo;
            });
        }

        userGrideData.unshift({ data: { ...total } });
        return userGrideData;
    };

    const getManagerDatabasRows = (newData) => {
        const data = JSON.parse(JSON.stringify({ ...newData }));
        console.log(data);
        const userGrideData = [];
        // Main total
        const total = {
            fullName: 'TOTALE REGION',
            total: 0,
            candidateInNeverMeet: 0,
            candidateInIter: 0,
            candidateInInterested: 0,
            candidateInNotQualify: 0
        };
        // Admin
        if (data && data?.admin) {
            data.admin.forEach((admin) => {
                admin.subManagersTotal.total += admin.total;
                admin.subManagersTotal.candidateInNeverMeet += admin.candidateInNeverMeet;
                admin.subManagersTotal.candidateInIter += admin.candidateInIter;
                admin.subManagersTotal.candidateInInterested += admin.candidateInInterested;
                admin.subManagersTotal.candidateInNotQualify += admin.candidateInNotQualify;

                admin.children = [];
                // 1. Add admins
                userGrideData.push({
                    data: { ...admin.subManagersTotal },
                    children: admin.children
                });
                // 2. Prepare Admin childs
                if (admin.subManagers.length > 0) {
                    // 3. Add Admin it self as a child
                    admin.children.push({
                        data: {
                            id: admin.id,
                            fullName: admin.fullName,
                            total: admin.total,
                            candidateInNeverMeet: admin.candidateInNeverMeet,
                            candidateInIter: admin.candidateInIter,
                            candidateInInterested: admin.candidateInInterested,
                            candidateInNotQualify: admin.candidateInNotQualify,
                            managerId: admin.managerId,
                            userItSelf: true
                        }
                    });
                    // 4. Add Admin dirs
                    admin.subManagers.forEach((dir) => {
                        // Uncomment
                        dir.subManagersTotal.total += dir.total;
                        dir.subManagersTotal.candidateInNeverMeet += dir.candidateInNeverMeet;
                        dir.subManagersTotal.candidateInIter += dir.candidateInIter;
                        dir.subManagersTotal.candidateInInterested += dir.candidateInInterested;
                        dir.subManagersTotal.candidateInNotQualify += dir.candidateInNotQualify;

                        dir.children = [];
                        // 1. Add Dir
                        admin.children.push({
                            data: { ...dir.subManagersTotal },
                            children: dir.children
                        });
                        // 2. Prepare Dir childs
                        if (dir.subManagers.length > 0) {
                            // 3. Add Dir it self as a child
                            dir.children.push({
                                data: {
                                    id: dir.id,
                                    fullName: dir.fullName,
                                    total: dir.total,
                                    candidateInNeverMeet: dir.candidateInNeverMeet,
                                    candidateInIter: dir.candidateInIter,
                                    candidateInInterested: dir.candidateInInterested,
                                    candidateInNotQualify: dir.candidateInNotQualify,
                                    managerId: dir.managerId,
                                    userItSelf: true
                                }
                            });
                            // 4. Add Dir Spone
                            dir.subManagers.forEach((spo) => {
                                spo.subManagersTotal.total += spo.total;
                                spo.subManagersTotal.candidateInNeverMeet +=
                                    spo.candidateInNeverMeet;
                                spo.subManagersTotal.candidateInIter += spo.candidateInIter;
                                spo.subManagersTotal.candidateInInterested +=
                                    spo.candidateInInterested;
                                spo.subManagersTotal.candidateInNotQualify +=
                                    spo.candidateInNotQualify;

                                spo.children = [];
                                // 1. Add Spone
                                dir.children.push({
                                    data: { ...spo.subManagersTotal },
                                    children: spo.children
                                });
                                // 2. Prepare SpOne childs
                                if (spo.subManagers.length > 0) {
                                    // 3. Add Spone it self as a child
                                    spo.children.push({
                                        data: {
                                            id: spo.id,
                                            fullName: spo.fullName,
                                            total: spo.total,
                                            candidateInNeverMeet: spo.candidateInNeverMeet,
                                            candidateInIter: spo.candidateInIter,
                                            candidateInInterested: spo.candidateInInterested,
                                            candidateInNotQualify: spo.candidateInNotQualify,
                                            managerId: spo.managerId,
                                            userItSelf: true
                                        }
                                    });
                                    // 4. Add Spone sptw
                                    spo.subManagers.forEach((sptw) => {
                                        sptw.subManagersTotal.total += sptw.total;
                                        sptw.subManagersTotal.candidateInNeverMeet +=
                                            sptw.candidateInNeverMeet;
                                        sptw.subManagersTotal.candidateInIter +=
                                            sptw.candidateInIter;
                                        sptw.subManagersTotal.candidateInInterested +=
                                            sptw.candidateInInterested;
                                        sptw.subManagersTotal.candidateInNotQualify +=
                                            sptw.candidateInNotQualify;

                                        sptw.children = [];
                                        // 1. Add sptw
                                        spo.children.push({
                                            data: { ...sptw.subManagersTotal },
                                            children: sptw.children
                                        });
                                        // 2. Prepare sptw childs
                                        if (sptw.subManagers.length > 0) {
                                            // 3. Add spt it self as a child
                                            sptw.children.push({
                                                data: {
                                                    id: sptw.id,
                                                    fullName: sptw.fullName,
                                                    total: sptw.total,
                                                    candidateInNeverMeet: sptw.candidateInNeverMeet,
                                                    candidateInIter: sptw.candidateInIter,
                                                    candidateInInterested:
                                                        sptw.candidateInInterested,
                                                    candidateInNotQualify:
                                                        sptw.candidateInNotQualify,
                                                    managerId: sptw.managerId,
                                                    userItSelf: true
                                                }
                                            });
                                            // 4. Add sptw spth
                                            sptw.subManagers.forEach((spth) => {
                                                spth.subManagersTotal.total += spth.total;
                                                spth.subManagersTotal.candidateInNeverMeet +=
                                                    spth.candidateInNeverMeet;
                                                spth.subManagersTotal.candidateInIter +=
                                                    spth.candidateInIter;
                                                spth.subManagersTotal.candidateInInterested +=
                                                    spth.candidateInInterested;
                                                spth.subManagersTotal.candidateInNotQualify +=
                                                    spth.candidateInNotQualify;

                                                spth.children = [];
                                                // 1. Add spth
                                                sptw.children.push({
                                                    data: { ...spth.subManagersTotal },
                                                    children: spth.children
                                                });
                                                // 2. Prepare spth childs
                                                if (spth.subManagers.length > 0) {
                                                    // 3. Add spth it self as a child
                                                    // Prova 2 SV3
                                                    spth.children.push({
                                                        data: {
                                                            id: spth.id,
                                                            fullName: spth.fullName,
                                                            total: spth.total,
                                                            candidateInNeverMeet:
                                                                spth.candidateInNeverMeet,
                                                            candidateInIter: spth.candidateInIter,
                                                            candidateInInterested:
                                                                spth.candidateInInterested,
                                                            candidateInNotQualify:
                                                                spth.candidateInNotQualify,
                                                            managerId: spth.managerId,
                                                            userItSelf: true
                                                        }
                                                    });
                                                    // 4. Add spth spf
                                                    // Prova SV4
                                                    spth.subManagers.forEach((spf) => {
                                                        spth.children.push({
                                                            data: { ...spf }
                                                        });
                                                    });
                                                }
                                            });
                                            // TODO:

                                            // spo.subManagers.forEach((spt) => {
                                            //     spo.children.push({
                                            //         data: { ...spt }
                                            //     });
                                            // });
                                        }
                                    });
                                    // TODO:

                                    // spo.subManagers.forEach((spt) => {
                                    //     spo.children.push({
                                    //         data: { ...spt }
                                    //     });
                                    // });
                                }
                            });
                        }
                    });
                }

                total.total += admin.subManagersTotal.total;
                total.candidateInNeverMeet += admin.subManagersTotal.candidateInNeverMeet;
                total.candidateInIter += admin.subManagersTotal.candidateInIter;
                total.candidateInInterested += admin.subManagersTotal.candidateInInterested;
                total.candidateInNotQualify += admin.subManagersTotal.candidateInNotQualify;
            });
        }
        // Add first total row
        console.log(userGrideData);
        userGrideData.unshift({ data: { ...total } });
        return userGrideData;
    };

    const generateManagerDatabaseObjects = (newData) => {
        const data = JSON.parse(JSON.stringify({ ...newData }));
        if (data?.supervisorFour) {
            data.supervisorFour = data.supervisorFour.map((item) => ({
                id: item.id,
                fullName: `${item.fullName}`,
                candidateInNeverMeet: item.candidateInNeverMeet,
                candidateInIter: item.candidateInIter,
                candidateInInterested: item.candidateInInterested,
                candidateInNotQualify: item.candidateInNotQualify,
                managerId: item.managerId,
                total:
                    item.candidateInNeverMeet +
                    item.candidateInIter +
                    item.candidateInInterested +
                    item.candidateInNotQualify
            }));
        } else {
            data.supervisorFour = [];
        }
        if (data?.supervisorThree) {
            data.supervisorThree = data.supervisorThree.map((item) => ({
                id: item.id,
                fullName: `${item.fullName}`,
                candidateInNeverMeet: item.candidateInNeverMeet,
                candidateInIter: item.candidateInIter,
                candidateInInterested: item.candidateInInterested,
                candidateInNotQualify: item.candidateInNotQualify,
                managerId: item.managerId,
                total:
                    item.candidateInNeverMeet +
                    item.candidateInIter +
                    item.candidateInInterested +
                    item.candidateInNotQualify
            }));
        } else {
            data.supervisorThree = [];
        }
        if (data?.supervisorTwo) {
            data.supervisorTwo = data.supervisorTwo.map((item) => ({
                id: item.id,
                fullName: `${item.fullName}`,
                candidateInNeverMeet: item.candidateInNeverMeet,
                candidateInIter: item.candidateInIter,
                candidateInInterested: item.candidateInInterested,
                candidateInNotQualify: item.candidateInNotQualify,
                managerId: item.managerId,
                total:
                    item.candidateInNeverMeet +
                    item.candidateInIter +
                    item.candidateInInterested +
                    item.candidateInNotQualify
            }));
        } else {
            data.supervisorTwo = [];
        }
        if (data?.supervisorOne) {
            data.supervisorOne = data.supervisorOne.map((item) => ({
                id: item.id,
                fullName: `${item.fullName}`,
                candidateInNeverMeet: item.candidateInNeverMeet,
                candidateInIter: item.candidateInIter,
                candidateInInterested: item.candidateInInterested,
                candidateInNotQualify: item.candidateInNotQualify,
                managerId: item.managerId,
                total:
                    item.candidateInNeverMeet +
                    item.candidateInIter +
                    item.candidateInInterested +
                    item.candidateInNotQualify
            }));
        } else {
            data.supervisorOne = [];
        }
        if (data?.direct) {
            data.direct = data.direct.map((item) => ({
                id: item.id,
                fullName: `${item.fullName}`,
                candidateInNeverMeet: item.candidateInNeverMeet,
                candidateInIter: item.candidateInIter,
                candidateInInterested: item.candidateInInterested,
                candidateInNotQualify: item.candidateInNotQualify,
                managerId: item.managerId,
                total:
                    item.candidateInNeverMeet +
                    item.candidateInIter +
                    item.candidateInInterested +
                    item.candidateInNotQualify
            }));
        } else {
            data.direct = [];
        }
        data.admin = data.admin.map((item) => ({
            id: item.id,
            fullName: `${item.fullName}`,
            candidateInNeverMeet: item.candidateInNeverMeet,
            candidateInIter: item.candidateInIter,
            candidateInInterested: item.candidateInInterested,
            candidateInNotQualify: item.candidateInNotQualify,
            managerId: item.managerId,
            total:
                item.candidateInNeverMeet +
                item.candidateInIter +
                item.candidateInInterested +
                item.candidateInNotQualify
        }));

        data.supervisorThree.forEach((item) => {
            item.subManagers = data?.supervisorFour?.filter(
                (manager) => item.id === manager.managerId
            );
            item.subManagersTotal = {
                fullName: item.fullName,
                id: item.id,
                total: 0,
                candidateInNeverMeet: 0,
                candidateInIter: 0,
                candidateInInterested: 0,
                candidateInNotQualify: 0
            };
            if (item.subManagers?.length && item.subManagers?.length > 0) {
                item.subManagers.forEach((element) => {
                    item.subManagersTotal.total += element.total;
                    item.subManagersTotal.candidateInNeverMeet += element.candidateInNeverMeet;
                    item.subManagersTotal.candidateInIter += element.candidateInIter;
                    item.subManagersTotal.candidateInInterested += element.candidateInInterested;
                    item.subManagersTotal.candidateInNotQualify += element.candidateInNotQualify;
                });
            }
        });
        data.supervisorTwo.forEach((item) => {
            item.subManagers = data?.supervisorThree?.filter(
                (manager) => item.id === manager.managerId
            );
            item.subManagersTotal = {
                fullName: item.fullName,
                id: item.id,
                total: 0,
                candidateInNeverMeet: 0,
                candidateInIter: 0,
                candidateInInterested: 0,
                candidateInNotQualify: 0
            };
            if (item.subManagers?.length && item.subManagers?.length > 0) {
                item.subManagers.forEach((element) => {
                    item.subManagersTotal.total += element.total + element.subManagersTotal.total;
                    item.subManagersTotal.candidateInNeverMeet +=
                        element.candidateInNeverMeet +
                        element.subManagersTotal.candidateInNeverMeet;
                    item.subManagersTotal.candidateInIter +=
                        element.candidateInIter + element.subManagersTotal.candidateInIter;
                    item.subManagersTotal.candidateInInterested +=
                        element.candidateInInterested +
                        element.subManagersTotal.candidateInInterested;
                    item.subManagersTotal.candidateInNotQualify +=
                        element.candidateInNotQualify +
                        element.subManagersTotal.candidateInNotQualify;
                });
            }
        });
        data.supervisorOne.forEach((item) => {
            item.subManagers = data?.supervisorTwo?.filter(
                (manager) => item.id === manager.managerId
            );
            item.subManagersTotal = {
                fullName: item.fullName,
                id: item.id,
                total: 0,
                candidateInNeverMeet: 0,
                candidateInIter: 0,
                candidateInInterested: 0,
                candidateInNotQualify: 0
            };
            if (item.subManagers?.length && item.subManagers?.length > 0) {
                item.subManagers.forEach((element) => {
                    item.subManagersTotal.total += element.total + element.subManagersTotal.total;
                    item.subManagersTotal.candidateInNeverMeet +=
                        element.candidateInNeverMeet +
                        element.subManagersTotal.candidateInNeverMeet;
                    item.subManagersTotal.candidateInIter +=
                        element.candidateInIter + element.subManagersTotal.candidateInIter;
                    item.subManagersTotal.candidateInInterested +=
                        element.candidateInInterested +
                        element.subManagersTotal.candidateInInterested;
                    item.subManagersTotal.candidateInNotQualify +=
                        element.candidateInNotQualify +
                        element.subManagersTotal.candidateInNotQualify;
                });
            }
        });
        data.direct.forEach((item) => {
            item.subManagers = data?.supervisorOne?.filter(
                (manager) => item.id === manager.managerId
            );

            item.subManagersTotal = {
                fullName: item.fullName,
                id: item.id,
                total: 0,
                candidateInNeverMeet: 0,
                candidateInIter: 0,
                candidateInInterested: 0,
                candidateInNotQualify: 0
            };

            if (item.subManagers?.length && item.subManagers?.length > 0) {
                item.subManagers.forEach((element) => {
                    item.subManagersTotal.total += element.total + element.subManagersTotal.total;
                    item.subManagersTotal.candidateInNeverMeet +=
                        element.candidateInNeverMeet +
                        element.subManagersTotal.candidateInNeverMeet;
                    item.subManagersTotal.candidateInIter +=
                        element.candidateInIter + element.subManagersTotal.candidateInIter;
                    item.subManagersTotal.candidateInInterested +=
                        element.candidateInInterested +
                        element.subManagersTotal.candidateInInterested;
                    item.subManagersTotal.candidateInNotQualify +=
                        element.candidateInNotQualify +
                        element.subManagersTotal.candidateInNotQualify;
                });
            }
        });
        data.admin.forEach((item) => {
            item.subManagers = data?.direct?.filter((manager) => item.id === manager.managerId);

            item.subManagersTotal = {
                fullName: item.fullName,
                id: item.id,
                total: 0,
                candidateInNeverMeet: 0,
                candidateInIter: 0,
                candidateInInterested: 0,
                candidateInNotQualify: 0
            };

            if (item.subManagers?.length && item.subManagers?.length > 0) {
                item.subManagers.forEach((element) => {
                    item.subManagersTotal.total += element.total + element.subManagersTotal.total;
                    item.subManagersTotal.candidateInNeverMeet +=
                        element.candidateInNeverMeet +
                        element.subManagersTotal.candidateInNeverMeet;
                    item.subManagersTotal.candidateInIter +=
                        element.candidateInIter + element.subManagersTotal.candidateInIter;
                    item.subManagersTotal.candidateInInterested +=
                        element.candidateInInterested +
                        element.subManagersTotal.candidateInInterested;
                    item.subManagersTotal.candidateInNotQualify +=
                        element.candidateInNotQualify +
                        element.subManagersTotal.candidateInNotQualify;
                });
            }
        });
        return data;
    };

    const generateQueryString = (currentFilters, qs) => {
        let queryString = qs;
        Object.keys(currentFilters).forEach((key) => {
            const value = currentFilters[key];
            if (value) {
                if (key === 'year') {
                    if (typeof value == 'string') {
                        if (value.indexOf('day') > -1) {
                            const days = value.split('-')[1];
                            queryString = buildQueryChunk(queryString, 'day', days);
                        } else if (value.indexOf('month') > -1) {
                            const months = value.split('-')[1];
                            queryString = buildQueryChunk(queryString, 'month', months);
                        } else {
                            queryString = buildQueryChunk(queryString, key, value);
                        }
                    } else {
                        queryString = buildQueryChunk(queryString, key, value);
                    }
                } else {
                    queryString = buildQueryChunk(queryString, key, value);
                }
            }
        });

        return queryString;
    };

    const retrieveUsersData = async (currentState, currentFilters, queryString) => {
        const data = { ...currentState };
        data.isLoading = true;
        setState({ ...data });

        const qs = generateQueryString(currentFilters, queryString);
        const result = await getUsersListData(qs).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
            data.isLoading = false;
            setState({ ...data });
        });

        if (result.data) {
            const userData = result.data;
            setManagersData([...userData]);
            data.isLoading = false;
            setState({ ...data });
        }
    };

    const retrieveUsersRevenueData = async (currentState, currentFilters, queryString) => {
        const data = { ...currentState };
        data.isLoading = true;
        setState({ ...data });

        const qs = generateQueryString(currentFilters, queryString);

        const result = await getUsersRevenueData(qs).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
            data.isLoading = false;
            setState({ ...data });
        });

        if (result.data) {
            const userData = generateManagerRevenueObjects(result.data);
            setManagersData({ ...userData });
            data.isLoading = false;
            setState({ ...data });
        }
    };

    const retrieveUsersDatabase = async (currentState, currentFilters, queryString) => {
        const data = { ...currentState };
        data.isLoading = true;
        setState({ ...data });

        const qs = generateQueryString(currentFilters, queryString);

        const result = await getUsersDatabase(qs).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
            data.isLoading = false;
            setState({ ...data });
        });

        if (result.data) {
            const userData = generateManagerDatabaseObjects(result.data);
            console.log(userData);
            setManagersData({ ...userData });
            data.isLoading = false;
            setState({ ...data });
        }
    };

    const getManagerCognativeData = async (id, currentState) => {
        if (id) {
            const newState = { ...currentState };
            newState.isLoading = true;
            setState({ ...newState });

            const qs = generateQueryString(
                { searchString: managerRevenueSearchString, year: filters.year },
                ''
            );

            const result = await getManagerCognativeCcandidates(id, qs).catch((e) => {
                console.log(e);
                toast.error(MESSAGES.SERVER_ERROR);
                newState.isLoading = false;
                setState({ ...newState });
            });

            if (result.data) {
                const data = {};
                data.user = result.data.user;
                data.origin = [];
                const keys = Object.keys(result.data.candidates);
                keys.forEach((key) => {
                    const item = { name: key, candidates: result.data.candidates[key] };
                    data.origin.push(item);
                });
                setManagerCognativeCandidate(data);
                newState.isLoading = false;
                setState({ ...newState });
            }
        } else {
            setManagerCognativeCandidate(null);
            setManagerRevenueSearchString('');
        }
    };

    /** DONT DELETE */
    // const openManagerModal = (currentState, id) => {
    //     const newState = { ...currentState };
    //     newState.selectedManagerId = id;
    //     setState({ ...newState });
    // };

    const closeManagerModel = (currentState) => {
        const newState = { ...currentState };
        newState.selectedManagerId = null;
        setState({ ...newState });
    };

    const onClickCreateUserBtn = (currentState) => {
        const data = { ...currentState };
        data.isUserCandidateOpen = true;
        setState({ ...data });
    };

    const onCloseCreateUserModal = (currentState, reload = false) => {
        const data = { ...currentState };
        data.isUserCandidateOpen = false;
        setState({ ...data });
        if (reload) {
            retrieveUsersData(data, filters, null);
        }
    };

    const handelActiveSwitchToggleHandler = async (event, user) => {
        if (user) {
            const result = await updateUserField(
                user.id,
                { isActive: user.isActive ? 0 : 1 },
                'status'
            ).catch((e) => {
                console.log(e);
                toast.error(MESSAGES.SERVER_ERROR);
            });
            if (result?.ok) {
                toast.success(MESSAGES.SUCCESS);
                retrieveUsersData(state, filters, null);
            }
        }
    };

    const filterData = (data, localFilters) => {
        let roleFilters = [];
        if (data) {
            roleFilters = data.filter((item) => {
                if (localFilters.role.length) {
                    return item.roleId === localFilters.role;
                }
                return true;
            });
        }

        return roleFilters.filter((item) => {
            if (localFilters.searchString.length) {
                return (
                    item?.email?.includes(localFilters.searchString) ||
                    item?.fullName?.includes(localFilters.searchString) ||
                    item?.manager?.fullName?.includes(localFilters.searchString)
                );
            }
            return true;
        });
    };

    const deleteOnClickHandler = (currentState, id) => {
        const newState = { ...currentState };
        newState.isConfirmDeleteOpen = true;
        setSelectedUserId(id);
        setState(newState);
    };

    const onCloseDeleteConfirmModal = (currentState) => {
        const newState = { ...currentState };
        newState.isConfirmDeleteOpen = false;
        setSelectedUserId(null);
        setState(newState);
    };

    const removeConfirmClickHandler = async (currentState, currentFilters, id) => {
        const newState = { ...currentState };
        newState.isLoading = true;
        setState(newState);

        const result = await deleteUser(id).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
            newState.isLoading = false;
            setState(newState);
        });
        if (result?.ok) {
            toast.success(MESSAGES.SUCCESS);
            newState.isLoading = false;
            newState.isConfirmDeleteOpen = false;
            setState(newState);
            setSelectedUserId(null);
            retrieveUsersData(newState, currentFilters);
        }
    };

    const getRows = (data, localFilters) => {
        const filteredData = filterData(data, localFilters);
        return filteredData.map((item) => {
            return {
                className: '',
                cells: [
                    {
                        template: (
                            <Link
                                to={`../manager/${item.id}`}
                                className="text-primary-800 cursor-pointer"
                                title={`${item.name} ${item.surname}`}>
                                {`${item.fullName}`}
                            </Link>
                        ),
                        className: 'min-w-[128px] font-bold'
                    },
                    {
                        template: item?.role?.name,
                        className: 'min-w-[128px]'
                    },
                    {
                        template: item.isManagerRole ? <FontAwesomeIcon icon={faCheck} /> : null,
                        className: 'min-w-[128px] font-bold text-lg text-center text-primary-500'
                    },
                    {
                        template: item?.createdAt,
                        className: 'min-w-[128px]'
                    },
                    {
                        template:
                            item?.secretaries?.length > 0
                                ? // eslint-disable-next-line no-shadow
                                  item?.secretaries?.map((user, index) =>
                                      // eslint-disable-next-line no-unsafe-optional-chaining
                                      item?.secretaries?.length - 1 === index
                                          ? user.fullName
                                          : `${user.fullName}, `
                                  )
                                : '',
                        className: 'min-w-[128px] font-bold'
                    },
                    {
                        template: item?.email,
                        className: 'min-w-[128px]'
                    },
                    {
                        template: `${item?.manager?.name} ${item?.manager?.surname}`,
                        className: 'min-w-[128px] font-bold'
                    },
                    {
                        template: (
                            <label className="relative inline-flex items-center mr-5 cursor-pointer">
                                <input
                                    type="checkbox"
                                    value=""
                                    className="sr-only peer"
                                    checked={item.isActive}
                                    onClick={(e) => handelActiveSwitchToggleHandler(e, item)}
                                />
                                <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-green-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-500" />
                            </label>
                        ),
                        className: 'min-w-[128px] font-bold'
                    },
                    {
                        template:
                            item?.role?.name !== 'Amministratore' ? (
                                <FontAwesomeIcon
                                    icon={faTrashCan}
                                    onClick={() => deleteOnClickHandler(state, item.id)}
                                />
                            ) : (
                                ''
                            ),
                        className: 'min-w-[128px] text-lg text-center text-red-600 cursor-pointer'
                    }
                ]
            };
        });
    };

    /** DONT DELETE */
    // const databaseCellClickHandler = (item) => {
    //     if (item) {
    //         const currentClientLocal = JSON.parse(Storage.get('candidateFilters'));
    //         currentClientLocal.managers = [{ value: item.id, label: item.fullName }];
    //         Storage.set('candidateFilters', JSON.stringify(currentClientLocal));
    //         navigate(`../candidates`);
    //     }
    // };

    // const toggleRow = (rowId) => {
    //     const isRowExpanded = expandedRows.includes(rowId);
    //     if (isRowExpanded) {
    //         setExpandedRows([...expandedRows.filter((id) => id !== rowId)]);
    //     } else {
    //         setExpandedRows([...expandedRows, rowId]);
    //     }
    // };

    // const getRevenueRows = (data) => {
    //     return data.map((item, index) => {
    //         if (index === 0) {
    //             return {
    //                 className: 'font-bold',
    //                 cells: [
    //                     {
    //                         template: 'TOTALE REGION',
    //                         className: 'border-b-lightBlue-600 border-b-2 min-w-[150px] font-bold'
    //                     },
    //                     {
    //                         template: item.database,
    //                         className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
    //                     },
    //                     {
    //                         template: item.conoscitivo,
    //                         className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
    //                     },
    //                     {
    //                         template: item.informativo,
    //                         className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
    //                     },
    //                     {
    //                         template: item.progetto,
    //                         className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
    //                     },
    //                     {
    //                         template: item.decisionale,
    //                         className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
    //                     },
    //                     {
    //                         template: item.esame,
    //                         className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
    //                     },
    //                     {
    //                         template: item.codici,
    //                         className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
    //                     },
    //                     {
    //                         template: item.total,
    //                         className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
    //                     },
    //                     {
    //                         template: item.daRichiamare,
    //                         className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
    //                     },
    //                     {
    //                         template: item.nonIdoneo,
    //                         className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
    //                     },
    //                     {
    //                         template: '',
    //                         className: 'border-b-lightBlue-600 border-b-2 min-w-[50px]'
    //                     }
    //                 ]
    //             };
    //         }
    //         return {
    //             className: (
    //                 item?.userItSelf
    //                     ? expandedRows.includes(item.id)
    //                     : expandedRows.includes(item.managerId) || item.isAdmin
    //             )
    //                 ? 'font-bold'
    //                 : 'hidden',
    //             cells: [
    //                 {
    //                     template: (
    //                         <>
    //                             {item?.level
    //                                 ? item?.level.map((item) => (
    //                                       <div className="w-2 h-2 bg-primary-800 rounded-full inline-block" />
    //                                   ))
    //                                 : ''}
    //                             <Link
    //                                 to={`../manager/${item.id}`}
    //                                 className="text-primary-800 cursor-pointer"
    //                                 title={`${item.fullName}`}>
    //                                 {`${item.fullName}`}
    //                             </Link>
    //                         </>
    //                     ),
    //                     className: `min-w-[150px] font-bold border-l-lightBlue-600 ${
    //                         item.isTotal && expandedRows.includes(item.id)
    //                             ? 'border-b-lightBlue-600 border-b-2'
    //                             : ''
    //                     }`
    //                 },
    //                 {
    //                     template: (
    //                         <span
    //                             onClick={() => databaseCellClickHandler(item)}
    //                             aria-hidden="true"
    //                             className="cursor-pointer">
    //                             {item.database}
    //                         </span>
    //                     ),
    //                     className: `min-w-[128px] text-primary-800 ${
    //                         item.isTotal && expandedRows.includes(item.id)
    //                             ? 'border-b-lightBlue-600 border-b-2'
    //                             : ''
    //                     }`
    //                 },
    //                 {
    //                     template: (
    //                         <button type="button" onClick={() => openManagerModal(state, item.id)}>
    //                             {item.conoscitivo}
    //                         </button>
    //                     ),
    //                     className: `min-w-[128px] ${
    //                         item.isTotal && expandedRows.includes(item.id)
    //                             ? 'border-b-lightBlue-600 border-b-2'
    //                             : ''
    //                     }`
    //                 },
    //                 {
    //                     template: item.informativo,
    //                     className: `min-w-[128px] ${
    //                         item.isTotal && expandedRows.includes(item.id)
    //                             ? 'border-b-lightBlue-600 border-b-2'
    //                             : ''
    //                     }`
    //                 },
    //                 {
    //                     template: item.progetto,
    //                     className: `min-w-[128px] ${
    //                         item.isTotal && expandedRows.includes(item.id)
    //                             ? 'border-b-lightBlue-600 border-b-2'
    //                             : ''
    //                     }`
    //                 },
    //                 {
    //                     template: item.decisionale,
    //                     className: `min-w-[128px] ${
    //                         item.isTotal && expandedRows.includes(item.id)
    //                             ? 'border-b-lightBlue-600 border-b-2'
    //                             : ''
    //                     }`
    //                 },
    //                 {
    //                     template: item.esame,
    //                     className: `min-w-[128px] ${
    //                         item.isTotal && expandedRows.includes(item.id)
    //                             ? 'border-b-lightBlue-600 border-b-2'
    //                             : ''
    //                     }`
    //                 },
    //                 {
    //                     template: item.codici,
    //                     className: `min-w-[128px] ${
    //                         item.isTotal && expandedRows.includes(item.id)
    //                             ? 'border-b-lightBlue-600 border-b-2'
    //                             : ''
    //                     }`
    //                 },
    //                 {
    //                     template: item.total,
    //                     className: `min-w-[128px] ${
    //                         item.isTotal && expandedRows.includes(item.id)
    //                             ? 'border-b-lightBlue-600 border-b-2'
    //                             : ''
    //                     }`
    //                 },
    //                 {
    //                     template: item.daRichiamare,
    //                     className: `min-w-[128px] ${
    //                         item.isTotal && expandedRows.includes(item.id)
    //                             ? 'border-b-lightBlue-600 border-b-2'
    //                             : ''
    //                     }`
    //                 },
    //                 {
    //                     template: item.nonIdoneo,
    //                     className: `min-w-[128px] ${
    //                         item.isTotal && expandedRows.includes(item.id)
    //                             ? 'border-b-lightBlue-600 border-b-2'
    //                             : ''
    //                     }`
    //                 },
    //                 {
    //                     template: item.isSubManager ? (
    //                         <FontAwesomeIcon
    //                             icon={
    //                                 expandedRows.includes(item.id) ? faChevronDown : faChevronRight
    //                             }
    //                             onClick={() => toggleRow(item.id)}
    //                         />
    //                     ) : null,
    //                     className: `min-w-[50px] text-lg text-center text-primary-500 cursor-pointer ${
    //                         item.isTotal && expandedRows.includes(item.id)
    //                             ? 'border-b-lightBlue-600 border-b-2'
    //                             : ''
    //                     }`
    //                 }
    //             ]
    //         };
    //     });
    // };

    // const getMngCell = (items) => {
    //     const template = [<div className="font-bold mb-1">{items?.length} Candidati</div>];

    //     items?.forEach((item) => {
    //         template.push(
    //             <div className="mb-0.5">
    //                 <br />
    //                 <span className={item.isStudying ? 'text-primary-500' : ''}>
    //                     {item.name}
    //                 </span>{' '}
    //                 <span className="text-red-500">({item.manager})</span>
    //             </div>
    //         );
    //     });

    //     return template;
    // };

    // const getDatabaseRows = (data) => {
    //     return data.map((item, index) => {
    //         return {
    //             className: (
    //                 item?.userItSelf
    //                     ? expandedRows.includes(item.id)
    //                     : expandedRows.includes(item.managerId) || item.isAdmin
    //             )
    //                 ? 'font-bold'
    //                 : 'hidden',
    //             cells: [
    //                 {
    //                     template:
    //                         index !== 0 ? (
    //                             <Link
    //                                 to={`../manager/${item.id}`}
    //                                 className="text-primary-800 cursor-pointer"
    //                                 title={`${item.fullName}`}>
    //                                 {`${item.fullName}`}
    //                             </Link>
    //                         ) : (
    //                             'TOTALE REGION'
    //                         ),
    //                     className: `${
    //                         index === 0 ? 'border-b-lightBlue-600 border-b-2' : ''
    //                     } min-w-[128px] ${
    //                         item.isTotal && expandedRows.includes(item.id)
    //                             ? 'border-b-lightBlue-600 border-b-2'
    //                             : ''
    //                     }`
    //                 },
    //                 {
    //                     template: item.total,
    //                     className: `${
    //                         index === 0 ? 'border-b-lightBlue-600 border-b-2' : ''
    //                     } min-w-[128px] ${
    //                         item.isTotal && expandedRows.includes(item.id)
    //                             ? 'border-b-lightBlue-600 border-b-2'
    //                             : ''
    //                     }`
    //                 },
    //                 {
    //                     template: item.candidateInNeverMeet,
    //                     className: `${
    //                         index === 0 ? 'border-b-lightBlue-600 border-b-2' : ''
    //                     } min-w-[128px] ${
    //                         item.isTotal && expandedRows.includes(item.id)
    //                             ? 'border-b-lightBlue-600 border-b-2'
    //                             : ''
    //                     }`
    //                 },
    //                 {
    //                     template: item.candidateInIter,
    //                     className: `${
    //                         index === 0 ? 'border-b-lightBlue-600 border-b-2' : ''
    //                     } min-w-[128px] ${
    //                         item.isTotal && expandedRows.includes(item.id)
    //                             ? 'border-b-lightBlue-600 border-b-2'
    //                             : ''
    //                     }`
    //                 },
    //                 {
    //                     template: item.candidateInInterested,
    //                     className: `${
    //                         index === 0 ? 'border-b-lightBlue-600 border-b-2' : ''
    //                     } min-w-[128px] ${
    //                         item.isTotal && expandedRows.includes(item.id)
    //                             ? 'border-b-lightBlue-600 border-b-2'
    //                             : ''
    //                     }`
    //                 },
    //                 {
    //                     template: item.candidateInNotQualify,
    //                     className: `${
    //                         index === 0 ? 'border-b-lightBlue-600 border-b-2' : ''
    //                     } min-w-[128px] ${
    //                         item.isTotal && expandedRows.includes(item.id)
    //                             ? 'border-b-lightBlue-600 border-b-2'
    //                             : ''
    //                     }`
    //                 },
    //                 {
    //                     template: item.isSubManager ? (
    //                         <FontAwesomeIcon
    //                             icon={
    //                                 expandedRows.includes(item.id) ? faChevronDown : faChevronRight
    //                             }
    //                             onClick={() => toggleRow(item.id)}
    //                         />
    //                     ) : null,
    //                     className: `min-w-[50px] text-lg text-center text-primary-500 cursor-pointer ${
    //                         item.isTotal && expandedRows.includes(item.id)
    //                             ? 'border-b-lightBlue-600 border-b-2'
    //                             : ''
    //                     }`
    //                 }
    //             ]
    //         };
    //     });
    // };

    const handleFilterChange = (e, currentFilters, filter, currentState) => {
        const newFilters = { ...currentFilters };
        const event = { ...e };
        newFilters[filter] = event.target.value;
        if (filter === 'year') {
            const newState = { ...currentState };
            newState.isCurrentYear = event.target.value == currentYear;
            setState({ ...newState });
        }
        setFilters({ ...newFilters });
    };

    const handleLocalFilterChange = (e, currentFilters, filter) => {
        const newFilters = { ...currentFilters };
        if (filter === 'searchString') {
            newFilters[filter] = e;
        } else {
            const event = { ...e };
            newFilters[filter] = event.target.value;
        }
        setFrontFilters({ ...newFilters });
    };

    const getManagerCsvData = (data, localFilters) => {
        const columns = [
            { header: 'Manager', field: 'name' },
            { header: 'Livello', field: 'role' },
            { header: 'MNG Crescita', field: 'mngCrescita' },
            { header: 'Data creazione', field: 'createdAt' },
            { header: 'Segretarie', field: 'secretaries' },
            { header: 'Mail', field: 'mail' },
            { header: 'Assegnato a', field: 'manager' },
            { header: 'Attivo', field: 'isActive' }
        ];
        const filteredData = filterData(data, localFilters);
        const finalData = filteredData.map((item) => {
            let sName = '';
            if (item?.secretaries?.length) {
                item?.secretaries.forEach((secretary, index) => {
                    sName +=
                        item?.secretaries?.length - 1 == index
                            ? secretary.fullName
                            : `${secretary.fullName} | `;
                });
            }
            return {
                name: item.fullName,
                role: item?.role?.name,
                mngCrescita: item.isManagerRole ? 'Si' : 'No',
                createdAt: item?.createdAt,
                secretaries: sName,
                mail: item?.email,
                manager: `${item?.manager?.name} ${item?.manager?.surname}`,
                isActive: item.isActive ? 'Si' : 'No'
            };
        });

        return { columns, finalData };
    };

    const getCsvRevenueObj = (item) => {
        return {
            name: item.fullName,
            database: item.database,
            conoscitivo: item.conoscitivo,
            informativo: item.informativo,
            progetto: item.progetto,
            decisionale: item.decisionale,
            esame: item.esame,
            codici: item.codici,
            total: item.total,
            daRichiamare: item.daRichiamare,
            nonIdoneo: item.nonIdoneo
        };
    };
    const getRevenueCsvData = (data) => {
        const columns = [
            { header: 'Manager', field: 'name' },
            { header: 'Database', field: 'database' },
            { header: 'Conoscitivo progressivo', field: 'conoscitivo' },
            { header: 'Informativo', field: 'informativo' },
            { header: 'Progetto/Master', field: 'progetto' },
            { header: 'Fase decisionale', field: 'decisionale' },
            { header: 'Esame', field: 'esame' },
            { header: 'Codici', field: 'codici' },
            { header: 'Totale candidati in iter', field: 'total' },
            { header: 'Da richiamare', field: 'daRichiamare' },
            { header: 'Non idonei', field: 'nonIdoneo' }
        ];
        const finalData = [];
        // const finalData = data.map((item, index) => {
        //     return {
        //         name: index === 0 ? 'TOTALE REGION' : item.fullName,
        //         database: item.database,
        //         conoscitivo: item.conoscitivo,
        //         informativo: item.informativo,
        //         progetto: item.progetto,
        //         decisionale: item.decisionale,
        //         esame: item.esame,
        //         codici: item.codici,
        //         total: item.total,
        //         daRichiamare: item.daRichiamare,
        //         nonIdoneo: item.nonIdoneo
        //     };
        // });
        if (data.admin) {
            data.admin.forEach((admin) => {
                finalData.push(getCsvRevenueObj(admin));
                if (admin?.subManagers?.length) {
                    admin?.subManagers.forEach((dir) => {
                        finalData.push(getCsvRevenueObj(dir));
                        if (dir?.subManagers?.length) {
                            dir?.subManagers.forEach((spo) => {
                                finalData.push(getCsvRevenueObj(spo));
                                if (spo?.subManagers?.length) {
                                    spo?.subManagers.forEach((spt) => {
                                        finalData.push(getCsvRevenueObj(spt));
                                        if (spt?.subManagers?.length) {
                                            spt?.subManagers.forEach((spth) => {
                                                finalData.push(getCsvRevenueObj(spth));
                                                if (spth?.subManagers?.length) {
                                                    spth?.subManagers.forEach((spf) => {
                                                        finalData.push(getCsvRevenueObj(spf));
                                                    });
                                                }
                                            });
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            });
        }
        return { columns, finalData };
    };

    // const getMngCSVCell = (items) => {
    //     let template = '';

    //     items?.forEach((item, index) => {
    //         if (index === 0) {
    //             template = `${index + 1}) ${item.name} (${item.manager})`;
    //         } else {
    //             template = `${template} ${index + 1}) ${item.name} (${item.manager})`;
    //         }
    //     });

    //     return template;
    // };

    const getCsvDatabaseObj = (item) => {
        return {
            name: item.fullName,
            total: item.total,
            candidateInNeverMeet: item.candidateInNeverMeet,
            candidateInIter: item.candidateInIter,
            candidateInInterested: item.candidateInInterested,
            candidateInNotQualify: item.candidateInNotQualify
        };
    };

    const getMngManagerCsvData = (data) => {
        const columns = [
            { header: 'Manager', field: 'name' },
            { header: 'Totale candidati', field: 'total' },
            { header: 'Mai incontrati', field: 'candidateInNeverMeet' },
            { header: 'Iter', field: 'candidateInIter' },
            { header: 'Da richiamare', field: 'candidateInInterested' },
            { header: 'Non idoneo', field: 'candidateInNotQualify' }
        ];
        const finalData = [];
        if (data.admin) {
            data.admin.forEach((admin) => {
                finalData.push(getCsvDatabaseObj(admin));
                if (admin?.subManagers?.length) {
                    admin?.subManagers.forEach((dir) => {
                        finalData.push(getCsvDatabaseObj(dir));
                        if (dir?.subManagers?.length) {
                            dir?.subManagers.forEach((spo) => {
                                finalData.push(getCsvDatabaseObj(spo));
                                if (spo?.subManagers?.length) {
                                    spo?.subManagers.forEach((spt) => {
                                        finalData.push(getCsvDatabaseObj(spt));
                                        if (spt?.subManagers?.length) {
                                            spt?.subManagers.forEach((spth) => {
                                                finalData.push(getCsvDatabaseObj(spth));
                                                if (spth?.subManagers?.length) {
                                                    spth?.subManagers.forEach((spf) => {
                                                        finalData.push(getCsvDatabaseObj(spf));
                                                    });
                                                }
                                            });
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            });
        }
        return { columns, finalData };
    };

    const downloadCsv = (data, localFilters) => {
        let result = null;
        let fileName = '';
        if (state.currentTab === 0) {
            result = getManagerCsvData(data, localFilters);
            fileName = 'Manager';
        } else if (state.currentTab === 1) {
            result = getRevenueCsvData(data);
            fileName = 'River';
        } else if (state.currentTab === 2) {
            result = getMngManagerCsvData(data);
            fileName = 'Database';
        }
        const selectedColumns = result?.columns.map((item) => ({
            label: item.header,
            value: item.field
        }));
        if (result && result.columns && result.finalData) {
            generateAndDownloadXlsx(selectedColumns, result.finalData, fileName);
        }
    };

    const resetFrontFilters = () => {
        setFrontFilters({
            searchString: '',
            role: ''
        });
    };

    /**
     * Watchers
     */
    useEffect(() => {
        getManagerCognativeData(state.selectedManagerId, state);
    }, [state.selectedManagerId, managerRevenueSearchString]);

    useEffect(() => {
        resetFrontFilters();
    }, [state.currentTab]);

    // useEffect(() => {
    //     if (state.isCurrentYear) {
    //         setRevenueColumns([...USERS_REVENUE_TABLE_COLUMNS]);
    //     } else {
    //         setRevenueColumns([...USERS_REVENUE_TABLE_ONLY_PROG_COLUMNS]);
    //     }
    // }, [state.isCurrentYear]);

    useEffect(() => {
        if (state.currentTab === 0) {
            retrieveUsersData(state, filters, null);
        } else if (state.currentTab === 1) {
            retrieveUsersRevenueData(state, filters, null);
        } else if (state.currentTab === 2) {
            retrieveUsersDatabase(state, filters, null);
        }
    }, [filters, state.currentTab]);

    useEffect(() => {
        checkPagePermission(navigate);
        retrieveRoles();
        initYears();
    }, []);

    return (
        <section className="space-y-2" style={{ overflow: 'hidden' }}>
            {state.isLoading ? <Loader /> : null}

            {state.isUserCandidateOpen ? (
                <CreateManager
                    isOpen
                    isCreateNew
                    closeModel={(reload = false) => onCloseCreateUserModal(state, reload)}
                />
            ) : null}

            {state.isConfirmDeleteOpen ? (
                <ConfirmationModal
                    isOpen={state.isConfirmDeleteOpen}
                    title="Elimina manager"
                    message="Vuoi eliminare il manager scelto?"
                    onConfirmAction={() =>
                        removeConfirmClickHandler(state, filters, selectedUserId)
                    }
                    closeModel={() => onCloseDeleteConfirmModal(state)}
                    confirmBtnText="ELIMINA"
                />
            ) : null}

            {state.selectedManagerId != null && (
                <ModalComponent
                    isOpen={!!state.selectedManagerId}
                    closeModel={() => closeManagerModel(state)}
                    title={
                        <div>
                            <span>{managerCognativeCandidate?.user}</span>
                            <SearchField
                                className="my-2 mx-auto w-full text-black"
                                defaultValue={filters?.searchString}
                                onPressEnter={(e) => setManagerRevenueSearchString(e)}
                            />
                        </div>
                    }
                    size="max-w-[632px]">
                    <div className="p-6 h-[650px] overflow-y-auto">
                        {managerCognativeCandidate &&
                            managerCognativeCandidate?.origin?.length &&
                            managerCognativeCandidate.origin.map((item) => {
                                return (
                                    <div>
                                        <div className="font-bold p-2 flex border-b-black border-b-2 w-full justify-between">
                                            <span>{item.name}</span>
                                            <span>
                                                {item?.candidates && item?.candidates?.length}
                                            </span>
                                        </div>
                                        {item?.candidates?.length > 0 ? (
                                            item?.candidates?.map((candidate) => {
                                                return (
                                                    <div className="p-2 flex border-b-2 w-full justify-between">
                                                        <span>{`${candidate.name} ${candidate.surname}`}</span>
                                                        <Link
                                                            to={`../candidate/${candidate.id}`}
                                                            title={`${candidate.name} ${candidate.surname}`}>
                                                            {`>`}
                                                        </Link>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div className="w-full border-b-2 p-2 block">
                                                Nessun dato
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                    </div>
                </ModalComponent>
            )}

            <AppHeader>
                <HeaderItem>
                    <span
                        className={`cursor-pointer font-bold text-2xl text-grey-900 ${
                            state.currentTab === 0 ? 'text-grey-900' : 'text-grey-600'
                        }`}
                        onClick={() => setCurrentTab(state, 0)}
                        aria-hidden="true">
                        {managersData && managersData?.length
                            ? managersData.length - (state.currentTab !== 0 ? 1 : 0)
                            : 0}{' '}
                        Manager
                    </span>
                    <span className="text-2xl">|</span>
                    <span
                        className={`cursor-pointer font-bold text-2xl text-grey-900 ${
                            state.currentTab === 1 ? 'text-grey-900' : 'text-grey-600'
                        }`}
                        onClick={() => setCurrentTab(state, 1)}
                        aria-hidden="true">
                        River
                    </span>
                    <span className="text-2xl">|</span>
                    <span
                        className={`cursor-pointer font-bold text-2xl text-grey-900 ${
                            state.currentTab === 2 ? 'text-grey-900' : 'text-grey-600'
                        }`}
                        onClick={() => setCurrentTab(state, 2)}
                        aria-hidden="true">
                        Database
                    </span>
                </HeaderItem>
                <HeaderItem>
                    {state.currentTab !== 0 && (
                        <div className="inline-block input-field-group">
                            <select
                                id="year"
                                name="year"
                                value={filters.year}
                                onChange={(e) => handleFilterChange(e, filters, 'year', state)}>
                                {years.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                    {state.currentTab === 0 && (
                        <>
                            <div className="inline-block input-field-group">
                                <select
                                    id="role"
                                    name="role"
                                    value={filters.role}
                                    onChange={(e) =>
                                        handleLocalFilterChange(e, frontFilters, 'role')
                                    }>
                                    <option value="">Livello</option>
                                    {roles.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {/* <div className="flex items-center input-field-group">
                        <span className="inline-block mr-1 font-semibold">Da: </span>
                        <input
                            type="date"
                            value={filters.startDate}
                            placeholder="Da"
                            onChange={(e) => handleFilterChange(e, filters, 'startDate')}
                        />
                    </div>
                    <div className="flex items-center input-field-group">
                        <span className="inline-block mr-1 font-semibold">A: </span>
                        <input
                            type="date"
                            value={filters.endDate}
                            placeholder="A"
                            onChange={(e) => handleFilterChange(e, filters, 'endDate')}
                        />
                    </div> */}
                            <SearchField
                                defaultValue={filters?.searchString}
                                onPressEnter={(e) =>
                                    handleLocalFilterChange(e, frontFilters, 'searchString')
                                }
                            />
                        </>
                    )}
                </HeaderItem>
            </AppHeader>

            {/* <AppHeader>
                <HeaderItem />
                <HeaderItem className="w-80">
                    <div className="inline-block w-full">
                        <SearchField
                            defaultValue={filters?.searchString}
                            onPressEnter={(e) => handleFilterChange(e, filters, 'searchString')}
                        />
                    </div>
                </HeaderItem>
            </AppHeader> */}

            <AppHeader>
                <HeaderItem />
                <HeaderItem>
                    <PrimaryButton onClick={() => downloadCsv(managersData, frontFilters)}>
                        XLS
                    </PrimaryButton>
                    {state.currentTab === 0 && (
                        <PrimaryButton onClick={() => onClickCreateUserBtn(state)}>
                            AGGIUNGI
                        </PrimaryButton>
                    )}
                </HeaderItem>
            </AppHeader>

            {state.currentTab === 0 && (
                <DataTable
                    columns={USERS_TABLE_COLUMNS}
                    rows={getRows(managersData, frontFilters)}
                />
            )}

            {state.currentTab === 1 && (
                // <DataTable
                //     columns={USERS_REVENUE_TABLE_COLUMNS}
                //     rows={getRevenueRows(managersData, state)}
                //     className="border-spacing-y-0"
                // />
                <div style={{ overflow: 'auto' }}>
                    <div style={{ minWidth: '1320px' }}>
                        <TreeDataGride
                            columns={USERS_REVENUE_TABLE_COLUMNS_TREE}
                            rows={generateManagerRevenueRows(managersData)}
                        />
                    </div>
                </div>
                // <div>abc</div>
            )}

            {state.currentTab === 2 && (
                // <DataTable
                //     columns={USERS_DATABASE_TABLE_COLUMNS}
                //     rows={getDatabaseRows(managersData, frontFilters)}
                //     className="border-spacing-y-0"
                // />
                // <div className="bg-yellow-200">
                <div style={{ overflow: 'auto' }}>
                    <div style={{ minWidth: '860px' }}>
                        <TreeDataGride
                            columns={USERS_DATABASE_TABLE_COLUMNS_TREE}
                            rows={getManagerDatabasRows(managersData)}
                        />
                    </div>
                </div>
                // <div>ad</div>
                // </div>
            )}
        </section>
    );
}

export default Managers;
